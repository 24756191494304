import * as React from 'react';
import Box from '@mui/material/Box';


import Appbar from '../components/Appbar';
import Personal from '../components/Personal';
import Navigation from '../components/Navigation';

export default function Page() {
	return (
		<Box>
			<Appbar/ >
			<Personal/ >
			<Navigation value="mine"/ >
		</Box>
	);
}