import * as React from 'react';
import Box from '@mui/material/Box';

import ArtCircle from '../components/ArtCircle.js'; 
import Navigation from '../components/Navigation';

export default function Artists(){

    return (
    	<Box>
            <ArtCircle/ >
            <Navigation value="artCircle"/ >
    	</Box>
    );
}