import * as React from 'react';
import Box from '@mui/material/Box';
import AppbarCrumbs from '../components/AppbarCrumbs';
import EditNickname from '../components/EditNickname';
import Navigation from '../components/Navigation';

export default function EditNicknamePage(){

    return (
    	<Box>
    		<AppbarCrumbs/ >
    		<EditNickname/ >
    		<Navigation value="mine"/>
    	</Box>
    );
}