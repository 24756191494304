import * as React from 'react';
import Carousel from 'react-material-ui-carousel'

import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import { ThemeProvider,useTheme, createTheme,styled,alpha } from '@mui/material/styles';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Button, CardActionArea, CardActions, Container,CssBaseline,Grid, Icon } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';

import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { apiPostDetail,apiPostCommentDetail,apiPostLike,apiPostCommentPublish } from '../common/Api';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import utils from '../common/utils'

const postCardTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow:'0px 0px 1px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
          
        },
      }
    }
  }
});
const Mycards = styled(Card)({
  boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
  paddingBottom:'10px'
});

const CommentNav = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
 
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));
const CommentIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color:"text.secondary",
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    borderRadius:'5px',
    backgroundColor:'rgb(0 0 0 / 6%)',
    [theme.breakpoints.up('sm')]: {
      // width: '12ch',
      // '&:focus': {
      //   width: '20ch',
      // },
    },
  },
}));

const CommentInputBase = styled(InputBase)(({ theme }) => ({
  color:"text.secondary",
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `10px`,
    transition: theme.transitions.create('width'),
    width: '100%',
    borderRadius:'10px',
    backgroundColor:'rgb(167 155 155 / 20%)',
    borderRadius:'5px 0px 0px 5px',
    [theme.breakpoints.up('sm')]: {
      // width: '12ch',
      // '&:focus': {
        // width: '20ch',
      // },
    },
  },
}));

function ImageCarousel(props){
  let imgItem = props.img

  // console.log(imgItem)
  
	let imgItems = [
    'fff (3).png',  
	];
  if(imgItem){
    imgItems =imgItem; 
  }

    return (
        <Carousel 
        autoPlay={false} 
        swipe={true} 
        indicators={true} 
        indicatorContainerProps={{
          style: {
            zIndex: 1,
            // marginTop: "-30px",
            position: "relative"
          }
        }}>
            {
                imgItems.map( (item, i) => <ImgItem key={i} item={item} /> )
            }
        </Carousel>
    )
}
function ImgItem(props)
{
    return (

    		<Card sx={{ maxWidth: '100%',borderRadius:0}}>
		      <CardMedia
          sx={{
            height:"auto"
          }}
		        component="img"
		        image={props.item}
		        alt="Paella dish"
		      />
		    </Card>
    )
}

function Navbar(props){
  let { loading = true } = props;
  props.author?loading=false:loading=true;
  const history = useHistory();

  const handleGoBack = () => {		// window.history.goback(-1);
    history.goBack();
	};
    return (
        <AppBar component="nav" >
          {loading ? (
            <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              // onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}
            >
            </IconButton>
            <Avatar
                />         
          </Toolbar>
          ):(
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
            onClick={handleGoBack}
          >
            <ArrowBackIosIcon  />
          </IconButton>
          <Avatar
                src={props.author.avatar}
              />
         <Typography sx={{fontSize:'1.1rem',padding:'0 10px',flexGrow: 1}}>{props.author.nickname}</Typography>
         <IconButton size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                sx={{padding:'0'}}
                >
                <ShareOutlinedIcon />
              </IconButton>
        </Toolbar>
        )}
      </AppBar>
    )
}
function Media(props) {
    // console.log(props)
    let { loading = true } = props;
    props.img?loading=false:loading=true;

    const textinfo =props.content;
    const [readMore,setReadMore] = React.useState(false);
    const [like,setLike] = React.useState(false);
   
    return (
      <Mycards sx={{ m: 0,marginBottom:'10px',backgroundImage:'none' }}>
        {loading ? (
          <Skeleton sx={{ minheight: 270 }} animation="wave" variant="rectangular" />
        ) : (
          // <CardMedia 
          <ImageCarousel 
          
            component="img"
            height="auto"
            img={props.img}
          />

        )}
  
        <CardContent sx={{padding:'8px 6px',top: '-20px',position: 'relative',display: 'inline',zIndex:10,}}>
          {loading ? (
            <React.Fragment>
              <Skeleton
                animation="wave"
                height={10}
                style={{ marginBottom: 6,marginTop:'40px', }}
              />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
          ) : (
              <Box sx={{padding:'0px 15px'}}>
                {/* <Box sx={{paddingBottom:'4px',}}>
                  <Box sx={{padding:'0 10px',display:'inline-block',}} onClick={() => setLike(!like)}>
                  {like ? <FavoriteIcon sx={{verticalAlign:'middle'}} color="error" ></FavoriteIcon> :<FavoriteBorderIcon sx={{verticalAlign:'middle'}}  ></FavoriteBorderIcon>}
                  </Box>
                  <Box  onClick={()=>alert('未开放')}
                   sx={{padding:'0 10px',display:'inline-block',verticalAlign:'middle'}}>
                  <TextsmsOutlinedIcon sx={{verticalAlign:'middle'}}></TextsmsOutlinedIcon>
                  </Box>
                </Box> */}
                <Typography variant="h6" display="block" gutterBottom sx={{fontSize:'1.2rem',fontWeight:700}}>
                  {props.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" component="p" sx={{               
                }}>
                {       
                    textinfo
                }
                </Typography>

                
              </Box>
            
          )}
        </CardContent>
        <Divider variant="middle" sx={{marginTop:'-20px'}} />

      </Mycards>

    );
  }

  function CommentInput(props){
    const { enqueueSnackbar } = useSnackbar();
    const commentInputRef = React.useRef();
    const [commentVal,setCommentVal] = React.useState();
    const comment =()=>{
        if (!commentVal) {
          enqueueSnackbar("内容不能为空",{variant:"error"});
        }else{
          apiPostCommentPublish({postId:props.postId,content:commentVal,parentId:0,replyUserId:0,replyCommentId:0}).then(res=>{
            if (res.code == 200) {
              enqueueSnackbar("评论成功",{variant:"success"});
              //清空input 内容
              commentInputRef.current.childNodes[0].value="";
              //更新评论列表
              let parentId=0;
              let date = new Date();
              date = utils.getNowFormatDate(date);
              if(parentId==0){
                  let User={
                    Avatar:props.userInfo.avatar,
                    Nickname:props.userInfo.nickname,
                    Uid:props.userInfo.uid,
                    url:"/space/"+props.userInfo.uid,
                  }
                  let newComment={Id:res.data,User:User,ReplyCommentList:[],ReplyCommentCount:{},create_time:date,post_id:props.postId,Content:commentVal}
                  commentToParent(newComment);
              }
            } else {
              if(res.msg=="UNAUTHENTICATED"){
                enqueueSnackbar("您尚未登陆",{variant:"error"});
              }else{
                enqueueSnackbar(res.msg,{variant:"error"});
              }
            }
          })
        }    
    }
    const inputChange=(e)=>{
        // console.log(e.target.value);
        setCommentVal(e.target.value);
    }
    const commentToParent = (newComment) => {
      // 向父组件传递新增的评论
        props.getNewComment(newComment)
    }
    return (
      <Box sx={{padding:'0px 20px',marginBottom:'10px'}}>
        <Typography sx={{color:'#969393',fontSize:'0.93rem',marginBottom:'0.65em'}} variant="body2" display="block" gutterBottom>
                  共{props.count}条评论
        </Typography>
        <Grid container spacing={0} sx={{alignItems:'center',justifyContent:'space-between'}}>
          <Grid item xs={2} sx={{flexBasis:0,}}>
            <Avatar alt="user" src={props.userInfo.avatar} sx={{}} />
          </Grid>
          <Grid item xs={10} sx={{display:'flex',}}>
            <CommentInputBase
                  ref={commentInputRef}
                  sx={{width:'100%',}}
                  placeholder="精彩评论优先展示哦"
                  inputProps={{ 'aria-label': 'comment' }}
                  onChange={(e)=>inputChange(e)}
                />
                <Button onClick={(e)=>comment()} variant="contained" sx={{borderRadius:'0 4px 4px 0'}}>发布</Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function CommentList(props){
    //调用
    const [val,setVal] = React.useState([]);
    const [commentCount,setCommentCount] = React.useState(0);
    const history = useHistory();

    const handleHref = (url) => {
      history.push(url);
    };
  
    React.useEffect(()=>{
      if(props.newData.Id){
        //数组第一位插入新评论
        let t_val=[];
        t_val.push(props.newData);
        t_val = t_val.concat(val);
        setVal(t_val);
        toparent(commentCount+1);
        setCommentCount(commentCount+1);
      }
      
    },[props.newData])

    React.useEffect(()=>{
      if(props.newChildData.Id){
        if(props.newChildData.fatherId){
          val.map((itemc,ic)=>{
            if(props.newChildData.fatherId == itemc.Id){
              let t_val=[];
              if (itemc.ReplyCommentList.length>0) {
                t_val.push(props.newChildData);
                t_val = t_val.concat(itemc.ReplyCommentList);
              }else{
                t_val.push(props.newChildData);
              }
              val[ic].ReplyCommentList = t_val;
            }  
          })
          let c_val = [];
          c_val = c_val.concat(val);
          setVal(c_val)
          toparent(commentCount+1);
          setCommentCount(commentCount+1);
        }else{
          let c_val=[],c_reply={};
          c_reply=props.newChildData
          c_reply.ReplyCommentList=[];
          c_reply.ReplyCommentCount={};
          c_val.push(c_reply);
          c_val = c_val.concat(val);
          setVal(c_val);
        }
          
      }
    },[props.newChildData])
    React.useEffect(()=>{
      apiPostCommentDetail({postId:props.uid}).then(res=>{
        if (res.code == 200) {
          if (res.data.data){
        
            for (var i = 0; i < res.data.data.length; i++) {
              res.data.data[i].User.url = "/space/"+res.data.data[i].User.Uid;
              let date = new Date(res.data.data[i].create_time)
              res.data.data[i].create_time = utils.getNowFormatDate(date);
              
              if(!res.data.data[i].ReplyCommentList){
                res.data.data[i].ReplyCommentList=[];
              }else{
                 for(var j=0; j<res.data.data[i].ReplyCommentList.length; j++){
                  res.data.data[i].ReplyCommentList[j].create_time=utils.getNowFormatDate(new Date(res.data.data[i].ReplyCommentList[j].create_time));
                  res.data.data[i].ReplyCommentList[j].User.url="/space/"+res.data.data[i].ReplyCommentList[j].User.Uid;
                 
                }
              }
            };
            setVal(res.data.data);

          }      
          toparent(res.data.count);
          setCommentCount(res.data.count);
        } else {
          enqueueSnackbar(res.msg,{variant:"error"});
        }
      })
    },[])
    //userId评论用户ID,parentId回复的评论的id,postId评论所属的postId,ReplyUserId回复的用户ID
    const comment = (ReplyUserId,parentId,postId,ReplyUsersName) => {
        let commentData={
          postId:postId,
          parentId:parentId,
          ReplyCommentId:parentId,
          ReplyUsersName:ReplyUsersName,
          ReplyUserId:ReplyUserId,
        }
        //调用BottomCommentBar 焦点
        props.sendFocus(commentData)

    }
    const commentChild = (ReplyUserId,parentId,postId,ReplyUsersName,ReplyCommentId) => {
        let commentData={
          postId:postId,
          parentId:parentId,
          ReplyCommentId:ReplyCommentId,
          ReplyUsersName:ReplyUsersName,
          ReplyUserId:ReplyUserId,
        }
        //调用BottomCommentBar 焦点
        props.sendFocus(commentData)

    }

    const toparent = (count) => {
    // 向父组件传递每次递增的value值
      props.getCount(count)
    }
    return (
      <Box sx={{padding:'10px 15px',color:"text.secondary"}}  variant="body1">
        {val.map((item,key)=>(
          <Box key={key}>
          <Grid container spacing={0} sx={{justifyContent:'space-around'}} >
            <Grid item xs={2} sx={{flexBasis:0,}}>
              <Avatar alt={item.User.Nickname} src={item.User.Avatar} onClick={(e)=>handleHref(item.User.url)} />
            </Grid>
            <Grid item xs={10} sx={{padding:'0 6px'}}>
              <Typography variant='subtitle2' gutterBottom sx={{color:'#999393',fontSize:'0.9rem',marginBottom:'0px'}}>
              {item.User.Nickname}
              </Typography>  
              <Grid container spacing={0} sx={{paddingBottom:"10px"}} onClick={(e)=>comment(item.User.Uid,item.Id,props.uid,item.User.Nickname)} >
                  <Grid item xs={11} >
                    <Typography variant="body2" color="text.secondary" gutterBottom sx={{marginBottom:'0px',fontSize:'0.9rem'}}>
                      {item.Content}
                    </Typography>
                    <Typography variant="caption" gutterBottom sx={{color:'#999393'}}>
                      {item.create_time}
                    </Typography>    
                  </Grid>
                  <Grid item xs={1} sx={{}}>
                    <Box sx={{padding:'0 10px',display:'inline-block',textAlign:'center'}} onClick={() => setLike(!like)}>
                        {/* <FavoriteBorderIcon sx={{verticalAlign:'middle'}}  ></FavoriteBorderIcon>
                        <Typography variant="body2" gutterBottom sx={{fontSize:'0.9rem'}}>
                          1                    
                        </Typography> */}
                    </Box>
                  </Grid>
              </Grid>
                {item.ReplyCommentList.map((replyItem,i)=>(
                  <Box sx={{paddingBottom:'8px',}} key={"sub"+i}>
                      
                      <Grid container spacing={0} sx={{justifyContent:'space-around'}}>
                        <Grid item xs={2} sx={{flexBasis:0,}}>
                          <Avatar alt="user" src={replyItem.User.Avatar} sx={{width:'29px',height:'29px'}} onClick={(e)=>handleHref(replyItem.User.url)}/>
                        </Grid>
                        <Grid item xs={10} sx={{padding:'0'}}>
                          <Typography variant='subtitle2' gutterBottom sx={{color:'#999393',fontSize:'0.9rem',marginBottom:'0px'}}>
                            {replyItem.User.Nickname}
                          </Typography>  
                          <Grid container spacing={0} onClick={(e)=>commentChild(replyItem.User.Uid,item.Id,props.uid,replyItem.User.Nickname,replyItem.Id)} >
                            <Grid item xs={11} sx={{}}>
                            <Typography variant="body2" gutterBottom sx={{fontSize:'0.9rem',marginBottom:'0px',}}>
                            回复 <Typography component='span' variant="body2" gutterBottom sx={{color:'#999393',fontSize:'0.9rem'}}>{replyItem.ReplyUsers.Nickname}</Typography>：
                            {replyItem.Content}
                            </Typography>
                            <Typography variant="caption" gutterBottom sx={{color:'#999393'}}>
                              {replyItem.create_time}
                            </Typography>  
                            </Grid>
                            <Grid item xs={1} sx={{}}>
                              <Box sx={{padding:'0 10px',display:'inline-block',textAlign:'center'}} onClick={() => setLike(!like)}>
                                  {/* <FavoriteBorderIcon sx={{verticalAlign:'middle'}}  ></FavoriteBorderIcon>
                                  <Typography variant="body2" gutterBottom sx={{fontSize:'0.9rem'}}>
                                    0                    
                                  </Typography> */}
                              </Box>
                            </Grid>
                          </Grid>    
                          {(i === item.ReplyCommentList.length - 1)&&(item.ReplyCommentCount.Count>item.ReplyCommentList.length)? <Button variant="text" sx={{marginLeft:'-10px',paddingTop:'0px',paddingBottom:'0px'}}>查看更多</Button> : null}      

                        </Grid>
                      </Grid>  
                  </Box>
                ))}
            </Grid>
          </Grid>
          <Divider variant="middle"  sx={{marginBottom:'15px'}}/>
          </Box>
        ))}
      </Box>
    );
  }

  
function BottomCommentBar(props) {
  const { enqueueSnackbar } = useSnackbar();
  const BottomCommentBarInput = React.useRef();
  const [val,setVal] = React.useState(props);
  const [commentData,setCommentData] = React.useState({});
  const [isKeyPress,setisKeyPress] = React.useState(false);
  React.useEffect(()=>{
    setVal(props)
  },[props])
  
  React.useEffect(()=>{
    if(props.commentData.ReplyUserId){
      BottomCommentBarInput.current.childNodes[0].focus();
      BottomCommentBarInput.current.childNodes[0].setAttribute("placeholder","回复 @"+props.commentData.ReplyUsersName);
      setCommentData(props.commentData)
    }
  },[props.commentData])
 
  //失去焦点事件
  const lostFocus = ()=>{
    //判断input框是否还有编辑中的文字
    if(BottomCommentBarInput.current.childNodes[0].value==""){
      //修改placeholder
      BottomCommentBarInput.current.childNodes[0].setAttribute("placeholder","说点什么…");
      //清除commentData
      setCommentData({});
      ChildToParent({});
    }
    setisKeyPress(false)
  }
  const readyToComment =(e)=>{
      //点赞与评论icon换成回复button
      // console.log(e.which)
      setisKeyPress(true)
      if(BottomCommentBarInput.current.childNodes[0].value == ""){
        setisKeyPress(false)
      }
  }
  const comment = (e) => {
    let comment = BottomCommentBarInput.current.childNodes[0].value;
      if(comment=""){
        enqueueSnackbar("内容不能为空",{variant:"error"});
      }else{
        apiPostCommentPublish({postId:props.postId,content:comment,parentId:commentData.parentId,replyUserId:commentData.ReplyUserId,replyCommentId:commentData.ReplyCommentId}).then(res=>{
          if (res.code == 200) {
            enqueueSnackbar("评论成功",{variant:"success"});
            //清空input 内容
            BottomCommentBarInput.current.childNodes[0].value="";
            BottomCommentBarInput.current.childNodes[0].setAttribute("placeholder","说点什么…");
            //清除commentData
            //更新评论列表
            let date = new Date();
            date = utils.getNowFormatDate(date);
            let User={
              Uid:props.userInfo.uid,
              url:"/space/"+props.userInfo.uid,
              Avatar:props.userInfo.avatar,
              Nickname:props.userInfo.nickname
            }
            let ReplyUsers={
              Uid:commentData.ReplyUserId,
              url:"/space/"+commentData.ReplyUserId,
              Avatar:"",
              Nickname:commentData.ReplyUsersName,
            }
            let newComment={fatherId:commentData.parentId,Id:res.data,ReplyUsers:ReplyUsers,User:User,create_time:date,post_id:props.postId,Content:comment}
            ChildToParent(newComment);    

          } else {
            if(res.msg=="UNAUTHENTICATED"){
              enqueueSnackbar("您尚未登陆",{variant:"error"});
            }else{
              enqueueSnackbar(res.msg,{variant:"error"});
            }
          }
        })
      }     
      setCommentData({})
      setisKeyPress(false)
  }

  const ChildToParent = (newComment) => {
    // 向父组件传递新增的评论
      props.commentChildToParent(newComment)
  }

  const likepost = (postId)=>{
    apiPostLike({postId:postId}).then(res=>{
        if (res.code == 200) {         
          let temp = val.favcount
          if(res.data=="like"){
              temp +=1;
              setVal({isFollowed:1,favcount:temp,count:props.count,postId:props.postId})  
          }else if(res.data=="unlike"){
              temp -=1;
              setVal({isFollowed:0,favcount:temp,count:props.count,postId:props.postId})  
          }      
        }
    });
  };
  return (

      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0,color:"text.secondary" }}>
        <Toolbar>
            <CommentNav sx={{width:'100%'}}>
                <CommentIconWrapper>
                  <DriveFileRenameOutlineOutlinedIcon />
                </CommentIconWrapper>
                <form>
                  <StyledInputBase
                    sx={{width:'100%'}}
                    ref={BottomCommentBarInput}
                    placeholder="说点什么…"
                    inputProps={{ 'aria-label': 'comment' }}
                    onBlur={(e) => lostFocus()}
                    // onKeyPress={readyToComment}
                    onChange={readyToComment}
                    type="search"
                  />
                </form>
            </CommentNav>
            <Box sx={{ flexGrow: 1 }} />
            {isKeyPress 
              ? <Button onClick={(e)=>comment()} variant="contained" sx={{marginLeft:"8px",borderRadius:'15px'}}>发布</Button>
              : <Box sx={{display:"flex",flexWrap:"nowrap",alignItems:"center"}}>
              <IconButton color="inherit" onClick={(e)=>likepost(val.postId)}>
                    {(val.isFollowed === 1)? <FavoriteIcon sx={{color:'#c43131fc'}}/> : <FavoriteBorderIcon />}
              </IconButton>
              <Typography variant="body2" display="block" gutterBottom sx={{marginBottom:'0.1em',marginLeft:'-5px'}}> 
                    {val.favcount}
              </Typography>
              <IconButton color="inherit">
                <TextsmsOutlinedIcon />
              </IconButton>
              <Typography variant="body2" display="block" gutterBottom sx={{marginBottom:'0.1em',marginLeft:'-5px'}}> 
                    {val.count}
              </Typography>
            </Box>}
        </Toolbar>
    </AppBar>
  );
}  

const handleDifferTime = (createTime) => {
  const date = new Date(createTime);
  const timestamp = date.getTime();
  const dateNow = new Date();
  const timestampNow = dateNow.getTime();
  const timestampDiffer = timestampNow - timestamp; 
  if (timestampDiffer < 3600) {
      return date.getSeconds()+"分钟前";
  } else if (timestampDiffer >= 3600 && timestampDiffer < 43200) {
      return  date.getHours()+"小时前";
  } else {
      return (date.getMonth()+1)+"-"+date.getDate();
  }
}

export default function MyPostM(props) {
  const theme = useTheme();
  const DividerColor = theme.palette.mode === 'dark' ? '#8b8b8bb8' : '#68686891';
  const [items,setItems] = React.useState([]);
  // const history = useHistory();
  React.useEffect(()=>{
    apiPostDetail({uid:props.match.params.uid}).then(res=>{
			if (res.code == 200) {
					res.data.author.url = "/space/"+res.data.author.uid;
          // res.data[i].url = "postDetail/"+res.data[i].uid;
          res.data.createTime = handleDifferTime(res.data.createTime);
          res.data.imgArr = JSON.parse(res.data.images);
          setItems(res.data);
			}

		});
  },[]);
  const [count, setCount] = React.useState(0)
  const getChildrenCount = (value) => {
    setCount(value)
  }
  const [newData, setNewData] = React.useState({})
  const [newChildData, setNewChildData] = React.useState({})

  const getInputComment = (newData) => {
    setNewData(newData)
  }
  const getNewChildComment = (newChildData) => {
    if(newChildData.Id){    
      setNewChildData(newChildData)
    }
    
  }
  
  const [foucusData, setfoucusData] = React.useState({})

  const changeFocus =(data)=>{
    setfoucusData(data)
  }
  let userInfoStr = window.localStorage.getItem("userInfo");
  if(!userInfoStr){
    userInfoStr='{"userInfo":{"avatar":"未登陆","nickname":"未登陆"}}';
  }
  let userInfo = JSON.parse(userInfoStr);

    return (
        <Box sx={{padding:0,height:'auto',bgcolor: 'background.paper'}}>
            <CssBaseline />
            <Navbar author={items.author} />
            <Toolbar />
            <Media img={items.imgArr} content={items.content} title={items.title} publishTime={items.createTime}/>      
            <CommentInput postId={items.uid} userInfo={userInfo} count={count} getNewComment={getInputComment} />
            <CommentList uid={props.match.params.uid} getCount={getChildrenCount} newData={newData} newChildData={newChildData} sendFocus={changeFocus}/>  
            {/* <Divider/> */}

            <Divider sx={{ width: '26%', margin: '0 auto',
            paddingBottom:'55px',paddingTop:'40px',marginBottom:'55px',color:DividerColor}}>THE END</Divider>

            <BottomCommentBar commentChildToParent={getNewChildComment} commentData={foucusData} userInfo={userInfo} postId={items.uid} count={count} favcount={items.followerCount} isFollowed={items.isFollowed}/>
            {/* <Divider>到底了</Divider> */}
        </Box>
    );
}