import * as React from 'react';
import Box from '@mui/material/Box';
import AppbarCrumbs from '../components/AppbarCrumbs';
import EditIntroduction from '../components/EditIntroduction';
import Navigation from '../components/Navigation';

export default function EditIntroPage(){

    return (
    	<Box>
    		<AppbarCrumbs/ >
    		<EditIntroduction/ >
    		<Navigation value="mine"/>
    	</Box>
    );
}