import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';

import { useHistory, useParams } from "react-router-dom";

import {apiArtistDetail,apiArtistFollow} from '../common/Api.js';

import { useSnackbar } from 'notistack';

import Navigation from './Navigation';
import SpaceCareer from './SpaceCareer';
import PostListCommon from './postListCommon';
import SpaceAbout from './SpaceAbout';
import SpaceSalon from './SpaceSalon';
import CollectionsList from './CollectionList.js';

const OrgText = styled(Box)(() => ({
  display:'inline',
  textDecoration:'underline',
}));

const TabPanelItem = styled(TabPanel)(() => ({
	padding:0,
}));

export default function Space(){

	const params = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const [Data,setData] = React.useState({});
	const [Organ,setOrgan] = React.useState({});
	const history = useHistory();
	const [value, setValue] = React.useState('1');
	const [role,setRole] = React.useState(0);

	React.useEffect(()=>{
		apiArtistDetail({uid:params.uid}).then(res=>{
			if (res.code == 200) {
				setData(res.data);
				res.data.organ.url = "/spaceOrg/"+res.data.organ.uid;
				setOrgan(res.data.organ);
				setRole(res.data.role);
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		})
	},[]);

	const handleHref = (url) => {
		history.push(url);
	};

	const toggleFollow = (item) => e => {
		apiArtistFollow({uid:item.uid}).then(res=>{
			if (res.code == 200) {
				let followerCountUpdate = item.followerCount;
				if (res.data == 1){
					followerCountUpdate++;
				} else {
					followerCountUpdate--;
				}
				setData((Data)=>({...Data, isFollowed:res.data, followerCount:followerCountUpdate}));
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
	}

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

    return (
		<Box sx={{ maxWidth: '100%',position:'relative' }}>
			<Container fixed>
				<Box

					sx={{
						// position:'relative',
						width:'100%',
						marginBottom:'6px',
						marginTop:4,
						display:'flex',
						padding:"0 5px",
						marginRight:'30px'
					}}
				>	
					<Avatar src={Data.avatar} sx={{
						display:'inline-block',
						marginLeft:'10px',
						marginRight: '30px',
						width:100,
						height:100,
					}}/ >
					{/* <Box> */}
				
					<Box sx={{					
							margin:'auto',
							textAlign:'center',
						}}>
						<Box component="span" sx={{ fontSize: 18,fontWeight:600, display:'block' }} color="text.secondary" >
							{Data.postCount}
						</Box>
						<Box component="span" sx={{ fontSize: 14,fontWeight:400, display:'block' }} color="text.secondary" >
							动态
						</Box>
					</Box>	
					<Box sx={{					
							margin:'auto',
							textAlign:'center',
						}}>
						<Box component="span" sx={{ fontSize: 18,fontWeight:600, display:'block' }} color="text.secondary" >
							{Data.followerCount}
						</Box>
						<Box component="span" sx={{ fontSize: 14,fontWeight:400, display:'block' }} color="text.secondary" >
							粉丝
						</Box>
					</Box>	
					<Box sx={{					
							margin:'auto',
							textAlign:'center',
						}}>
						<Box component="span" sx={{ fontSize: 18,fontWeight:600, display:'block' }} color="text.secondary" >
							{Data.followCount}
						</Box>
						<Box component="span" sx={{ fontSize: 14,fontWeight:400, display:'block' }} color="text.secondary" >
							已关注
						</Box>
					</Box>	
					{/* </Box> */}
				</Box>

				
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					sx={{pt:1}}
				>	
					<Typography variant='h5' sx={{fontWeight:'bold',mx:2}}>
						{Data.nickname}{Organ.uid!=""?<OrgText onClick={(e)=>handleHref(Organ.url)}>@{Organ.nickname}</OrgText>:<OrgText></OrgText>}
					</Typography>
					<Button onClick={toggleFollow(Data)} sx={{mr:2}} variant={Data.isFollowed==1?"outlined":"contained"} color="primary" size="medium" >
						{Data.isFollowed==1?"已关注":"+关注"}
					</Button>
				</Stack>

				<CardContent sx={{pt:2}}>
					<Typography variant="body2" color="text.secondary">
						{Data.introduction}
					</Typography>
				</CardContent>

				

				<Box>
					<TabContext value={value}>
						<TabList onChange={handleChange} aria-label="lab API tabs example" centered>
							<Tab label="动态" value="1" />
							{role==2?<Tab label="艺术履历" value="4" />:<Box></Box> }
							{role==2?<Tab label="画廊" value="2" />:<Box></Box> }
							{role==3?<Tab label="关于我们" value="3" />:<Box></Box> }
							
						</TabList>
						<TabPanelItem value="1"><PostListCommon uid={params.uid} method="2" /></TabPanelItem>
						<TabPanelItem value="4"><SpaceCareer uid={params.uid} /></TabPanelItem>
						<TabPanelItem value="2"><SpaceSalon uid={params.uid} /></TabPanelItem>
						<TabPanelItem value="3"><SpaceAbout uid={params.uid} /></TabPanelItem>
						
						
					</TabContext>
				</Box>

			</Container>
			<Navigation />

	    </Box>
    );
}