import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import Link from '@mui/material/Link';
import { Redirect, useHistory} from "react-router-dom";

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import { useSnackbar } from 'notistack';
import {apiUserSendCode,apiUserSignin} from '../common/Api';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{my:3}}>
      Copyright © MadMeta.Art .
    </Typography>
  );
}




export default function SignInSide() {

    let [phone,setPhone]=React.useState("");
    let [code,setCode]=React.useState("");
    let [password,setPassword]=React.useState("");
    let [sendCodeText,setSendCodeText]=React.useState("发送验证码");
    let [sendStatus,setSendStatus]=React.useState(false);
    let [method,setMethod]=React.useState(1);
    let [signinStatus,setSigninStatus]=React.useState(false);
    const [num,setNum] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();

    const handleHref = (url) => {
		history.push(url);
	};


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    apiUserSignin({phone:data.get("phone"),code:data.get("code"),method:method,password:data.get("password")}).then(res=>{
      if (res.code == 200){
        window.localStorage.setItem('token',res.data.token);
        enqueueSnackbar("欢迎来到MadMeta",{variant:"success"});
        let s1 = setInterval(()=>{
          setSigninStatus(true);
          if (signinStatus){
            clearInterval(s1);
          }
        },1000);
      } else {
        enqueueSnackbar(res.msg,{variant:"error"});
      }
    })
    
  };

  const sendCode = () => {
    var phoneReg = /^[1]([3-9])[0-9]{9}$/
    if (phoneReg.test(phone)){

      apiUserSendCode({phone:phone}).then(res=>{
        if (res.code == 200){
          handleSendButton();
        } else {
          enqueueSnackbar(res.msg,{variant:"error"});
        }
      })

    }
     else {
      enqueueSnackbar("请填写正确的国内手机号",{variant:"warning"});
    }
    
  }

  const handleSendButton = () => {
    let t = 60
    setSendStatus(true);
    setNum(t)
    const t1 = setInterval(()=>{
      t = t - 1;
      setNum(t)
      if (t == 0){
        setSendStatus(false)
        setSendCodeText("发送验证码")
        clearInterval(t1)
      }
    },1000);
  }

  React.useEffect(()=>{
    if (num > 0) {
      setSendCodeText(num)
    }
  })

  const switchMethod = () => {
    if (method == 1){
      setMethod(2)
    } else {
      setMethod(1)
    }
  }

  const phoneChange = (e) =>{
   setPhone(e.target.value)
  }

  const codeChange = (e) =>{
   setCode(e.target.value)
  }

  const passwordChange = (e) =>{
   setPassword(e.target.value)
  }



  if (signinStatus) {
    return (<Redirect to="/personal" />);
  } else {
  return (
    <Box>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            
              <Typography component="h1" variant="h5">
                登录
              </Typography>

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="手机号"
                name="phone"
                autoComplete="phone"
                value={phone}
                onChange={phoneChange}
                autoFocus
              />
              
              {method == 1?(
              <TextField
                margin="normal"
                required
                name="code"
                label="验证码"
                id="code"
                value={code}
                onChange={codeChange}
                sx={{width:'60%'}}
              />
              ):null}
              
              {method == 1?(
              <Button size="large" variant={sendStatus?'disabled':'text'} id="sendCode" onClick={sendCode} sx={{
                mt:3,
                ml:2
              }}>
                {sendCodeText}
              </Button>
              ):null}

              {method == 2?(
              <TextField
                margin="normal"
                required
                fullWidth
                type="password"
                id="password"
                label="密码"
                name="password"
                autoComplete="password"
                value={password}
                onChange={passwordChange}
              />
              ):null}
              
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                立即登录
              </Button>
              <Grid container>
                <Grid item xs>
                    <Typography variant="body2" onClick={(e)=>handleHref("/signup")}>
                        {"尚未拥有账号？注册"}
                    </Typography>
                </Grid>
                <Grid item onClick={switchMethod}>
                    <Typography  variant="body2">
                        {method == 1?'密码登录':'验证码登录'}
                    </Typography>
                  
                </Grid>
              </Grid>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
  }
}