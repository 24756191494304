import * as React from 'react';
import Box from '@mui/material/Box';
import Appbar from '../components/Appbar';
import { CssBaseline } from '@mui/material';

export default function AgreementSecret(){

    return (
    	<Box>
    		<Appbar/>
            <CssBaseline/>
    	</Box>
    );
}