import React from 'react'; 
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import ScrollToTop from './common/ScrollToTop';

import Home from './pages/Home';
import Personal from './pages/Personal';
import PersonalCareer from './pages/PersonalCareer';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import Annonce from './pages/Annonce';
import Collections from './pages/Collections';
import Collection from './pages/Collection';
import CollectionSub from './pages/CollectionSub';
import Space from './pages/Space';
import Artists from './pages/Artists';
import SpaceOrg from './pages/SpaceOrg';
import Page404 from './pages/404';
import Market from './pages/Market';
import AgreementUser from './pages/AgreementUser';
import AgreementSecret from './pages/AgreementSecret';
import Search from './pages/Search';
import Post from './pages/Post';
import Settings from './pages/Settings';
import Draw from './pages/Draw';
import Comment from './components/Comment';
import Oldshit from './components/Artistdetail_old';
import Banner from './pages/Banner';
import Edit from './pages/Edit';
import EditAvatar from './pages/EditAvatar';
import EditIntroduction from './pages/EditIntroduction';
import EditNickname from './pages/EditNickname';
import EditCareer from './pages/EditCareer';
import Message from './pages/Message';
import Peanut from './pages/Peanut';

function router(){
	return (
		<Router>
			<ScrollToTop>
				<Switch>
				    <Route exact path="/" component={Home} />
				    <Route exact path="/Annonce" component={Annonce} />
				    <Route exact path="/personal" component={Personal} />
                    <Route exact path="/personalCareer" component={PersonalCareer} />
				    <Route exact path="/collections" component={Collections} />
					<Route path="/collection/:uid/:number" component={CollectionSub} />
				    <Route path="/collection/:uid" component={Collection} />
				    <Route exact path="/artCircle" component={Artists} />
				    <Route path="/space/:uid" component={Space} />
				    <Route path="/spaceOrg/:uid" component={SpaceOrg} />
				    <Route exact path="/signin" component={Signin} />
				    <Route exact path="/signup" component={Signup} />
				    <Route exact path="/market" component={Market} />
				    <Route exact path="/agreementUser" component={AgreementUser} />
				    <Route exact path="/agreementSecret" component={AgreementSecret} />
				    <Route exact path="/search" component={Search} />
				    <Route exact path="/post" component={Post} />
				    <Route exact path="/settings" component={Settings} />
				    <Route path="/postDetail/:uid" component={Comment} />
				    <Route exact path="/draw" component={Draw} />
				    <Route exact path="/banner/:uid" component={Banner} />
				    <Route exact path="/edit" component={Edit} />
				    <Route exact path="/editIntroduction" component={EditIntroduction} />
				    <Route exact path="/editNickname" component={EditNickname} />
				    <Route exact path="/editAvatar" component={EditAvatar} />
                    <Route exact path="/editCareer" component={EditCareer} />
				    <Route exact path="/oldshit" component={Oldshit} />
					<Route exact path="/message" component={Message} />
					<Route exact path="/peanut" component={Peanut} />
				    <Route component={Page404} />
			    </Switch>
		    </ScrollToTop>
		</Router>
	);
}

export default router;