import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


import Button from '@mui/material/Button';
import {useHistory} from "react-router-dom";

const Item = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.primary.main,
}));

export default function FullWidthGrid() {

  const history = useHistory();

  const handleHref = (url) => {
    history.push(url);
  };

  const handleHrefOut = (url) => {
    window.location.href = url;
  }

  return (
    <Box sx={{ flexGrow: 1, my:1.5}}>
      <Grid 
        container
        direction="row"
        justify="flex-end"
        alignItems="center" 
        spacing={2} 
        textAlign="center"
        sx={{
          display:{md:'none',color:'primary'}
      }}>
        <Grid item xs={4} onClick={(e)=>handleHrefOut('http://120.25.224.86:8004')} >
          <Item>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-a-yaoqinghaoyou-hei2"></use>
            </svg>
          </Item>
          <Typography variant="body2">邀请好友</Typography>
        </Grid>
        <Grid item xs={4} onClick={(e)=>handleHref('/peanut')}>
          <Item>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-huasheng-hei"></use>
            </svg>
          </Item>
          <Typography variant="body2">领取花生</Typography>
        </Grid>
        <Grid item xs={4} onClick={(e)=>handleHref('/draw')} label="抽签">
          <Item>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-a-chouqian-hei2"></use>
            </svg>
          </Item>
          <Typography variant="body2">抽签</Typography>  
        </Grid>
      </Grid>
    </Box>
    
  );
}


