import * as React from 'react';
import Box from '@mui/material/Box';

import Appbar from '../components/Appbar';
import Space from '../components/Space';
import Footer from '../components/Footer';

export default function SpaceOrgPage(){

    return (
    	<Box>
    		<Appbar/ >
    		<Space/ >
    		<Footer/ >
    	</Box>
    );
}