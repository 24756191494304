import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

import Appbar from '../components/Appbar';
import Searchbar from '../components/Searchbar';
import Banner from '../components/Banner';
import Middlebar from '../components/Middlebar';
import Title from '../components/Title';
import Collectionsbar from '../components/Collectionsbar';
import Artistsbar from '../components/Artistsbar';
import Whatisnft from '../components/Whatisnft';
import Whatismadmeta from '../components/Whatismadmeta';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import Boxbg from '../components/Boxbg';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import PropTypes from "prop-types";

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

export default function Home(){

    return (
    	<Boxbg>
        <Appbar/ >
    		<Banner/ >
    		<Container  fixed>
    		  <Middlebar/ >
    		</Container>
        <Divider sx={{opacity:0.3}}/>
        <Collectionsbar/ >
        <Divider sx={{opacity:0.3}}/>
        <Artistsbar/ >
        <Whatismadmeta/ >
        <Footer/ >
        <Navigation value="home"/ >
    	</Boxbg>
    );
}