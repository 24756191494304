import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ImageList from '@material-ui/core/ImageList';
import Typography from '@mui/material/Typography';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Link, useHistory } from "react-router-dom";

import { apiCollectionList,apiCollectionFollow } from '../common/Api.js';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
	},
	gridList: {
		flexWrap: 'nowrap'
	},
	itemImg:{
		borderRadius:'20px'
	}
}));

export default function CollectionsList(props){

	const [items,setItems] = React.useState([]);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	React.useEffect(()=>{
		apiCollectionList({pageSize:props.pageSize}).then(res=>{
			if (res.code == 200) {
				for (var i = 0; i < res.data.length; i++) {
					res.data[i].url = "collection/"+res.data[i].uid;
					res.data[i].artist.url = "space/"+res.data[i].artist.uid;
				};
				setItems(res.data);
			}
		});
	},[]);


	const toggleFollow = (item,i) => e => {
		apiCollectionFollow({uid:item.uid}).then(res=>{
			if (res.code == 200) {
				setItems(
					items.map((itemc,ic)=>{
						if (ic == i) {
							return { ...itemc, isFollowed:res.data};
						} else {
							return itemc;
						}
					})
				);
			} else {
                if (res.code == 401){
                    enqueueSnackbar("请先登录",{variant:"warning"});
                } else {
                    enqueueSnackbar(res.msg,{variant:"error"});
                }
			}
		});
		e.stopPropagation();
	}

	const handleNth = (e) => {
		e.stopPropagation();
	};

	const handleHref = (url) => {
		history.push(url);
	};
	

    return (
    	<Box>
	    	<Container fixed>
				<Box sx={{py:1}}>
					<Typography onClick={(e)=>handleHref('/collections')} variant="h6" sx={{fontWeight:'bold'}}>
						首发藏品<ArrowForwardIosIcon sx={{pt:1}}/>
					</Typography>
				</Box>
		    	<Box className={classes.root}>
		    		<ImageList className={classes.gridList} cols={10}>
	    				{ items.map( (item, i) => 
	    					<Box key={i} onClick={(e)=>handleHref(item.url)} sx={{minWidth: '200px',minHeight:'250px',mr:1,position:'relative'}}>
	    						<img src={item.cover} className={classes.itemImg} width="200" height="200" />
	    						<Box
									sx={{
										position:'absolute',
										top:0,
										right:0,
										height:'30px',
										width:'30px',
										lineHeight:'30px',
										pt:'4px',
										mt:0.5,
										mr:0.5,
										textAlign:'center',
										borderRadius:'50%',
										background:'rgba(0,0,0,0.3)'
									}}
									onClick={toggleFollow(item,i)}
								>
									{item.isFollowed == 1?<FavoriteIcon color="error"/>:<FavoriteBorderIcon color="error"/>}
								</Box>
	    						<Typography variant="body1">
	    							{item.collectionName}
	    						</Typography>
	    					</Box>

	    				)}
	    			</ImageList>
	    		</Box>
	    	</Container>
	    </Box>
    );
}

