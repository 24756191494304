import * as React from 'react';
import Box from '@mui/material/Box';

import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export default function Title(props) {
    return (
        <Box sx={{py:2}}>
            <Container fixed>
            <AutoAwesomeIcon fontSize="inherit" / >
            <Typography variant='h6' sx={{display:'inline',pl:1}}>{props.value}</Typography>
            </Container>
        </Box>
    );
}