import * as React from 'react';
import Box from '@mui/material/Box';
import AppbarCrumbs from '../components/AppbarCrumbs';
import Edit from '../components/Edit';
import Navigation from '../components/Navigation';

export default function EditPage(){

    return (
    	<Box>
    		<AppbarCrumbs/ >
    		<Edit/ >
    		<Navigation value="mine"/>
    	</Box>
    );
}