import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { apiUserEditIntroduction } from '../common/Api.js';

import { useSnackbar } from 'notistack';

export default function EditIntroduction(){

	const [Intro,setIntro] = React.useState("");
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(()=>{
		let userInfoStr = window.localStorage.getItem("userInfo");
		let userInfo = JSON.parse(userInfoStr);
		setIntro(userInfo.introduction);
	},[]);

	const introChange = (e) =>{
		setIntro(e.target.value);
	}

	const handleClick = () =>{
		apiUserEditIntroduction({introduction:Intro}).then(res=>{
			if (res.code == 200) {
				enqueueSnackbar("更新成功",{variant:"success"});
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
	}

    return (
    	<Box>
    		<Container fixed>
    			<TextField
					id="outlined-multiline-static"
					label="修改个人简介"
					fullWidth
					multiline
					rows={7}
					onChange={introChange}
					defaultValue={Intro}
					autoFocus
					sx={{
						mt:4
					}}
				/>
				<Button
					type="text"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
					onClick={handleClick}
				>
                	确认修改
                </Button>
    		</Container>
    	</Box>
    );
}