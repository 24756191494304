import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import { red, blueGrey, orange,grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { Link, useHistory } from "react-router-dom";

import { styled } from '@mui/material/styles';
import {isMobile} from 'react-device-detect';

import { useParams } from "react-router-dom";

import {apiCollectionDetail,apiCollectionFollow,apiArtistFollow,apiCollectionSub} from '../common/Api.js';

import { useSnackbar } from 'notistack';
import { width } from '@mui/system';
import { makeStyles } from '@material-ui/core/styles';


import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Global } from '@emotion/react';
import Skeleton from '@mui/material/Skeleton';
import ReactPullLoad,{ STATS } from "react-pullload";
import ".././ReactPullLoad.css";
const OrgText = styled(Box)(() => ({
  display:'inline',
  textDecoration:'underline',
}));

const CoverImage = styled('div')({
	'img': {
	  width: '100%',
	},
  });

  const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
	},
	gridList: {
		flexWrap: 'nowrap',
		width:'86.5%',
	},
	itemImg:{
		borderRadius:'12px',
		objectFit: 'cover'
	},
	backgroudTheme:{
		background:theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.4)',
	},
	subimg:{
		height:'auto!important',
		borderTopLeftRadius:'6px',
		borderTopRightRadius:'6px',
	},
	subItem:{
		borderBottomLeftRadius:"6px",
		borderBottomLeftRadius:"6px",
		margin:"8px 0",
		backgroundColor:"#4e4b47",
	} 
}));




export default function Coldetail(){
	const classes = useStyles();
	const [Col,setCol] = React.useState({});
	const [items,setItems] = React.useState([]);
	const [Artist,setArtist] = React.useState({});
	const [Organ,setOrgan] = React.useState({});
	const params = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const CardPadding = isMobile?2:4;
	const ref = React.useRef();
	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [sub, setSub] = React.useState([]);
	const [pullload, setPullload] = React.useState({
		// scroll 相关	
		hasMore: true,		
		action: STATS.init,		
		isMore:true,
		page:1,
	
		});
	const toggleDrawer = (open) => (event) => {
		setOpen(open);
		//获取衍生品
		if(open){
			apiCollectionSub({uid:params.uid,pageCount:1,pageSize:18}).then(res=>{
				console.log(res);
				if(res.code == 200){
					for (var i = 0; i < res.data.length; i++) {
						res.data[i].url = params.uid+"/"+res.data[i].number;
					}
						setSub(res.data);
						//延迟执行
						// setTimeout(() => {
							setLoading(false);	
						// }, 200)
				}
			});
		}else{
			//关闭更多的时候 初始化上拉加载状态
			setPullload({
				hasMore: true,		
				action: STATS.init,		
				isMore:true,
				page:1,
				

				})
		}
	};  

	React.useEffect(()=>{
		apiCollectionDetail({uid:params.uid}).then(res=>{
			if (res.code == 200) {
				res.data.bcTxHash = (res.data.bcTxHash ?res.data.bcTxHash :"上链确认中");
				res.data.goBcTxHash = (res.data.bcTxHash ?"https://explorer.testnet.bianjie.ai/#/txs/"+res.data.bcTxHash :"#");
				if(res.data.CollectionSub){
					for (var i = 0; i < res.data.CollectionSub.length; i++) {
						res.data.CollectionSub[i].url = params.uid+"/"+res.data.CollectionSub[i].number;
					}
					setItems(res.data.CollectionSub);
				}
				setCol(res.data);
				res.data.artist.url = "/space/"+res.data.artist.uid
				setArtist(res.data.artist);
				res.data.artist.organ.url = "/organization/"+res.data.artist.organ.uid;
				setOrgan(res.data.artist.organ);
				const node = document.createRange().createContextualFragment(res.data.detail);
    			ref.current.appendChild(node);
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		})
	},[]);

	const handleHref = (url) => {
		history.push(url);
	};
	const handleHrefOuter = (url) => {
		window.location.href = url
		};
	const toggleFollow = (item) => e => {
		apiCollectionFollow({uid:item.uid}).then(res=>{
			if (res.code == 200) {
				let followerCountUpdate = item.followerCount;
				if (res.data == 1){
					followerCountUpdate++;
				} else {
					followerCountUpdate--;
				}
				setCol((Col)=>({...Col, isFollowed:res.data, followerCount:followerCountUpdate}));
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
	}

	const toggleFollowArtist = (item) => e => {
		apiArtistFollow({uid:item.uid}).then(res=>{
			if (res.code == 200) {
				let followerCountUpdate = item.followerCount;
				if (res.data == 1){
					followerCountUpdate++;
				} else {
					followerCountUpdate--;
				}
				setArtist((Artist)=>({...Artist, isFollowed:res.data, followerCount:followerCountUpdate}));
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
	}
	const drawerBleeding = 0;
	const Puller = styled(Box)(({ theme }) => ({
		width: 30,
		height: 6,
		backgroundColor: theme.palette.mode === 'light' ? grey[300] : "#6e6a6a",
		borderRadius: 3,
		position: 'absolute',
		top: 8,
		left: 'calc(50% - 15px)',
		zIndex:'999',
	  }));
	  const StyledBox = styled(Box)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
	  }));
	const list = () => (
		<Box
		  sx={{ width: 'auto'}}
		  role="presentation"
		//   onClick={toggleDrawer(false)}
		//   onKeyDown={toggleDrawer(false)}

		>
			<StyledBox
			sx={{
				position: 'absolute',
				top: -drawerBleeding,
				borderTopLeftRadius: 8,
				borderTopRightRadius: 8,
				visibility: 'visible',
				right: 0,
				left: 0,
			}}
			>
			{/* <Puller /> */}
			</StyledBox>
			{loading
				?(
			<Box sx={{display: 'flex', flexWrap: 'nowrap',flexDirection: 'row',justifyContent: 'space-evenly',mt:2}}>
				<StyledBox
				sx={{padding:'8px',mt:4,height: '100px',width:'30%',overflow: 'auto',}}
				>
				<Skeleton variant="rectangular" height="100%" />
				</StyledBox>
				<StyledBox
				sx={{padding:'8px',mt:4,height: '100px',width:'30%',overflow: 'auto',}}
				>
				<Skeleton variant="rectangular" height="100%" />
				</StyledBox>
				<StyledBox
				sx={{padding:'8px',mt:4,height: '100px',width:'30%',overflow: 'auto',}}
				>
				<Skeleton variant="rectangular" height="100%" />
				</StyledBox>
			</Box>
			)
			:(
			<Box sx={{mt:4,padding:'0 12px'}}>
				<ImageList cols={3} gap={8} rowHeight="auto">

				{ sub.map( (v, i) => 
					// <img src={v.cover}  width='30%' className={classes.itemImg} />
					<ImageListItem key={v.cover} sx={{marginBottom:'8px',}} onClick={(e)=>handleHref(v.url)} >
								<img
								src={`${v.cover}?w=248&fit=crop=format`}
								srcSet={`${v.cover}?w=248&fit=crop=format&dpr=2 2x`}
								alt={v.number}
								loading="lazy"
								className={classes.subimg}
								/>
								<Typography variant="body1" sx={{lineHeight:"1.25",fontSize:"0.825rem",padding:"6px",borderBottomLeftRadius:"6px",borderBottomRightRadius:"6px",background:'rgb(139 134 134 / 16%)',}}>
	    							#{v.number}
	    						</Typography>
					</ImageListItem>
				)}
				</ImageList>
			</Box>
			)}
		</Box>
	  );


	 const handleAction = (action) => {
		// console.info(action, pullload.action, action === pullload.action);
		if(action !== STATS.loading){
			return false;
		}
		setPullload({
			hasMore: true,
			
		});
		//new action must do not equel to old action
		// if(action === this.state.action) {
		// 	return false
		// }
		// if(action === STATS.refreshing) { //刷新
		// 	 handRefreshing();
		// } else if(action === STATS.loading) { //加载更多
		// 	handLoadMore();
		// } else {
		// 	//DO NOT modify below code
		// 	setPullload({
		// 		action: action
		// 	})
		// }

		if(action === STATS.loading) { //加载更多
			handLoadMore();
		}else {
			//DO NOT modify below code
			setPullload({
				
				action: action
			})
		}  
		
	}
	//刷新
	const handRefreshing = () => {
		if(STATS.refreshing === pullload.action) {
			return false
		}
		setTimeout(() => {
			//refreshing complete
			setPullload({
				hasMore: true,
				action: STATS.refreshed
			});
		}, 2000)
		setPullload({
			
			action: STATS.refreshing
		})
	}
	//加载更多
	const handLoadMore = () => {

		if(STATS.loading === pullload.action) {
			return false
		}
		setTimeout(() => {
			if(pullload.isMore === false || pullload.hasMore===false) {
				setPullload({
					
					action: STATS.reset,
					hasMore: false
				});
			} else {
				var n=pullload.page;
					n++;
					console.log(pullload)

					apiCollectionSub({uid:params.uid,pageCount:n,pageSize:18}).then(res=>{
						if(res.code == 200 && pullload.isMore){
							var newdata = sub.concat(res.data);
							setSub(newdata);
							setPullload({
								
								action: STATS.reset,
								isMore:Math.ceil(Col.publishCount/18)==n?false:true,
								page:n
							})	
						}
						else{
							setPullload({
								
								action: STATS.reset,
								hasMore:false,
							})	
						}
					});
			}
		}, 500)
	
		setPullload({
			
			action: STATS.loading
		})
	}

	
    return (
    	<Box>
    		<Container fixed sx={{pt:3}}>
    			<Grid container spacing={2} >
    				<Grid item xs={12} md={6}>
	    				<Card sx={{ maxWidth: '100%',position:'relative' }}>
							<CardMedia
								component="img"
								image={Col.cover}
								alt="green iguana"
								sx={{borderRadius:'8px'}}
							/>
					    </Card>
						<Box sx={{
								  display: 'flex',
								  justifyContent: 'space-between',
								  flexDirection: 'row',
								  flexWrap: 'nowrap',
								}}>
						<ImageList className={classes.gridList} cols={10}>
	    				{ items.map( (item, i) => 
	    					<Box key={i} sx={{minWidth: '80px',mr:1,position:'relative',marginTop:'16px',height:'auto!important'}} onClick={(e)=>handleHref(item.url)}>
	    						<img src={item.cover} className={classes.itemImg} width="80" height="80" />
	    						<Box
									sx={{
										position:'absolute',
										top:0,
										right:0,
										height:'24px',
										width:'3rem',
										lineHeight:'30px',
										pt:'4px',
										mt:1.2,
										mr:0.5,
										textAlign:'center',
										borderRadius:'6px',
										background:'rgb(0 0 0 / 43%)'
									}}
									// onClick={toggleFollow(item,i)}
								>
									<Typography variant="body1" className={classes.subItem} sx={{lineHeight:"1.25",fontSize:"0.825rem"}}>
	    							#{item.number}
	    							</Typography>
								</Box>
	    						
	    					</Box>

							)}
						</ImageList>
						<Box
									sx={{
										position:'absolute',
										right:0,
										height:81,
										width:'12%',
										mt:2,
										mr:2,
										borderBottomRightRadius:'6px',
										borderTopRightRadius:'6px',
										background:'rgb(139 134 134 / 16%)',
										display:'flex',
										flexDirection:'column',
										justifyContent:'center',
										alignItems:'center',
									}}
									onClick={toggleDrawer(true)}
								>
									<Typography variant="body1" sx={{lineHeight:"1.25",fontSize:"0.825rem",marginBottom:'2px'}}>
									{Col.publishCount}个
	    							</Typography>
									<Typography variant="body1" sx={{lineHeight:"1.25",fontSize:"0.825rem"}}>
	    								更多
	    							</Typography>
									
								</Box>

								
						</Box>
						
	    			</Grid>
	    			<Grid item xs={12} md={6} sx={{marginTop:'-4px',}}>
	    				<Card sx={{ maxWidth: '100%',position:'relative' }}>
							<CardHeader title={Col.collectionName}
								action={
									<Button onClick={toggleFollow(Col)} variant="text" color="error" size="small" endIcon={Col.isFollowed==1?<FavoriteIcon />:<FavoriteBorderIcon />}>
										{Col.followerCount}
									</Button>
								}
							/>
							<CardContent sx={{py:0}}>
								<Box color="warning.main">
									<Typography variant="h4" gutterBottom>¥{Col.price}</Typography>
								</Box>
								<Box sx={{
									textAlign:'center',
									py:1
						    	}}>	
						    		<Button color="error" variant="contained" size="large" sx={{
						    			borderRadius:10,
						    			width:'85%',
						 				margin:'0 auto',
						    		}}>
							          立即购买
							        </Button>
						    	</Box>
							</CardContent>
							<CardContent sx={{py:1}}>
								<Typography variant="h6" gutterBottom>藏品信息</Typography>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box>发行数量</Box>
									<Box>{Col.publishCount}份</Box>
								</Stack>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box>当前剩余</Box>
									<Box>{Col.stock}份</Box>
								</Stack>
							</CardContent>
		    				<CardContent sx={{py:1}}>
		    					<Typography variant="h6" gutterBottom>认证信息</Typography>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box sx={{minWidth:"30%"}}>合约地址</Box>
									<Box sx={{overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={(e)=>handleHrefOuter(Col.goBcTxHash)}>{Col.bcTxHash}</Box>
								</Stack>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box>认证标准</Box>
									<Box>ERC-721</Box>
								</Stack>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box>认证网络</Box>
									<Box>BSN</Box>
								</Stack>
							</CardContent>
					    </Card>
	    			</Grid>
    			</Grid>
    			<Grid container spacing={1} sx={{pt:2}}>
    				<Grid item xs={12} md={3}>
	    				<Card sx={{height:'200px',pt:2}}>
							<Box>
								<Avatar onClick={(e)=>handleHref(Artist.url)} src={Artist.avatar} sx={{
									margin:'auto',
									width:100,
									height:100,
								}}/ >
								<Box sx={{
									textAlign:'center',
									fontSize:'1.25em',
									fontWeight:'bold',
									my:0.5
								}}>
									{Artist.nickname}@<OrgText onClick={(e)=>handleHref(Organ.url)}>{Organ.nickname}</OrgText>
								</Box>
								<Box sx={{
									textAlign:'center',
									fontWeight:'bold',
								}}>	
									{Artist.followerCount}粉丝
									<Button onClick={toggleFollowArtist(Artist)} variant={Artist.isFollowed == 1?"outlined":"contained"} size="small" sx={{ml:2}}>
										{Artist.isFollowed == 1?"已关注":"+关注"}
									</Button>
								</Box>
							</Box>
					    </Card>
	    			</Grid>
	    			<Grid item xs={12} md={9}>
						<Card sx={{
							// minHeight:'200px',
							p:CardPadding,
						}}>
							<Typography variant="h6" gutterBottom>作者介绍</Typography>

							<Typography variant="body1">
								{Artist.introduction}
							</Typography>
					    </Card>
	    			</Grid>
    			</Grid>

    			<Grid container spacing={0} sx={{pt:2}}>
    				<Grid item xs={12} md={12}>
	    				<Card sx={{p:2}}>
						<Typography variant="h6" gutterBottom>藏品介绍</Typography>

							<CoverImage ref={ref}></CoverImage>
					    </Card>
	    			</Grid>
    			</Grid>
				<Global
					styles={{
					'.MuiDrawer-root > .MuiPaper-root': {
						height: `calc(88% - ${drawerBleeding}px)`,
						overflow: 'visible',
						overflowY:"scroll",
						maxHeight:'88%',
					},
					}}
				/>
				<SwipeableDrawer
										open={open}
										onClose={toggleDrawer(false)}
										onOpen={toggleDrawer(true)}
										anchor="bottom"
									>
										<Puller />
										<ReactPullLoad
										//  downEnough={10}
										//  ref="reactpullload" 
										 className="block"
										isBlockContainer={true}
 										action={pullload.action} 
										handleAction={handleAction}
										hasMore={pullload.hasMore}  
										distanceBottom={100}
										>
										{list()}
										</ReactPullLoad>

				</SwipeableDrawer>
    		</Container>
    	</Box>
    );
}