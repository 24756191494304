import * as React from 'react';
import Box from '@mui/material/Box';

import Appbar from '../components/Appbar';
import Title from '../components/Title';
import Noticelist from '../components/Noticelist';
import Navigation from '../components/Navigation';

export default function Annonce(){

    return (
        <Box>
            <Appbar />
            <Title value="公告中心" />
            <Noticelist/ >
            <Navigation />
        </Box>
    );
}