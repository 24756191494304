import * as React from 'react';

import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import Container from '@mui/material/Container';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Button from '@mui/material/Button';
import { Avatar,Stack } from '@mui/material';
import {isMobile} from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { apiPostList,apiPostLike } from '../common/Api';

import ShowEmpty from './ShowEmpty';

//icon
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';


function AuthorMsg(props) {
    return (
        <Stack direction="row" spacing={1}>
            <Avatar src={props.item.author.avatar} sx={{width:16,height:16}} />
            <Box sx={{height:'16px',lineHeight:'16px'}}>
                {props.item.author.nickname}
            </Box>
        </Stack>

    )
}

function ArtCircleNew(props) {
  const colsNum = isMobile?2:3;
  const [items,setItems] = React.useState([]);

  React.useEffect(()=>{
    apiPostList({classify:props.classify}).then(res=>{
			if (res.code == 200) {
                if (res.data != null){
                    for (var i = 0; i < res.data.length; i++) {
                        res.data[i].author.url = "/space/"+res.data[i].author.uid;
                        res.data[i].url = "/postDetail/"+res.data[i].uid;
                        const imgArr = JSON.parse(res.data[i].images);
                        res.data[i].cover = imgArr[0];
                    };
                    setItems(res.data);
                }
			}
		});
  },[]);

    const handleHref = (url) => {
		props.history.push(url);
	};

  let likepost = (postId,index)=>{
    // items[index].isFollowed=1
    // items[index].followerCount+=1
    //           console.log(1);
    //           setItems([]]);

    apiPostLike({postId:postId}).then(res=>{
        if (res.code == 200) {
          setItems(
            items.map((itemc,ic)=>{
             if (ic == index) {
                let temp = itemc.followerCount
                if(res.data=="like"){
                  temp +=1;
                  return { ...itemc, isFollowed:1,followerCount:temp};
                }else if(res.data=="unlike"){
                  temp -=1;
                  return { ...itemc, isFollowed:0,followerCount:temp};
                }   
             } else {
              return itemc;
             }
            })
           );           
        }
    });
  };
  return (
    <Container fixed sx={{paddingLeft:'12px',paddingRight:"12px"}}>
      {items.length == 0?
        <ShowEmpty/>
        :
      <ImageList sx={{ width: '100%',m:0,pt:1 }} cols={colsNum} variant="masonry" gap={6}>
        {items.map((item,index) => (
          <ImageListItem key={item.uid} sx={{width:'100%'}} >
            <img
              onClick={(e)=>handleHref(item.url)}
              src={`${item.cover}?w=248&fit=crop&auto=format`}
              srcSet={`${item.cover}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              
            />
            <ImageListItemBar
              title={<span style={{overflow:'visible',whiteSpace:'normal'}}>{item.title}</span>}
              subtitle={<Box sx={{width:'100%',display:'flex',justifyContent:'space-between',flexWrap:'nowrap'}}><Box sx={{marginTop:"5px",fontSize:"0.825rem",display:"flex",alignItems:"center",justifyContent:"flex-start"}}><Avatar sx={{width:"25px",height:"25px",marginRight:"6px"}} alt={item.author.nickname} src={item.author.avatar}  /><span >@{item.author.nickname}</span></Box>
              <Button sx={{padding:"0 5px",marginTop:"5px",justifyContent:"flex-end"}} onClick={(e)=>likepost(item.uid,index)} startIcon={ item.isFollowed==1?<FavoriteIcon sx={{color:'#c43131fc',marginRight:'-5px'}}/>:<FavoriteBorderIcon sx={{marginRight:'-5px'}} />} size="small" color="inherit">{item.followerCount}</Button>
              </Box>}
              position="below"
             
            />
          </ImageListItem>
        ))}
      </ImageList>
  }
    </Container>
  );
}

export default withRouter(ArtCircleNew);