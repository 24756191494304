import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
//import Link from '@mui/material/Link';
import { BrowserRouter as Router, Route, Link, Redirect} from "react-router-dom";
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';


import Divider from '@mui/material/Divider';


function Copyright(props) {
  return (
    <Typography variant="body2" color="primary.contrastText" align="center" {...props}>
      {' © '}{new Date().getFullYear()}{' '}MadMeta.Art{' '}版权所有{'.'}
    </Typography>
  );
}


const footers = [
  {
    title: '关注我们',
    description: ['微信公众号', '微博', '小红书', '邮箱'],
  },
  {
    title: '关于我们',
    description: ['关于MadMeta', '加入我们', 'APP下载'],
  },
  {
    title: '合作伙伴',
    description: ['小燕画院'],
  },
  {
    title: '帮助中心',
    description: [
      '常见问题',
    ],
  },
];

function PricingContent() {
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
      <Divider/>
      {/* Footer */}
      <Box sx={{display:{xs:'none',md:'flex'}}}>
        <Container
          maxWidth="md"
          component="footer"
          sx={{
            py: [3, 6],
            mt:5,
          }}
        >
          <Grid container spacing={4} justifyContent="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={3} key={footer.title}>
                <Typography variant="h6" color="primary.contrastText" gutterBottom>
                  {footer.title}
                </Typography>
                <ul>
                  {footer.description.map((item) => (
                    <li key={item}>
                      <Link to="/#" variant="subtitle1" color="text.main">
                        <Box sx={{color:'text.main',opacity:0.7}}>{item}</Box>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </Box>
      {/* End footer */}
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}