import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { apiUserGetAvatarList,apiUserEditAvatar } from '../common/Api.js';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { withRouter } from "react-router-dom";

const Item = styled(Box)({
});

let formData = new FormData();

function EditAvatar(props){

	const [User,setUser] = React.useState({});
	const [ImgData,setImgData] = React.useState("");
	const [SelectedId,setSelectedId] = React.useState("");
	const [ColCoverList,setColCoverList] = React.useState([]);
	const [DefaultOpen, setDefaultOpen] = React.useState(false);
	const [ArtistOpen, setArtistOpen] = React.useState(false);
	const [DefaultList,setDefaultList] = React.useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [Method,setMethod] = React.useState(0);
	const [UpdateStatus,setUpdateStatus] = React.useState(false);

	React.useEffect(()=>{
		let userInfoStr = window.localStorage.getItem("userInfo");
		let userInfo = JSON.parse(userInfoStr);
		setUser(userInfo);
		setImgData(userInfo.avatar);
		apiUserGetAvatarList({}).then(res=>{
			if (res.code == 200){
				setDefaultList(res.data.defaultList);
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
	},[]);

	const handleDefaultClick = () => {
		setDefaultOpen(!DefaultOpen);
	};

	const handleArtistClick = () => {
		setArtistOpen(!ArtistOpen);
	};

	const defaultClick = (id,avatar) => {
		setMethod(1);
		setUpdateStatus(true);
		setImgData(avatar);
		setSelectedId(id);
	}

    let upChange = (event) => {
		let upimgs = document.getElementById("upimg");
        let imgfile = event.currentTarget.files[0];
		formData.append("cover",imgfile);
        if (imgfile.size > 5096000) {
           	enqueueSnackbar('图片必须小于5M',{variant:"error"});
            upimgs.value='';
            return false;
        }
        else {
            let reader = new FileReader();
            reader.readAsDataURL(imgfile);

            reader.onload = function (event) {
                setImgData(this.result);
            };
            setMethod(3);
            setUpdateStatus(true);
            setSelectedId(0);
        }
    }

	let handleSubmit = () => {
        formData.append("method",Method);
		formData.append("id",SelectedId);
		apiUserEditAvatar(formData).then(res=>{
			if (res.code == 200){
				enqueueSnackbar("更新成功",{variant:"success"});
                let hrefToPersonal = setInterval(()=>{
                    clearInterval(hrefToPersonal);
                    props.history.push('/personal');
                },1000);
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		})
	}

    
    return (
    	<Box>
    		<Container fixed>
    			<Box sx={{textAlign:'center',py:4}}>
    				<Avatar src={ImgData} sx={{margin:'0 auto',width:100,height:100}}></Avatar>
    			</Box>
    			<Typography variant="body1" sx={{pb:1}}>
    				请选择头像
    			</Typography>
    			<Paper elevation={3} >
    				<Box sx={{}}>
    					<List
							component="nav"
						>
							<ListItemButton onClick={handleDefaultClick}>
								<ListItemText primary="从默认头像中选择" />
								{DefaultOpen ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse in={DefaultOpen} timeout="auto" unmountOnExit>
								<Grid container spacing={2} sx={{p:2,textAlign:'center'}}>
								{ DefaultList.map( (defaultItem, i) => 
									<Grid key={i} item xs={3}>
										<Avatar onClick={(e)=>defaultClick(defaultItem.id,defaultItem.avatar)} src={defaultItem.avatar} sx={{margin:'0 auto',height:50,width:50}}></Avatar>
									</Grid>
								)}
								</Grid>
							</Collapse>
						</List>
						<List
							component="nav"
						>
							<ListItemButton onClick={handleArtistClick}>
								<ListItemText primary="艺术家可上传图片作为头像" />
								{ArtistOpen ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse in={ArtistOpen} timeout="auto" unmountOnExit>
								<Box sx={{textAlign:'center',p:2}}>
								<Button variant={User.role==2?"contained":"disabled"} component="label">
									点击上传
									<input id="upimg" onChange={upChange} hidden accept="image/*" multiple type="file" />
								</Button>
								</Box>
							</Collapse>
						</List>
	    				
    				</Box>
    			</Paper>
    			
    			<Button
					type="text"
					fullWidth
					variant={UpdateStatus?"contained":"disabled"}
					sx={{ mt: 3, mb: 2 }}
					onClick={handleSubmit}
				>
                	确认修改
                </Button>
    		</Container>
    	</Box>
    );
}

export default withRouter(EditAvatar);