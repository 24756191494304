import * as React from 'react';
import Box from '@mui/material/Box';
import Post from '../components/Post';

export default function PostPage(){

    return (
    	<Box>
    		<Post/>
    	</Box>
    );
}