import * as React from 'react';
import Box from '@mui/material/Box';
import DraftsIcon from '@mui/icons-material/Drafts';
import { Typography } from '@mui/material';
import { styled } from '@material-ui/core/styles';


export default function ShowEmpty(props){

    const [desc,setDesc] = React.useState("幸运儿，你发现了一片艺术荒漠...");
    const [py,setPy] = React.useState("50px");
    const [iconSize,setIconSize] = React.useState("80px");
    
    React.useEffect(()=>{
		if (props.desc != null) {
            setDesc(props.desc);
        }
        if (props.py != null) {
            setPy(props.py+'px');
        }
        if (props.iconSize != null) {
            setIconSize(props.iconSize);
        }
	},[]);

    const BoxEmpty = styled(Box)({
        textAlign:'center',
        paddingTop:py,
        paddingBottom:py,
        opacity:0.33
    });

    return (
    	<BoxEmpty>
            <Box>
                <DraftsIcon sx={{ fontSize: iconSize }} />
            </Box>
            <Typography variant='h6'>
                {desc}
            </Typography>
    	</BoxEmpty>
    );
}