import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import { CssBaseline } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Avatar from '@mui/material/Avatar';
import Input from '@mui/material/Input';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {  Button } from '@mui/material';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';

import {apiUserEditCareer} from '../common/Api.js';
import { useHistory } from "react-router-dom";



let formData = new FormData();

export default function EditCareerAdd(){

    const [addStatus,setAddStatus] = React.useState(false);
    const [kind,setKind] = React.useState(0);
    const [kindDesc,setKindDesc] = React.useState(0);
    const [title,setTitle] = React.useState("");
    const [desc,setDesc] = React.useState("");
    const dateNow = new Date();
    const [startDate, setStartDate] = React.useState(dayjs(dateNow));
    const [endDate, setEndDate] = React.useState(dayjs(dateNow));
    const [cover,setCover] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [startTimestamp,setStartTimestamp] = React.useState(0);
    const [endTimestamp,setEndTimestamp] = React.useState(0);
    const [startDateDisplay,setStartDateDisplay] = React.useState("");
    const [endDateDisplay,setEndDateDisplay] = React.useState("");
    const [startDateUpload,setStartDateUpload] = React.useState("");
    const [endDateUpload,setEndDateUpload] = React.useState("");
    const [tillDate,setTillDate] = React.useState("");
    const history = useHistory();

    const titleChange = (e) =>{
		setTitle(e.target.value);
        checkAddStatus();
	}

    const descChange = (e) =>{
		setDesc(e.target.value);
        checkAddStatus();
	}

    const handlestartDate = (newValue) => {
        setStartDate(newValue);
        setStartTimestamp(newValue.$d.getTime());
        setStartDateDisplay(newValue.$y+'/'+(newValue.$M+1)+'/'+newValue.$D);
        setStartDateUpload(newValue.$y+'/'+(newValue.$M+1)+'/'+newValue.$D);
        checkTillDate(newValue.$d.getTime(),endTimestamp);
        checkAddStatus();
    };

    const handleEndDate = (newValue) => {
        setEndDate(newValue);
        let endDateTimestamp = newValue.$d.getTime();
        setEndTimestamp(endDateTimestamp);
        setEndDateUpload(newValue.$y+'/'+(newValue.$M+1)+'/'+newValue.$D);
        const dateNow = new Date();
        if (dateNow.getTime() < endDateTimestamp){
            setEndDateDisplay('至今');
            endDateTimestamp = dateNow.getTime();
        } else {
            setEndDateDisplay(newValue.$y+'/'+(newValue.$M+1)+'/'+newValue.$D);
        }
        checkTillDate(startTimestamp,endDateTimestamp);
        checkAddStatus();
    };

    const checkTillDate = (s,e) => {
        if (s != 0 && e != 0){
            const startDateC = new Date(s);
            const startYear = startDateC.getFullYear();
            const startMonth = startDateC.getMonth();
            const endDateC = new Date(e);
            const endYear = endDateC.getFullYear();
            const endMonth = endDateC.getMonth();
            let tillMonth = (endYear*12+endMonth)-(startYear*12+startMonth);
            const tillYear = (tillMonth - (tillMonth%12)) / 12;
            if (tillMonth == 0){
                tillMonth = tillMonth+1;
            }
            if (tillMonth > 12){
                setTillDate(tillYear+'年');
            } else if (tillMonth < 12){
                setTillDate(tillMonth+'月')
            }
        }
        checkAddStatus();
    }

    const handleKindChange = (event) => {
        setKind(event.target.value);
        if (event.target.value == 1){
            setKindDesc("教育背景");
        } else if (event.target.value == 2) {
            setKindDesc("实践经历");
        }
        checkAddStatus();
    };

    let upChange = (event) => {
		let upimgs = document.getElementById("upimg");
        let imgfile = event.currentTarget.files[0];
		formData.append("cover",imgfile);
        if (imgfile.size > 5096000) {
           	enqueueSnackbar('图片必须小于5M',{variant:"error"});
            upimgs.value='';
            return false;
        }
        else {
            let reader = new FileReader();
            reader.readAsDataURL(imgfile);

            reader.onload = function (event) {
                setCover(this.result);
            };
        }
        checkAddStatus();
    }

    const checkAddStatus = () => {
        if(kind!=0&&title!=""&&desc!=""&&startTimestamp!=0&&endTimestamp!=0&&cover!=""){
            setAddStatus(true);
        }
    }

    let handleEdit = () => {
        formData.append("title",title);
		formData.append("desc",desc);
		formData.append("kind",kind);
        formData.append("startDate",startDateUpload)
        formData.append("endDate",endDateUpload)
        formData.append("method","insert")
        apiUserEditCareer(formData).then(res=>{
			if (res.code == 200) {
				enqueueSnackbar("上传成功",{variant:"success"});
                let hrefToCareer = setInterval(()=>{
                    clearInterval(hrefToCareer);
                    history.push('/personalCareer');
                },1000);
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
    }

    

    

    return (
    	<Box>
            <CssBaseline/>
            <Container fixed>
                <Box sx={{pt:3,pb:2}}>
                    <Typography variant="h5">添加新的艺术履历</Typography>
                    
                </Box>
                <Divider  />
                <Box sx={{py:3}}>
                        <FormLabel id="demo-row-radio-buttons-group-label">类别</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={kind}
                            onChange={handleKindChange}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="教育背景" />
                            <FormControlLabel value="2" control={<Radio />} label="实践经历" />
                        </RadioGroup>
                        <FormLabel id="demo-row-radio-buttons-group-label">封面</FormLabel>
                        <Box sx={{p:2}}>
                            {cover == ""?
                            <Button variant='outlined' component="label">
                                点击上传
                                <input hidden id="upimg" accept="image/*" multiple onChange={upChange} type="file" />
							</Button>
                            :
                            <Avatar src={cover}></Avatar>
                            }
                            
                        </Box>
                        
                        <FormLabel id="titleLabel">标题</FormLabel>
                        <Input 
                            placeholder="请输入标题"
                            value={title}
                            onChange={titleChange}
                            sx={{width:'100%',
                                fontSize:'20px!important',
                                mb:2
                            }}
                        />
                        <FormLabel id="descLabel">描述</FormLabel>
                        <Input placeholder="请为您的经历添加少于200字的描述"  
                            multiline
                            rows={3}
                            value={desc}
                            onChange={descChange}
                            sx={{
                                width:'100%',
                                fontSize:'16px!important',
                                padding:'10px 0',
                                mb:1
                            }}
                        />
                        
                        <FormLabel id="demo-row-radio-buttons-group-label">起止日期</FormLabel>
                        <Box sx={{pt:2}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack direction="row" spacing={2} >
                                <MobileDatePicker
                                    label="选择开始时间"
                                    inputFormat="YYYY/MM/DD"
                                    value={startDate}
                                    onChange={handlestartDate}
                                    renderInput={(params) => <TextField {...params} />}
                                    />
                                <MobileDatePicker
                                    label="选择结束时间"
                                    inputFormat="YYYY/MM/DD"
                                    value={endDate}
                                    onChange={handleEndDate}
                                    renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Box>
                </Box>
                <Box sx={{mb:10}}>
                    <Typography variant="h6">浏览</Typography>
                    <Divider sx={{pt:1}} />
                    <List>
                        <Typography  sx={{ display: 'block',fontWeight:700,fontSize:'18px',paddingTop:'10px',paddingBottom:'10px' }}>
                            {kindDesc == ""?'类别':kindDesc}
                        </Typography>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                            <Avatar alt="MM" src={cover} />
                            </ListItemAvatar>
                            <ListItemText
                            primary={title == ""?'标题':title} 
                            secondary={
                                <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {desc == ""?'描述':desc} 
                                </Typography>
                                <Typography
                                    sx={{ display: 'block' }}
                                    component="span"
                                    variant="body2"
                                    fontSize='12px'
                                    color='#afa9a9'
                                    paddingTop='5px'
                                >   
                                    {startDateDisplay == ""?<span> 开始日期 </span>:<span> {startDateDisplay} </span>}
                                     - 
                                    {endDateDisplay == ""?<span> 结束日期 </span>:<span> {endDateDisplay} </span>}
                                      · 
                                    {tillDate == ""?<span> 持续时长</span>:<span> {tillDate} </span>}                               
                                </Typography>
                                </React.Fragment>
                            }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" sx={{marginLeft:'70px'}} />
                    </List>
                </Box>

                <AppBar position="fixed" sx={{ top: 'auto', bottom: 0,height:'60px',justifyContent:'center' }} color="background">
                    <Toolbar sx={{px:0.5}}>
                        <Button size="large" 
                            color="primary"
                            variant={addStatus == false?"disabled":"contained"}
                            sx={{width:'100%',fontSize:'20px'}}
                            onClick={handleEdit}
                        >
                            {addStatus == false?"尚有未填写的资料":"确认上传"}
                        </Button>
                    </Toolbar>
                </AppBar>
            </Container>
    	</Box>
    );
}