import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import Fab from '@mui/material/Fab';

import { red, blueGrey, orange } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import Artisttab from './Artisttab';

export default function ArtistsDetail(){

    return (

		<Box sx={{ maxWidth: '100%',marginTop:'50px'}}>
			
			
			<Container fixed>
			<Grid container spacing={2}>
                <Grid xs={1} md={1}></Grid>
                <Grid item xxs={12} xs={5} md={5} sx={{padding:'30px 30px',textAlign:'center'}}>
                            <CardMedia
                            component="img"
                            width='80%'
                            height="auto"
                            image="ape1.png"
                            alt="3D APE"  
                            sx={{borderRadius:'20px',}}  
                            />
                </Grid>
           
                <Grid item xxs={12} xs={5} md={5}>
                    <Box sx={{
                        textAlign:'center',
                        fontSize:'1.5em',
                        fontWeight:'bold',
                        marginBottom: '20px',
                        // position:'relative'
                    }}>
                        <Box sx={{
                            textAlign: 'left',
                            // color: #FFFFFF,
                            fontSize: '54px',
                            fontWeight: '700',
                            lineHeight: '1.3em',
                            letterSpacing: '0px',
                        }}>
                            The 3d cartoon APE <sup>Ⓡ</sup>
                            </Box>
                       
                        
                    </Box>
                    <Box sx={{
                            // position:'relative',
                            width:'100%',
                            marginBottom:1,}}>	
                            <Stack direction="row" spacing={2}>
                            <Avatar src="../avatar.jpeg" sx={{
                                // position:'absolute',
                                // left:0,
                                // right:0,
                                // top:0,
                                // bottom:0,
                                // margin:'auto',
                                // float:'left',                           
                                width:60,
                                height:60,
                            }}/ >
                                <Box>
                                    <Box sx={{color: '#636977',fontSize: '16px',fontWeight: '700',
        letterSpacing: '0px',margin:'0px 0px 7px 0px',}}>
                                        艺术家
                                    </Box>
                                    <Box sx={{color: '#000',fontSize: '20px',fontWeight: '500',letterSpacing: '0px',}}>
                                        Paul Rojers
                                    </Box>
                                </Box>
                                <Box sx={{
                            // position:'absolute',
                            // right:150,
                            // top:100,
                            opacity:0.8,
                            padding:'10px 10px',
                            marginLeft:'30px!important',
                        }}>
                            <Button variant="outlined" color="error" size="small" endIcon={<FavoriteIcon />} disabled>
                                已关注
                            </Button>
                        </Box>                
                        </Stack>
                        </Box>
                    <Stack direction="row" spacing={1} sx={{pt:1,margin:'0 auto',textAlign:'center'}}>
                        <Chip label="小燕画院" icon={<DoneIcon />} variant="outlined" size="small" />
                        <Chip label="MadMeta" icon={<DoneIcon />} variant="outlined" size="small" />
                        <Chip label="插画" icon={<DoneIcon />} variant="outlined" size="small" />
                    </Stack>

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                            m: 1,
                            width: '90%',
                            // minHeight: 128,
                            // backgroundColor:'rgb(247 249 251)',
                            backgroundColor:'#fafafa',
                            borderRadius:'18px',
                            marginTop:'20px',
                            padding:'20px 40px 20px 40px',
                            },
                        }}
                        >
                        {/* <Paper elevation={0} />
                        <Paper /> */}
                        <Paper elevation={1} sx={{textAlign:'center'}}>
                            <Box sx={{margin:'0px 0px 10px 0px',textAlign: 'center',
                            color: '#000000',
                            fontSize: '22px',
                            fontWeight: 500,
                            lineHeight: '1.3em',
                            letterSpacing: '0px',
                        }}>发售价格</Box>
                            <Box sx={{
                                margin:'0px 0px 10px 0px',
                                textAlign: 'center',
                                color: '#7A8293',
                                fontSize: '22px',
                                fontFamily:'"Raleway", Sans-serif',
                                fontWeight: 700,
                                lineHeight: '1.3em',
                                letterSpacing: '0px'
                            }}>¥99.99</Box>
                            <Fab variant="extended" color="primary" aria-label="add"
                            sx={{backgroundColor:'#3772FF',fontSize: '20px',
                            fontWeight: '700',
                            letterSpacing: '0.5px',
                            fill: '#FFFFFF',
                            color: '#FFFFFF',
                            padding:'17px 50px 17px 50px',
                            width:'90%',    
                            }}
                            >
                                立即购买
                            </Fab>
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={1} md={1}></Grid>
            </Grid>
				

				

				<Artisttab/ >

			</Container>
	    </Box>
    );
}