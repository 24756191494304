import * as React from 'react';
import Box from '@mui/material/Box';

import{apiArtistAbout} from '../common/Api';

export default function SpaceAbout(props){

    const ref = React.useRef();

    React.useEffect(()=>{
		apiArtistAbout({uid:props.uid}).then(res=>{
			if (res.code == 200) {
                const node = document.createRange().createContextualFragment(res.data.about);
    			ref.current.appendChild(node);
			}
		})
	},[]);

    return (
    	<Box>
            <div ref={ref}></div>
    	</Box>
    );
}