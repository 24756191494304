import * as React from 'react';

import { Box } from '@mui/material';
import Signin from '../components/Signin';
import AppbarCrumbsHome from '../components/AppbarCrumbsHome';

export default function Basic(){

    return (
        <Box>
            <AppbarCrumbsHome/>
            <Signin/ >
        </Box>
    );
}