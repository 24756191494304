import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from '@mui/styles';

import {useToggleTheme} from "../Theme";

const useStyles = makeStyles((theme) => ({
  
	logoClass:{
		width:'150px',
		height:'35px',
		marginLeft:'auto'
	}

}));

export default function AppbarCrumbs(){
	const classes = useStyles();

	const themeConf = useToggleTheme();

    return (
    	<Box sx={{ flexGrow: 1 }}>
			<AppBar position="sticky" color="background">
				<Toolbar>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						onClick={ ()=>window.history.back(-1)}
						sx={{ mr: 2 }}
					>
						<ArrowBackIosIcon />
					</IconButton>
						<Typography
							variant="h5"
							noWrap
							component="a"
							href="/"
							sx={{
								mr: 1,
								width:'100%',
								display: { xs: 'flex', md: 'none' },
								flexGrow: 2,
							}}
						>	
								<img src={themeConf.logoAddress} className={classes.logoClass} />
						</Typography>
				</Toolbar>
			</AppBar>
		</Box>

    );
}