import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import PeanutTask from './PeanutTask';
import PeanutRecord from './PeanutRecord';
import { apiPeanutSum,apiPeanutCheck } from '../common/Api';


const PeanutBg = styled(Box)({
    backgroundImage:'url(banner3.jpg)',
    backgroundSize:'cover',
    maxHeight:'180px',
    lineHeight:'180px',
    borderRadius:'10px',
    textAlign:'center',
    marginTop:'10px'
});




export default function Peanut(){

    const [open, setOpen] = React.useState(true);
    const [value, setValue] = React.useState('1');
    const [peanut, setPeanut] = React.useState(0);
    const [dailySignin, setDailySignin] = React.useState(1);
    const [dailyPost, setDailyPost] = React.useState(1);
    const handleClick = () => {
        setOpen(!open);
    };

    const handleChange = (event, newValue) => {
		setValue(newValue);
	};
    React.useEffect(()=>{
        apiPeanutCheck({}).then(res=>{
            if (res.code == 200) {
                if(res.data["daily_signin"]&&(res.data["daily_signin"].count>=res.data["daily_signin"].day_limit)){
                    setDailySignin(0)
                }        
                if(res.data["daily_post"]&&(res.data["daily_post"].count>=res.data["daily_post"].day_limit)){
                    setDailyPost(0)
                }
            }
        })
        apiPeanutSum({}).then(res=>{
            if (res.code == 200) {
                setPeanut(res.data);
            }
        })
    },[])
   
    const getChildrenDailyCheck = (value,peanutNum) => {
        setDailySignin(value)
        setPeanut(peanut+peanutNum)
      }

    return (
    	<Box>
            <Container fixed>
                <PeanutBg>
                    <Typography variant='h4' sx={{display:'inline'}}>
                        您拥有：
                    </Typography>
                    <Typography variant='h4' sx={{display:'inline'}}>
                        {peanut}
                    </Typography>
                    <Typography variant='h6' sx={{display:'inline', pl:2}}>
                        <svg className="icon" aria-hidden="true">
                            <use xlinkHref="#icon-huasheng-hei"></use>
                        </svg>
                    </Typography>
                    

                </PeanutBg>

                

                <TabContext value={value}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                        <Tab label="获取花生" value="1" />
                        <Tab label="记录" value="2" />
                    </TabList>
                    <TabPanel value="1" sx={{p:0}}><PeanutTask getDailySignin={getChildrenDailyCheck} dailyPost={dailyPost} dailySignin={dailySignin}/></TabPanel>
                    <TabPanel value="2" sx={{p:0}}><PeanutRecord/></TabPanel>
                    
                    
                </TabContext>

            </Container>
    	</Box>
    );
}