import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { apiUserEditNickname } from '../common/Api.js';

import { useSnackbar } from 'notistack';

export default function EditNickname(){

	const [Nickname,setNickname] = React.useState("");
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(()=>{
		let userInfoStr = window.localStorage.getItem("userInfo");
		let userInfo = JSON.parse(userInfoStr);
		setNickname(userInfo.nickname);
	},[]);

	const nicknameChange = (e) =>{
		setNickname(e.target.value);
	}

	const handleClick = () =>{
		apiUserEditNickname({nickname:Nickname}).then(res=>{
			if (res.code == 200) {
				enqueueSnackbar("更新成功",{variant:"success"});
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
	}

    return (
    	<Box>
    		<Container fixed>
    			<TextField
					id="outlined-multiline-static"
					label="修改昵称"
					fullWidth
					multiline					
					onChange={nicknameChange}
					defaultValue={Nickname}
					autoFocus
					helperText="请输入不大于8位的中文/英文/数字作为您的昵称"
					sx={{
						mt:4
					}}
				/>
				<Button
					type="text"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
					onClick={handleClick}
				>
                	确认修改
                </Button>
    		</Container>
    	</Box>
    );
}