import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { NavLink} from "react-router-dom";

import { useSnackbar } from 'notistack';


export default function AppbarMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const [isLogin,setIsLogin] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userInfo');
    enqueueSnackbar("登出成功",{variant:"success"});
    setIsLogin(false);
  };

  React.useEffect(()=>{
		const tokenStr = window.localStorage.getItem("token");
    if (tokenStr != null) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
	},[]);


  return (
    <React.Fragment>
      <Box sx={{
        flexGrow: 0,
        display:{xs:'flex',md:'none'},
        float:'right',
      }}>
        <Tooltip title="Mint">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MenuIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      > 
        <MenuItem sx={{mx:1}} component={NavLink} to="/annonce">
          <ListItemIcon>
            <VolumeUpIcon fontSize="small" />
          </ListItemIcon>
          公告中心
        </MenuItem>
        <MenuItem sx={{mx:1}} component={NavLink} to="/message">
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          站内信
        </MenuItem>
        <MenuItem sx={{mx:1}} component={NavLink} to="/settings">
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          偏好设置
        </MenuItem>
        { isLogin == true ?
          <Box>
            <MenuItem sx={{mx:1}} onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              退出登录
            </MenuItem>
          </Box>
          :
          <Box>
            <MenuItem sx={{mx:1}} component={NavLink} to="/signin">
              <ListItemIcon>
                <LoginIcon fontSize="small" />
              </ListItemIcon>
              登录
            </MenuItem>
          </Box>
        }
        
      </Menu>
    </React.Fragment>
  );
}
