import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';

import AppbarMenu from '../components/AppbarMenu';


import {useHistory,Link} from "react-router-dom";

import {useToggleTheme} from "../Theme";



export default function App() {

  const themeConf = useToggleTheme();

  const history = useHistory();

  const handleHref = (url) => {
    history.push(url);
  };

  // const pages = ['主页','藏品', '艺术家', '抽签', '市场'];
  const pages = [
        {name:'主页',links:'/'},
        {name:'藏品',links:'/collections'},
        {name:'艺术家',links:'/artCircle'}, 
        {name:'抽签',links:'/'}, 
        {name:'市场',links:'/market'}];
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  const Search = styled('span')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('span')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));


  return (
    <AppBar position="sticky" color="background">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'text',
              textDecoration: 'none',
            }}
          >
            <img src={themeConf.logoAddress} width="150" height="35" />
          </Typography>
          

          
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={themeConf.logoAddress} width="150" height="35" />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page,index) => (
              <Link to={page.links} key={index}>
                <Button
                  key={index}
                  // onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'text', display: 'block' }}
                  color="text"
                >
                   {page.name}
                 
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, mr: 2 }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="藏品、艺术家"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box>
          

          <Box onClick={(e)=>handleHref('/search')} sx={{ flexGrow: 0 ,display: { xs: 'flex', md: 'none' }}}>
            <SearchIcon/>
          </Box>

          <Box sx={{ flexGrow: 0 ,display: { xs: 'flex', md: 'flex' }}}>
            <AppbarMenu/>
          </Box>

          <Box onClick={(e)=>handleHref('/personal')} sx={{ flexGrow: 0 ,display: { xs: 'none', md: 'flex' }}}>
              <IconButton  sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="avatar.jpeg" />
              </IconButton>
          </Box>

        </Toolbar>
        
      </Container>
      
      
    </AppBar>
  );
};
