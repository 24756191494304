import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Router from './Router';
import NotistackWrapper from './NotistackWrapper';

//theme
import {CustomThemeProvider} from './Theme';

import {isMobile} from 'react-device-detect';

import MobileOnly from './pages/MobileOnly';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  	<CustomThemeProvider>
        <NotistackWrapper>
            {isMobile?
            <Router />
            :
            <MobileOnly/>
            }
            
        </NotistackWrapper>
    </CustomThemeProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
