import * as React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {  Button } from '@mui/material';

import {apiUserCareerList} from '../common/Api.js';
import {useHistory} from "react-router-dom";
import { styled } from '@mui/material/styles';
import ShowEmpty from './ShowEmpty.js';


const CareerStatus = styled('span')(() => ({
    position:'absolute',
    right:'24px',
    top:'14px'
}));


export default function SpaceCareer(props){

    const history = useHistory();
    const [listEdu,setListEdu] = React.useState([]);
    const [listExp,setListExp] = React.useState([]);
    const [list,setList] = React.useState([]);

    React.useEffect(()=>{

        apiUserCareerList({uid:props.uid}).then(res=>{
            if (res.code == 200) {
                if (res.data != null){
                    setList(res.data);
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].status == 0){
                            res.data[i].statusDisplay = '待审核'
                        } else if (res.data[i].status == 1){
                            res.data[i].statusDisplay = '已认证'
                        } else if (res.data[i].status == 2){
                            res.data[i].statusDisplay = '未通过'
                        } 
                        res.data[i].dateDisplay = handleDateDisplay(res.data[i].startDate, res.data[i].endDate)
                        if (res.data[i].kind == 1){
                            setListEdu(listEdu => [...listEdu, res.data[i]]);
                        } else if (res.data[i].kind == 2){
                            setListExp(listExp => [...listExp, res.data[i]]);
                        }
                        
                    }
                }
            }
        });
	},[]);

    const handleDateDisplay = (s,e) => {

        const dateNow = new Date();

        const startDate = new Date(s);
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth();
        const startDateDisplay = startDate.getFullYear()+"/"+(startDate.getMonth()+1)+"/"+startDate.getDay();

        let endDate = new Date(e);
        let endDateTimestamp = endDate.getTime();
        let endDateDisplay = endDate.getFullYear()+"/"+(endDate.getMonth()+1)+"/"+endDate.getDay();
        if (endDateTimestamp > dateNow.getTime()){
            endDateDisplay = "至今"
            endDate = new Date();
        }
        const endYear = endDate.getFullYear();
        const endMonth = endDate.getMonth();
       
        
        let tillMonth = (endYear*12+endMonth)-(startYear*12+startMonth);
        let tillDisplay = "";
        const tillYear = (tillMonth - (tillMonth%12)) / 12;
        if (tillMonth == 0){
            tillMonth = tillMonth+1;
        }
        if (tillMonth > 12){
            tillDisplay = tillYear+'年';
        } else if (tillMonth < 12){
            tillDisplay = tillMonth+'月';
        }

        return startDateDisplay + " - " + endDateDisplay + " · " + tillDisplay

    }

    return (
    	<Box>
            {list.length == 0?
            <ShowEmpty/>
            :
            <List sx={{ width:{xs:'100%',md:'70%'}, padding:{xs:'0',md:'0 20px'},  bgcolor: 'background.paper' }}>
                <Typography  sx={{ display: 'block',fontWeight:700,fontSize:'18px',paddingTop:'10px',paddingBottom:'10px' }}>
                    教育背景
                </Typography>
                {listEdu.map((item,i) => (
                    <Box  key={i}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={item.cover} />
                            </ListItemAvatar>
                            <ListItemText
                            primary={item.title}
                            secondary={
                                <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {item.description}
                                    <CareerStatus>{item.statusDisplay}</CareerStatus>
                                </Typography>
                                <Typography
                                    sx={{ display: 'block' }}
                                    component="span"
                                    variant="body2"
                                    fontSize='12px'
                                    color='#afa9a9'
                                    paddingTop='5px'
                                >
                                    {item.dateDisplay}
                                    {toggleStatus?
                                    <ToogleBar>
                                        <EditIcon sx={{mx:1}} />
                                        <DeleteIcon />
                                    </ToogleBar>
                                    :<ToogleBar></ToogleBar>}
                                </Typography>
                                
                                </React.Fragment>
                            }
                            />
                        </ListItem>
                        <Divider sx={{marginLeft:'70px'}}/>
                    </Box>
                    ))}
                <Typography  sx={{ display: 'block',fontWeight:700,fontSize:'18px',paddingTop:'15px',paddingBottom:'10px' }}>
                    实践经历
                </Typography>
                {listExp.map((item,i) => (
                <Box  key={i}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={item.cover} />
                        </ListItemAvatar>
                        <ListItemText
                        primary={item.title}
                        secondary={
                            <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {item.description}
                                <CareerStatus>{item.statusDisplay}</CareerStatus>
                            </Typography>
                            <Typography
                                sx={{ display: 'block' }}
                                component="span"
                                variant="body2"
                                fontSize='12px'
                                color='#afa9a9'
                                paddingTop='5px'
                            >
                                {item.dateDisplay}
                                {toggleStatus?
                                <ToogleBar>
                                    <EditIcon sx={{mx:1}} />
                                    <DeleteIcon />
                                </ToogleBar>
                                :<ToogleBar></ToogleBar>}
                            </Typography>
                            
                            </React.Fragment>
                        }
                        />
                    </ListItem>
                    <Divider sx={{marginLeft:'70px'}}/>
                </Box>
                ))}
              

            </List>
            }

    	</Box>
    );
}