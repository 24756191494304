import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@material-ui/core/ImageList';
import Typography from '@mui/material/Typography';

//icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
        height:'130px',
	},
    sendRoot: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
        height:'160px',
	},
	gridList: {
		flexWrap: 'nowrap'
	},
	itemImg:{
		borderRadius:'10px'
	}
}));

const colItems = [
    {
        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        title: 'Breakfast',
    },
    {
        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        title: 'Burger',
    },
    {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
    },
    {
        img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
        title: 'Coffee',
    },
    {
        img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
        title: 'Hats',
    },
];

const sendItems = [
    {
        img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
        title: 'Fern',
        send: '赠与:Zed',
    },
    {
        img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        title: 'Mushrooms',
        send: '收到:Peter',
    },
    {
        img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
        title: 'Tomato basil',
        send: '赠与:Anna',
    },
    {
        img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
        title: 'Sea star',
        send: '收到:Krystal',
    },
    {
        img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        title: 'Bike',
        send: '赠与:TheFatRat',
    },
];

export default function PersonalIndex(){

    const classes = useStyles();

    return (
    	<Box sx={{mx:1}}>
            <Box>
                <Box sx={{py:1}}>
                    <Typography variant="body" sx={{fontWeight:'bold'}}>
                        我的藏品<ArrowForwardIosIcon sx={{pt:1.5}}/>
                    </Typography>
                </Box>
                <Box className={classes.root}>
                    <ImageList className={classes.gridList} cols={10}>
                        { colItems.map( (item, i) => 
                            <Box key={i} sx={{minWidth: '100px',minHeight:'130px',mr:1,position:'relative'}}>
                                <img src={item.img} className={classes.itemImg} width="100" height="100" />
                                <Typography variant="subtitle2">
                                    {item.title}
                                </Typography>
                            </Box>

                        )}
                    </ImageList>
                </Box>
            </Box>
            <Box>
                <Box sx={{py:1}}>
                    <Typography variant="body" sx={{fontWeight:'bold'}}>
                        转增<ArrowForwardIosIcon sx={{pt:1.5}}/>
                    </Typography>
                </Box>
                <Box className={classes.sendRoot}>
                    <ImageList className={classes.gridList} cols={10}>
                        { sendItems.map( (item, i) => 
                            <Box key={i} sx={{minWidth: '100px',minHeight:'160px',mr:1,position:'relative'}}>
                                <img src={item.img} className={classes.itemImg} width="100" height="100" />
                                <Typography variant="subtitle2">
                                    {item.title}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {item.send}
                                </Typography>
                            </Box>

                        )}
                    </ImageList>
                </Box>
            </Box>
    	</Box>
    );
}