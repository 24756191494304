import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {useHistory} from "react-router-dom";
import { apiPeanutAdd } from '../common/Api';

export default function Basic(props){
    const history = useHistory();
    
    const handleHref = (url) => {
        history.push(url);
    };
    
    const handleHrefOut = (url) => {
        window.location.href = url;
    }

    const recordPeanut=(type)=>{

        apiPeanutAdd({typeId:type}).then(res=>{
            if (res.code == 200) {
                toparent(res.data.leftCount,res.data.peanut)
            }
        })
    }

    const toparent = (status,peanutNum) => {
        // 向父组件传递每次递增的value值
          props.getDailySignin(status,peanutNum)
        }
    return (
    	<Box>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding:0}}
                component="nav"
                aria-labelledby="nested-list-subheader"
                >
                <ListItemButton>
                    <ListItemIcon>
                    <AssignmentTurnedInIcon />
                    </ListItemIcon>
                    <ListItemText primary="每日签到" secondary="签到可获得1花生" />
                    {props.dailySignin>=1?
                    <Button variant='contained' onClick={(e)=>recordPeanut(1)}>签到</Button>
                    :<Button variant='contained' sx={{backgroundColor:"#c0bcb7!important"}}>已签到</Button>
                    }
                </ListItemButton>
                <ListItemButton>
                    <ListItemIcon sx={{fontSize:'12px'}}>
                    <svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-a-yaoqinghaoyou-hei2"></use>
                    </svg>
                    </ListItemIcon>
                    <ListItemText primary="邀请好友" secondary="每邀请一个好友可获得5花生" />
                    <Button onClick={(e)=>handleHrefOut('http://120.25.224.86:8004')}>前往</Button>
                </ListItemButton>
                <ListItemButton>
                    <ListItemIcon>
                    <PostAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="每日动态" secondary="每天首次发动态可获得2花生" />
                    {props.dailyPost==1?
                    <Button onClick={(e)=>handleHref('post')}>前往</Button>
                    :<Button onClick={(e)=>handleHref('post')}>已完成</Button>}
                </ListItemButton>
            </List>
    	</Box>
    );
}