import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Title from './Title';
import Typography from '@mui/material/Typography';
import VerifiedIcon from '@mui/icons-material/Verified';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import {apiCollectionSeries} from '../common/Api.js';


const useStyles = makeStyles((theme) => ({
    flexBoxRowPd:{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0px 1rem 1.2rem 1rem',
    },
    justFlexRow:{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    flexBoxColumn:{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    imgItem:{
      objectFit: 'cover',borderRadius:'10px',width:'4.2rem',height:"4.2rem",
    },
    textOverFlowHide:{
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }
}));

export default function CollectionSeriesList(){
    const [value, setValue] = React.useState('1');
    const [colItems, setColItems] = React.useState([]);
	  const classes = useStyles();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    React.useEffect(()=>{
      apiCollectionSeries().then(res=>{
        console.log(res)
        if (res.code == 200) {
          setColItems(res.data)
          // for (var i = 0; i < res.data.length; i++) {           
          // };
        }
      });
    },[]);

    return (
    	<Container>
    		<Box className={classes.flexBoxRowPd}>
          <Typography variant="body2">作品集</Typography>
          <Typography variant="body2">人气</Typography>
        </Box>
       
        {colItems.map((colItem, i) => 
        <Box key={i} className={classes.justFlexRow} sx={{padding: '0px 1rem 1.6rem 1rem',}}>
          <Box sx={{display: 'flex',flexDirection: 'row', flexGrow: '7.5',flexBasis: '75%',overflow: 'hidden',}}>
          <img
                  className={classes.imgItem}
                  src={colItem.Cover}/>
              <Box className={classes.flexBoxColumn} sx={{paddingLeft:'0.5rem',flexGrow: 1,width: '60%',}}>
                <Typography variant="subtitle1">{colItem.CollectionsName}</Typography>

                <Box sx={{display: 'flex',alignItems: 'center'}}>
                  <Typography variant="caption" sx={{paddingRight:'2px',wordBreak:'keep-all'}}>发行:</Typography>
                  <Box className={classes.textOverFlowHide} fontSize="0.875rem">{colItem.Artist.nickname}</Box>
                  {colItem.Artist.level==3?<VerifiedIcon sx={{marginLeft:'4px',color:"#f8d90f",}} fontSize='body2' />:<></>}
                  {colItem.Artist.level==2?<VerifiedIcon sx={{marginLeft:'4px',color:"#1976d2",}} fontSize='body2' />:<></>}
                  
                </Box>
                <Box sx={{display: 'flex',alignItems: 'flex-end',}}>
                  <Typography variant="caption"  sx={{paddingRight:'2px',}}>价格: </Typography>
                  <Typography sx={{fontSize:'0.925rem'}}>¥{colItem.Price}</Typography>
                </Box>
              </Box>    
           </Box>
    
            <Box sx={{display: 'flex',flexDirection: 'column',alignItems:'flex-end',flexGrow: '2.5',flexBasis: '25%'}}>
                <Typography sx={{fontSize:'0.925rem',fontWeight:'700',display: 'flex',alignItems: 'center',}}>{colItem.heat}<LocalFireDepartmentIcon sx={{marginLeft:'4px',color:"#d32f2f",fontSize:'1.15rem'}} /></Typography>
               
               {colItem.daily_growth!=0?
                <Typography  sx={{color:'#34c77b',fontSize:'0.925rem',fontWeight:'900',display: 'flex',alignItems: 'center',}} ><ArrowDropUpIcon sx={{marginLeft:'4px',fontSize:'1.15rem'}}/>
                {colItem.daily_growth}
                </Typography>
                :<Typography  sx={{fontSize:'0.925rem',fontWeight:'900'}}>—</Typography>}
            </Box>

            
        </Box>
        )}

        {/* <Box className={classes.justFlexRow} sx={{padding: '0px 1rem 1.6rem 1rem',}}>
          <Box sx={{display: 'flex',flexDirection: 'row', flexGrow: '7.5',flexBasis: '75%',overflow: 'hidden',}}>
              <img
                  className={classes.imgItem}
                  src="https://madmetademo.oss-cn-hangzhou.aliyuncs.com/collections/5d20b999a77f913aece3ce04a5569e69.png"/>
              <Box className={classes.flexBoxColumn} sx={{paddingLeft:'0.5rem',flexGrow: 1,width: '60%',}}>
                <Typography className={classes.textOverFlowHide} variant="subtitle1">黑城</Typography>

                <Box sx={{display: 'flex',alignItems: 'center'}}>
                  <Typography variant="caption" sx={{paddingRight:'2px',wordBreak:'keep-all'}}>发行:</Typography>
                  <Box className={classes.textOverFlowHide} fontSize="0.875rem">金城武本人</Box>
                  <VerifiedIcon sx={{marginLeft:'4px',color:"#1976d2",}} fontSize='body2' />
                </Box>
                <Box sx={{display: 'flex',alignItems: 'flex-end',}}>
                  <Typography variant="caption"  sx={{paddingRight:'2px',}}>价格: </Typography>
                  <Typography sx={{fontSize:'0.925rem'}}>¥9.90</Typography>
                </Box>
              </Box>    
           </Box>
           <Box sx={{display: 'flex',flexDirection: 'column',alignItems:'flex-end',flexGrow: '2.5',flexBasis: '25%'}}>
                <Typography sx={{fontSize:'0.925rem',fontWeight:'700',display: 'flex',alignItems: 'center',}}>107<LocalFireDepartmentIcon sx={{marginLeft:'4px',color:"#d32f2f",fontSize:'1.15rem'}} /></Typography>
                <Typography  sx={{fontSize:'0.925rem',fontWeight:'900'}}>—</Typography>
            </Box>
        </Box> */}


    	</Container>
    );
}