import * as React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSnackbar } from 'notistack';


import {apiNoticeList} from '../common/Api.js';

export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [items,setItems] = React.useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(()=>{
    apiNoticeList({}).then(res=>{
      if (res.code == 200) {
        for (var i = 0; i < res.data.length; i++) {
          let date = new Date(res.data[i].createTime);
          res.data[i].showTime = (date.getMonth()+1)+"-"+date.getDate();
        };
        setItems(res.data);
      } else {
        enqueueSnackbar(res.msg,{variant:"error"});
      }
    });
  },[]);

  return (
    <Box sx={{p:2}}>
      { items.map( (item, i) => 
        <Accordion key={i} expanded={expanded === i} onChange={handleChange(i)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id={i}
          >
            <Typography sx={{ width: '20%', flexShrink: 0 }}>
              {item.showTime}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {item.content}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}
