import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@material-ui/core/styles';


const BoxBg = styled(Box)({
    backgroundImage:'url(bgbubble4.png)',
    backgroundSize:'cover'
});

export default BoxBg;

