import React, { useState,useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { render } from '@testing-library/react';
import { alpha } from '@mui/material/styles';

  
const NumBlock = ({ num = 0}) =>{
    return (
      <ul style={{transform: `translateY(-${ num }em)`, 
      flex: '1',
      textAlign: 'center',
      transition:'all .3s ease',
      marginBlockStart:'unset',
      marginBlockEnd:'unset',
      paddingInlineStart:'unset'
      }}>
        {
          /* 通过 new Array().fill() 快速创建一个长度为10数组, 然后map取Index显示再列表上 */ 
          new Array(10).fill(0).map((t,i)=>(<li style={{listStyle: 'none',lineHeight:'1em',}} key={i}>{i}</li>))
        }
      </ul>
    )
}  
const ModalBlock = ({ unit, num })=>{
  if(num > 99) return (<p>Error, Num max is 99!</p>)
  /* 取十位 */
  const i = ~~(num/10)
  /* 取个位 */
  const j = num % 10
  /* 然后分别进行渲染 */
  const classes = useStyles();
  return (
    <div className={classes.modal_block}>
      <div className={classes.scroll_item}>
        <NumBlock num={i} />
        <NumBlock num={j} />
      </div>
      <span className={classes.num_span}>{ unit }</span>
    </div>
  )
}



const useStyles = makeStyles(theme => ({
  modal_block:{
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.mode==='light'?'#ffffffa6':'#7570704d',
    width: '2em',
    // height: '2.4em',
    alignItems: 'center',
    borderRadius: '0.2em',
    fontFamily: 'Comic Sans MS',
    justifyContent: 'space-between',
    padding: '0.2em',
    paddingTop: '0.4em',
    fontSize: '15px',  
    marginLeft:'0.2em'
  },
  num_span:{
    fontSize: '0.4em',
  },
  scroll_item:{
    width: '100%',
    height: '1em',
    display: 'flex',
    overflow:'hidden',
  },
  scroll_item_ul:{
    flex: '1',
    textAlign: 'center',
    transition:'all .3s ease',
  },
  flex_wrap:{
    display: 'flex',
  },
}));

const TimeModal = (props) => {
  const classes = useStyles();

  return (<div className={classes.flex_wrap}>
    { <ModalBlock  unit="天" num={ props.time.day }/> }
    { <ModalBlock  unit="时" num={ props.time.hours }/> }
    { <ModalBlock  unit="分" num={ props.time.minutes }/> }
    { <ModalBlock  unit="秒" num={ props.time.seconds }/> }
  </div>)
}
//重复调用组件 usestate处理 https://blog.csdn.net/Marker__/article/details/105593118
// let index =0;
// let state = [];
// function myUseState(initialValue){
//   const currentIndex = index;
//   index +=1;
//   state[currentIndex] = state[currentIndex]||initialValue;
//   const setState = newState =>{
//     state[currentIndex] = newState;
//     render(); 
//   }
//   return [state[currentIndex],setState];
// }

//在当前模块中定义变量_state
// let _state;
// const myUseState = (initState) => {
// 		// 第一次调用时没有初始值，因此使用传入的初始值赋值
//     _state = ( _state === undefined ? initState : _state);
//     const setState = (newState) => {
//         _state = newState
//         // 此方法能触发页面渲染
//         render()
//     }
//     return [_state,setState]
// }

// let timer;

export default function CountDown(props) {
  const targetTime = props.itemData.replace(/-/g,"/");

  let time = (Date.parse(targetTime) -  Date.parse(Date())) / 1000;

  
  // time = Math.abs(time);
  // console.log(timer);
    // console.log(countshit);
    let day = (time>0)?~~(time / 86400):0;
    let hours = (time>0)?~~((time - (day * 86400)) / 3600):0;
    let minutes = (time>0)?~~((time - (day * 86400) - (hours * 3600)) / 60):0;
    let seconds = (time>0)?~~(time % 60):0;
    const [ timeObj, setTimeObj] = useState({ day, hours, minutes, seconds });
    
    /* 判断是否走完 */
    if(time>0){
      if(seconds-- < 1) {
        seconds = 59
        if(--minutes < 1){
          minutes = 59
          if(--hours < 1) {
            hours = 23
            --day
          }
        }
      }                 
    }
    // console.log(seconds);

    useEffect(()=>{
      // clearInterval(timer);
      const timer = setInterval(()=> {
            setTimeObj({ day, hours, minutes, seconds})
        }, 1000)  
      return () => clearInterval(timer);

    },[timeObj]);
    
    
  return(<TimeModal  time={timeObj} />)
}