import * as React from 'react';
import Box from '@mui/material/Box';
import Appbar from '../components/Appbar';
import Settings from '../components/Settings';
import Navigation from '../components/Navigation';

export default function PageSettings(){

    return (
    	<Box>
    		<Appbar/ >
    		<Settings/ >
    		<Navigation/ >
    	</Box>
    );
}