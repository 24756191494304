import * as React from 'react';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import PaletteIcon from '@mui/icons-material/Palette';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Radio from '@mui/material/Radio';

import {useToggleTheme} from '../Theme';


export default function Settings() {
	const themeModelocal = window.localStorage.getItem('theme_mode')?window.localStorage.getItem('theme_mode'):'dark';
	const themePrimarylocal = window.localStorage.getItem('theme_primary')?window.localStorage.getItem('theme_primary'):'#f8d90f';
	const [modeChecked,setModeChecked] = React.useState(themeModelocal=='dark'?true:false);
	const [selectedValue, setSelectedValue] = React.useState(themePrimarylocal);
	
	const theme = useToggleTheme();

	const handlePrimaryChange = (event) => {
		setSelectedValue(event.target.value);
		window.localStorage.setItem('theme_primary',event.target.value);
	};

	const controlProps = (item) => ({
		checked: selectedValue === item,
		onChange: handlePrimaryChange,
		value: item,
		name: 'color-radio-button-demo',
		inputProps: { 'aria-label': item },
	});

	React.useEffect(() => {
	    if (modeChecked) {
			theme.toggleMode('dark');
	    } else {
	    	theme.toggleMode('light');
	    }
	    theme.togglePrimary(selectedValue);
  	}, [modeChecked,selectedValue]);


	const handleMode = (event) => {
		setModeChecked(event.target.checked);
		if (event.target.checked == true){
			window.localStorage.setItem('theme_mode','dark');
		} else {
			window.localStorage.setItem('theme_mode','light');
		}
	};

	

  	return (
	  	<Container fixed>
		    <List
		      sx={{ width: '100%', maxWidth: 480, bgcolor: 'background.paper',margin:'0 auto',pt:2 }}
		      subheader={<ListSubheader>设置</ListSubheader>}
		    >
				<ListItem>
					<ListItemIcon>
						<PaletteIcon />
					</ListItemIcon>
					<ListItemText id="switch-list-label-wifi" primary="主题" />
					<Radio
						{...controlProps('#f8d90f')}
						sx={{
							color: '#f8d90f',
							'&.Mui-checked': {
								color: '#f8d90f',
							},
						}}
					/>
					<Radio
						{...controlProps('#d5f566')}
						sx={{
							color: '#d5f566',
							'&.Mui-checked': {
								color: '#d5f566',
							},
						}}
					/>
					<Radio
						{...controlProps('#55c1d8')}
						sx={{
							color: '#55c1d8',
							'&.Mui-checked': {
								color: '#55c1d8',
							},
						}}
					/>
					<Radio
						{...controlProps('#d35555')}
						sx={{
							color: '#d35555',
							'&.Mui-checked': {
								color: '#d35555',
							},
						}}
					/>
					
				</ListItem>
				<ListItem>
					<ListItemIcon>
						{modeChecked?<DarkModeIcon />:<LightModeIcon />}
					</ListItemIcon>
					<ListItemText id="switch-list-label-mode" primary="深色模式" />
					<Switch
						edge="end"
						onChange={handleMode}
						checked={modeChecked}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</ListItem>
		    </List>
	    </Container>
  	);
}
