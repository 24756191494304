import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Searchbar from '../components/Searchbar';
import SearchImageList from '../components/SearchImageList';

export default function SearchPage(){

    return (
    	<Box>
    		<Searchbar/>
    		<Container fixed>
    			<SearchImageList/>
    		</Container>
    	</Box>
    );
}