import * as React from 'react';
import Box from '@mui/material/Box';

import Appbar from '../components/Appbar';
import MarketList from '../components/MarketList';
import Footer from '../components/Footer';
import Title from '../components/Title';

export default function Artist(){

    return (
    	<Box>
    		<Appbar/ >
            <Title value="交易市场"/ >
    		<MarketList/ >
    		<Footer/ >
    	</Box>
    );
}