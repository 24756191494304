import * as React from 'react';
import Box from '@mui/material/Box';
import AppbarCrumbs from '../components/AppbarCrumbs';
import PersonalCareer from '../components/PersonalCareer';
import Navigation from '../components/Navigation';

export default function PersonalCareerPage(){

    return (
    	<Box>
    		<AppbarCrumbs/ >
    		<PersonalCareer/ >
    		<Navigation value="mine"/>
    	</Box>
    );
}