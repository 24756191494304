import * as React from 'react';
import Box from '@mui/material/Box';

import Appbar from '../components/Appbar';
import Title from '../components/Title';
import Collectionlist from '../components/CollectionList';
import CollectionSeriesList from '../components/CollectionSeriesList';
import Navigation from '../components/Navigation';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CollectionsIcon from '@mui/icons-material/Collections';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	tabIcon: {
		marginRight:'2px!important',
	},
    tabPanel:{
        padding: '24px 6px!important',
    }
	
}));

export default function Collections(){
    const [value, setValue] = React.useState('2');
	const classes = useStyles();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    return (
    	<Box>
    		<Appbar/ >
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab icon={<AutoAwesomeIcon fontSize="inherit" className={classes.tabIcon} />} iconPosition="start" label="最新发售" value="1" ></Tab>
                    <Tab icon={<CollectionsIcon fontSize="inherit" className={classes.tabIcon} />} iconPosition="start" label="所有系列" value="2" />
                    <Tab icon={<FavoriteIcon fontSize="inherit" className={classes.tabIcon} />} iconPosition="start" label="关注清单" value="3" />
                </TabList>
                </Box>
                <TabPanel value="1" className={classes.tabPanel}><Collectionlist/ ></TabPanel>
                <TabPanel value="2" className={classes.tabPanel}><CollectionSeriesList /></TabPanel>
                <TabPanel value="3" className={classes.tabPanel}>Item Three</TabPanel>
            </TabContext>
    		
            
            <Navigation value="collections"/>
    	</Box>
    );
}