import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import {useHistory} from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';

import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { styled } from '@mui/material/styles';

import {apiUserCareerList} from '../common/Api.js';

const ToogleBar = styled('span')(() => ({
    position:'absolute',
    right:'16px',
    bottom:'8px'
}));

const CareerStatus = styled('span')(() => ({
    position:'absolute',
    right:'24px',
    top:'14px'
}));

export default function EditCareer(){

    const [User,setUser] = React.useState({});
    const history = useHistory();
    const [toggleStatus,setToggleStatus] = React.useState(false);
    const [list,setList] = React.useState([]);
    const [listEdu,setListEdu] = React.useState([]);
    const [listExp,setListExp] = React.useState([]);

    const handleHref = (url) => {
        history.push(url);
    };

    React.useEffect(()=>{
		let userInfoStr = window.localStorage.getItem("userInfo");
		let userInfo = JSON.parse(userInfoStr);
		setUser(userInfo);
        apiUserCareerList({}).then(res=>{
            if (res.code == 200) {
                if (res.data != null){
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].status == 0){
                            res.data[i].statusDisplay = '待审核'
                        } else if (res.data[i].status == 1){
                            res.data[i].statusDisplay = '已认证'
                        } else if (res.data[i].status == 2){
                            res.data[i].statusDisplay = '未通过'
                        } 
                        res.data[i].dateDisplay = handleDateDisplay(res.data[i].startDate, res.data[i].endDate)
                        if (res.data[i].kind == 1){
                            setListEdu(listEdu => [...listEdu, res.data[i]]);
                        } else if (res.data[i].kind == 2){
                            setListExp(listExp => [...listExp, res.data[i]]);
                        }
                        
                    }
                }
            }
        });
	},[]);

    const handleDateDisplay = (s,e) => {

        const dateNow = new Date();

        const startDate = new Date(s);
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth();
        const startDateDisplay = startDate.getFullYear()+"/"+(startDate.getMonth()+1)+"/"+startDate.getDay();

        let endDate = new Date(e);
        let endDateTimestamp = endDate.getTime();
        let endDateDisplay = endDate.getFullYear()+"/"+(endDate.getMonth()+1)+"/"+endDate.getDay();
        if (endDateTimestamp > dateNow.getTime()){
            endDateDisplay = "至今"
            endDate = new Date();
        }
        const endYear = endDate.getFullYear();
        const endMonth = endDate.getMonth();
       
        
        let tillMonth = (endYear*12+endMonth)-(startYear*12+startMonth);
        let tillDisplay = "";
        const tillYear = (tillMonth - (tillMonth%12)) / 12;
        if (tillMonth == 0){
            tillMonth = tillMonth+1;
        }
        if (tillMonth > 12){
            tillDisplay = tillYear+'年';
        } else if (tillMonth < 12){
            tillDisplay = tillMonth+'月';
        }

        return startDateDisplay + " - " + endDateDisplay + " · " + tillDisplay

    }

    const handleTooggle = () => {
        if (toggleStatus) {
            setToggleStatus(false);
        } else {
            setToggleStatus(true);
        }
    }


    return (
    	<Box sx={{pb:2}}>
            <Container fixed>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{py:3}}
                    >
                    <Typography variant="h5">艺术履历</Typography>
                    <Box>
                        {toggleStatus?<ToggleOnIcon onClick={handleTooggle} fontSize='large' sx={{mx:0.5}} />:<ToggleOffOutlinedIcon onClick={handleTooggle} fontSize='large' sx={{mx:0.5}} />}
                        <AddIcon onClick={(e)=>handleHref('editCareer')} fontSize='large' sx={{mx:0.5}} />
                    </Box>
                </Stack>
                <Divider  />
            <List sx={{}}>
                <Typography  sx={{ display: 'block',fontWeight:700,fontSize:'18px',paddingTop:'10px',paddingBottom:'10px' }}>
                    教育背景
                </Typography>
                {listEdu.map((item,i) => (
                <Box  key={i}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={item.cover} />
                        </ListItemAvatar>
                        <ListItemText
                        primary={item.title}
                        secondary={
                            <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {item.description}
                                <CareerStatus>{item.statusDisplay}</CareerStatus>
                            </Typography>
                            <Typography
                                sx={{ display: 'block' }}
                                component="span"
                                variant="body2"
                                fontSize='12px'
                                color='#afa9a9'
                                paddingTop='5px'
                            >
                                {item.dateDisplay}
                                {toggleStatus?
                                <ToogleBar>
                                    <EditIcon sx={{mx:1}} />
                                    <DeleteIcon />
                                </ToogleBar>
                                :<ToogleBar></ToogleBar>}
                            </Typography>
                            
                            </React.Fragment>
                        }
                        />
                    </ListItem>
                    <Divider sx={{marginLeft:'70px'}}/>
                </Box>
                ))}
                <Typography  sx={{ display: 'block',fontWeight:700,fontSize:'18px',paddingTop:'15px',paddingBottom:'10px' }}>
                    实践经历
                </Typography>
                {listExp.map((item,i) => (
                <Box  key={i}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={item.cover} />
                        </ListItemAvatar>
                        <ListItemText
                        primary={item.title}
                        secondary={
                            <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {item.description}
                                <CareerStatus>{item.statusDisplay}</CareerStatus>
                            </Typography>
                            <Typography
                                sx={{ display: 'block' }}
                                component="span"
                                variant="body2"
                                fontSize='12px'
                                color='#afa9a9'
                                paddingTop='5px'
                            >
                                {item.dateDisplay}
                                {toggleStatus?
                                <ToogleBar>
                                    <EditIcon sx={{mx:1}} />
                                    <DeleteIcon />
                                </ToogleBar>
                                :<ToogleBar></ToogleBar>}
                            </Typography>
                            
                            </React.Fragment>
                        }
                        />
                    </ListItem>
                    <Divider sx={{marginLeft:'70px'}}/>
                </Box>
                ))}

            </List>
            </Container>
    	</Box>
    );
}