import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';

import { red, blueGrey, orange } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { Link, useHistory } from "react-router-dom";

import { styled } from '@mui/material/styles';
import {isMobile} from 'react-device-detect';

import { useParams } from "react-router-dom";

import {apiCollectionDetail,apiCollectionFollow,apiArtistFollow,apiCollectionSubDetail} from '../common/Api.js';

import { useSnackbar } from 'notistack';

const OrgText = styled(Box)(() => ({
  display:'inline',
  textDecoration:'underline',
}));


export default function Coldetail(){

	const [Col,setCol] = React.useState({});
	const [Artist,setArtist] = React.useState({});
	const [Organ,setOrgan] = React.useState({});
	const params = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const CardPadding = isMobile?2:4;
	const ref = React.useRef();
	const history = useHistory();

	React.useEffect(()=>{
		apiCollectionSubDetail({uid:params.uid,number:params.number}).then(res=>{
			console.log(res)
			if (res.code == 200) {			
				res.data.CollectionSubCover = res.data.CollectionSub[0].cover
				res.data.CollectionSubNumber = res.data.CollectionSub[0].number
				res.data.CollectionSubUid = res.data.CollectionSub[0].uid
				res.data.CollectionSubHash = (res.data.CollectionSub[0].TxHash ?res.data.CollectionSub[0].TxHash :"上链确认中");
				res.data.isSold = res.data.CollectionSub[0].TxHash ?1:0
				setCol(res.data);
				res.data.artist.url = "/space/"+res.data.artist.uid
				setArtist(res.data.artist);
				res.data.artist.organ.url = "/organization/"+res.data.artist.organ.uid;
				setOrgan(res.data.artist.organ);
				const node = document.createRange().createContextualFragment(res.data.detail);
    			ref.current.appendChild(node);
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		})
	},[]);

	const handleHref = (url) => {
		history.push(url);
	};

	const toggleFollow = (item) => e => {
		apiCollectionFollow({uid:item.uid}).then(res=>{
			if (res.code == 200) {
				let followerCountUpdate = item.followerCount;
				if (res.data == 1){
					followerCountUpdate++;
				} else {
					followerCountUpdate--;
				}
				setCol((Col)=>({...Col, isFollowed:res.data, followerCount:followerCountUpdate}));
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
	}

	const toggleFollowArtist = (item) => e => {
		apiArtistFollow({uid:item.uid}).then(res=>{
			if (res.code == 200) {
				let followerCountUpdate = item.followerCount;
				if (res.data == 1){
					followerCountUpdate++;
				} else {
					followerCountUpdate--;
				}
				setArtist((Artist)=>({...Artist, isFollowed:res.data, followerCount:followerCountUpdate}));
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
	}

    return (
    	<Box>
    		<Container fixed sx={{pt:4}}>
    			<Grid container spacing={2} >
    				<Grid item xs={12} md={6}>
	    				<Card sx={{ maxWidth: '100%',position:'relative' }}>
							<CardMedia
								component="img"
								image={Col.CollectionSubCover}
								alt="green iguana"
							/>
					    </Card>
	    			</Grid>
	    			<Grid item xs={12} md={6}>
	    				<Card sx={{ maxWidth: '100%',position:'relative' }}>
							<CardHeader title={Col.collectionName+"#"+Col.CollectionSubNumber}
								action={
									<Button onClick={toggleFollow(Col)} variant="text" color="error" size="small" endIcon={Col.isFollowed==1?<FavoriteIcon />:<FavoriteBorderIcon />}>
										{Col.followerCount}
									</Button>
								}
							/>
							
							<CardContent sx={{py:1}}>
								<Typography variant="h6" gutterBottom>藏品信息</Typography>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box>系列发行数量</Box>
									<Box>{Col.publishCount}份</Box>
								</Stack>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box>发行数量占比</Box>
									<Box>1/{Col.publishCount}</Box>
								</Stack>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box>当前剩余</Box>
									<Box>{Col.isSold?"已售":"1份"}</Box>
								</Stack>
							</CardContent>
		    				<CardContent sx={{py:1}}>
		    					<Typography variant="h6" gutterBottom>认证信息</Typography>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box sx={{minWidth:"30%"}}>合约地址</Box>
									<Box sx={{overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={(e)=>handleHrefOuter(Col.CollectionSubHash)}>{Col.CollectionSubHash}</Box>
								</Stack>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box>认证标准</Box>
									<Box>ERC-721</Box>
								</Stack>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									spacing={2}
									sx={{opacity:0.8}}
								>
									<Box>认证网络</Box>
									<Box>Polygon</Box>
								</Stack>
							</CardContent>
					    </Card>
	    			</Grid>
    			</Grid>
    			<Grid container spacing={1} sx={{pt:2}}>
    				<Grid item xs={12} md={3}>
	    				<Card sx={{height:'200px',pt:2}}>
							<Box>
								<Avatar onClick={(e)=>handleHref(Artist.url)} src={Artist.avatar} sx={{
									margin:'auto',
									width:100,
									height:100,
								}}/ >
								<Box sx={{
									textAlign:'center',
									fontSize:'1.25em',
									fontWeight:'bold',
									my:0.5
								}}>
									{Artist.nickname}@<OrgText onClick={(e)=>handleHref(Organ.url)}>{Organ.nickname}</OrgText>
								</Box>
								<Box sx={{
									textAlign:'center',
									fontWeight:'bold',
								}}>	
									{Artist.followerCount}粉丝
									<Button onClick={toggleFollowArtist(Artist)} variant={Artist.isFollowed == 1?"outlined":"contained"} size="small" sx={{ml:2}}>
										{Artist.isFollowed == 1?"已关注":"+关注"}
									</Button>
								</Box>
							</Box>
					    </Card>
	    			</Grid>
	    			<Grid item xs={12} md={9}>
						<Card sx={{
							minHeight:'200px',
							p:CardPadding,
						}}>
							<Typography variant="body1">
								{Artist.introduction}
							</Typography>
					    </Card>
	    			</Grid>
    			</Grid>

    			<Grid container spacing={0} sx={{pt:2}}>
    				<Grid item xs={12} md={12}>
	    				<Card sx={{p:2}}>
							<div ref={ref}></div>
					    </Card>
	    			</Grid>
    			</Grid>

    		</Container>
    	</Box>
    );
}