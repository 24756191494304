import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Typography } from '@mui/material';
import utils from '../common/utils'

import { apiPeanutRecordList } from '../common/Api';

export default function PeanutRecord(){
    const [items, setItems] = React.useState([]);

    React.useEffect(()=>{
        apiPeanutRecordList({}).then(res=>{
            if (res.code == 200) {
                for(var i=0;i<res.data.length;i++){
                    res.data[i].create_time = utils.getNowFormatDate(new Date(res.data[i].create_time))
                    // switch(res.data[i].type_id)
                    // {
                    //     case 1:
                    //         res.data[i].icon = "<AssignmentTurnedInIcon/>"
                    //         break;
                    //     case 2:
                    //         res.data[i].icon = '<svg className="icon" aria-hidden="true"><use xlinkHref="#icon-a-yaoqinghaoyou-hei2"></use></svg>'
                    //         break;
                    //     case 3:
                    //         res.data[i].icon = "<PostAddIcon/>"
                    //         break;
                    //     case 4:
                    //         res.data[i].icon = '<svg className="icon" aria-hidden="true"><use xlinkHref="#icon-a-chouqian-hei2"></use></svg>'
                    //         break;
                    //     default:
                    //         res.data[i].icon = "<AssignmentTurnedInIcon/>"
                    //         break;
                    // }
                } 
                setItems(res.data)             
            }
        })
    
    },[])

    return (
    	<Box>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding:0}}
                component="nav"
                aria-labelledby="nested-list-subheader"
                >
                {items.map((item,key)=>(
                    <ListItemButton key={key}>
                    <ListItemIcon >
                    {/* <AssignmentTurnedInIcon/> */}
                    {item.type_id==1?<AssignmentTurnedInIcon/> :""}
                    {item.type_id==2?<svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-a-yaoqinghaoyou-hei2"></use>
                    </svg>:""}
                    {item.type_id==3?<PostAddIcon/>:""}
                    {item.type_id==4?<svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-a-chouqian-hei2"></use>
                    </svg>:""}
                    </ListItemIcon>
                    <ListItemText primary={item.explain} secondary={item.create_time} />
                    <Box sx={{fontSize:'12px'}}>
                        <Typography variant='h6' sx={{display:'inline',pr:1}}>{item.type_id!=4?"+":""}{item.num}</Typography>
                        <svg className="icon" aria-hidden="true">
                            <use xlinkHref="#icon-huasheng-hei"></use>
                        </svg>
                    </Box>
                </ListItemButton>
                ))}
                
            </List>
    	</Box>
    );
}