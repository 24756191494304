import * as React from 'react';

import { Box } from '@mui/material';

import ShowEmpty from '../components/ShowEmpty';


export default function MobileOnly(){

    return (
    	<Box>
    	<ShowEmpty desc="请在手机浏览器打开" py="300" />
    	</Box>
    );
}