import { Box, Container } from '@mui/material';
import * as React from 'react';
import { styled,alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Slider from '@mui/material/Slider';

import Title from '../components/Title';
import MarketItem from '../components/MarketItem';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#000', 0.15),
    '&:hover': {
      backgroundColor: alpha('#000', 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '18ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  function valuetext(value) {
    return `${value}°C`;
  }
  
  const minDistance = 0;

export default function MarketList(){
    const [value1, setValue1] = React.useState([0, 100]);

    const handleChange1 = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
        return;
        }

        if (activeThumb === 0) {
        setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
        } else {
        setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
        }
    };

 
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container>

                <Grid container spacing={2} >
                    <Grid item xxs={0} xs={3} md={3}>
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, mr: 2 }}>
                        <Search>
                            <SearchIconWrapper>
                            <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                            placeholder="系列藏品、艺术家"
                            inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                    </Box>
                    <Box sx={{width:200,
									height:20,
									
									color:'darkslategray',
									fontSize:'larger',padding:'10px 10px',margin:'30px 10px',fontWeight:'600'}}>分类</Box>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label="3D ape" />
                            <FormControlLabel control={<Checkbox />} label="Chimera" />
                            <FormControlLabel control={<Checkbox />} label="3DPunks" />
                            <FormControlLabel control={<Checkbox />} label="Azuki" />
                            <FormControlLabel control={<Checkbox />} label="PhantaBear" />
                            <FormControlLabel control={<Checkbox />} label="12星座" />
                            <FormControlLabel control={<Checkbox />} label="Madmeta Lab" />
                            <FormControlLabel control={<Checkbox />} label="外卖员" />
                            <FormControlLabel control={<Checkbox />} label="Lucky Zeros" />
                            <FormControlLabel disabled control={<Checkbox />} label="cool cat" />
                        </FormGroup>

                        <Box sx={{width:200,
									height:20,								
									color:'darkslategray',
									fontSize:'larger',padding:'10px 10px',margin:'30px 10px',fontWeight:'600'}}>价格筛选</Box>
                        
                        <Slider
                            sx={{width:'80%'}}
                            getAriaLabel={() => 'Minimum distance'}
                            value={value1}
                            onChange={handleChange1}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            disableSwap
                        />
                    </Grid>
                    <Grid item xs={9} xxs={12} md={9}>
                            <MarketItem/ >
                    </Grid>
                </Grid>

                <Grid container spacing={2} >
                </Grid>
            </Container>
        </Box>
    );
}