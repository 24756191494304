const addZero=(i)=> {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  const getNowFormatDate=(date)=> {
    var seperator1 = "-";
    var seperator2 = ":";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate
            + " " + addZero(date.getHours()) + seperator2 + addZero(date.getMinutes())
            + seperator2 + addZero(date.getSeconds());
    return currentdate;
  }



  export default{
     "getNowFormatDate":getNowFormatDate,
  }

 