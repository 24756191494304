import * as React from 'react';
import Box from '@mui/material/Box';

import Appbar from '../components/Appbar';
import ColSub from '../components/ColSub';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';

export default function CollectionSub(){

    return (
    	<Box>
    		<Appbar/ >
    		<ColSub/ >
    		<Navigation value="collections"/ >
    		<Footer/ >
    	</Box>
    );
}