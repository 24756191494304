import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@material-ui/core/ImageList';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

//icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { makeStyles } from '@material-ui/core/styles';

import { apiUserFollowList } from '../common/Api';

import ShowEmpty from './ShowEmpty';

import { withRouter } from "react-router-dom";


const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
        height:'130px',
	},
    postRoot: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
        height:'100px',
	},
    artistRoot: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
        height:'100px',
	},
	gridList: {
		flexWrap: 'nowrap',
	},
	itemImg:{
		borderRadius:'10px'
	}
}));


function PersonalLike(props){

    const classes = useStyles();
    const [followUserList,setFollowUserList] = React.useState([]);
    const [followPostList,setFollowPostList] = React.useState([]);
    const [followColList,setFollowColList] = React.useState([]);

    React.useEffect(()=>{
		apiUserFollowList({classify:1}).then(res=>{
			if (res.code == 200) {
                if (res.data != null){
                    for (var i = 0; i < res.data.length; i++) {
                        res.data[i].url = "/space/"+res.data[i].uid;
                    };
                    setFollowUserList(res.data);
                }
			} 
		});
        apiUserFollowList({classify:2}).then(res=>{
			if (res.code == 200) {
                if (res.data != null){
                    for (var i = 0; i < res.data.length; i++) {
                        res.data[i].url = "/postDetail/"+res.data[i].uid;
                        const imgArr = JSON.parse(res.data[i].images);
                        res.data[i].cover = imgArr[0];
                    };
                    setFollowPostList(res.data);
                }
			} 
		});
        apiUserFollowList({classify:3}).then(res=>{
			if (res.code == 200) {
                if (res.data != null){
                    for (var i = 0; i < res.data.length; i++) {
                        res.data[i].url = "/collection/"+res.data[i].uid;
                    };
                    setFollowColList(res.data);
                }
			} 
		});
	},[]);

    const handleHref = (url) => {
        props.history.push(url);
	};

    return (
    	<Box sx={{mx:1,mb:3}}>
            <Box>
                <Box sx={{py:1}}>
                    <Typography variant="body" sx={{fontWeight:'bold'}}>
                        我关注的艺术家<ArrowForwardIosIcon sx={{pt:1.5}}/>
                    </Typography>
                </Box>
                <Box className={classes.artistRoot}>
                    {followUserList.length == 0?
                    <ShowEmpty desc="未关注任何艺术家" py="20" iconSize="40px" />
                    :
		    		<ImageList className={classes.gridList} cols={10}>
    				{ followUserList.map( (item, i) => 
    					<Box key={i} sx={{minWidth: '80px',minHeight:'110px',mr:1}}>
							<Avatar 
                                src={item.avatar} 
                                sx={{
								margin:'auto',
								width:80,
								height:80,
							    }} 
                                onClick={(e)=>handleHref(item.url)}
                            />
								<Typography variant="caption" component="div" sx={{textAlign:'center'}}>
									{item.nickname}
								</Typography>
						</Box>
    				)}
    				</ImageList>
                    }
    			</Box>
            </Box>
            <Box>
                <Box sx={{py:1}}>
                    <Typography variant="body" sx={{fontWeight:'bold'}}>
                        我点赞的POST<ArrowForwardIosIcon sx={{pt:1.5}}/>
                    </Typography>
                </Box>
                <Box className={classes.postRoot}>
                    {followPostList.length == 0?
                        <ShowEmpty desc="未点赞任何POST" py="20" iconSize="40px" />
                    :
                    <ImageList className={classes.gridList} cols={10}>
                        { followPostList.map( (item, i) => 
                            <Box key={i} sx={{minWidth: '100px',minHeight:'100px',mr:1,position:'relative'}} onClick={(e)=>handleHref(item.url)}>
                                <img src={item.cover} className={classes.itemImg} width="100" height="100" />
                            </Box>
                        )}
                    </ImageList>
                    }
                </Box>
            </Box>
            <Box>
                <Box sx={{py:1}}>
                    <Typography variant="body" sx={{fontWeight:'bold'}}>
                        我喜欢的藏品<ArrowForwardIosIcon sx={{pt:1.5}}/>
                    </Typography>
                </Box>
                <Box className={classes.root}>
                    {followColList.length == 0?
                        <ShowEmpty desc="未喜欢任何藏品" py="25" iconSize="40px" />
                    :
                    <ImageList className={classes.gridList} cols={10}>
                        { followColList.map( (item, i) => 
                            <Box key={i} sx={{minWidth: '100px',minHeight:'130px',mr:1,position:'relative'}} onClick={(e)=>handleHref(item.url)}>
                                <img src={item.cover} className={classes.itemImg} width="100" height="100" />
                                <Typography variant="subtitle2">
                                    {item.collectionsName}
                                </Typography>
                            </Box>

                        )}
                    </ImageList>
                    }
                </Box>
            </Box>
            
    	</Box>
    );
}

export default withRouter(PersonalLike);