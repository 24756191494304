import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useParams } from "react-router-dom";

import {apiBannerDetail} from '../common/Api.js';

import { useSnackbar } from 'notistack';

export default function BannerDetail(){

	const params = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const [Detail,setDetail] = React.useState("");

	React.useEffect(()=>{
		apiBannerDetail({uid:params.uid}).then(res=>{
			if (res.code == 200) {
				setDetail(res.data.detail);
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		})
	},[]);

    return (
    	<Box>
    		<Container sx={{pt:2}}>
    			<Typography variant="body1" gutterBottom>
					{Detail}
				</Typography>
    		</Container>
    	</Box>
    );
}

