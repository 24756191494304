import service from '../common/ApiService.js';

export const apiUserSignin = params => service.post('/api/user/signin', params);
export const apiUserSignup = params => service.post('/api/user/signup', params);
export const apiUserSignupStep2 = params => service.post('/api/user/signupStep2', params);
export const apiUserSendCode = params => service.post('/api/user/sendCode', params);
export const apiUserGetInfo = params => service.post('/api/user/getInfo', params);
export const apiUserEditAvatar = params => service.post('/api/user/editAvatar', params);
export const apiUserEditNickname = params => service.post('/api/user/editNickname', params);
export const apiUserEditIntroduction = params => service.post('/api/user/editIntroduction', params);
export const apiUserGetAvatarList = params => service.post('/api/user/getAvatarList', params);
export const apiUserPostList = params => service.post('/api/user/postList', params);
export const apiUserEditCareer = params => service.post('/api/user/editCareer', params);
export const apiUserCareerList = params => service.post('/api/user/careerList', params);
export const apiUserFollowList = params => service.post('/api/user/followList', params);

export const apiBannerList = params => service.post('/api/banner/list', params);
export const apiBannerDetail = params => service.post('/api/banner/detail', params);

export const apiNoticeList = params => service.post('/api/notice/list', params);

export const apiCollectionList = params => service.post('/api/collection/list', params);
export const apiCollectionDetail = params => service.post('/api/collection/detail', params);
export const apiCollectionSub = params => service.post('/api/collection/sub', params);
export const apiCollectionSubDetail = params => service.post('/api/collection/subDetail', params);
export const apiCollectionSeries = params => service.post('/api/collection/series', params);
export const apiCollectionFollow = params => service.post('/api/collection/follow', params);
export const apiCollectionDrawList = params => service.post('/api/collection/drawList', params);
export const apiCollectionDrawRecord = params => service.post('/api/collection/drawRecord', params);

export const apiArtistList = params => service.post('/api/artist/list', params);
export const apiArtistDetail = params => service.post('/api/artist/detail', params);
export const apiArtistFollow = params => service.post('/api/artist/follow', params);
export const apiArtistPostList = params => service.post('/api/artist/postList', params);
export const apiArtistAbout = params => service.post('/api/artist/about', params);

export const apiPostPublish = params => service.post('/api/post/publish', params);
export const apiPostList = params => service.post('/api/post/list', params);
export const apiPostDetail = params => service.post('/api/post/detail', params);
export const apiPostLike = params => service.post('/api/post/like', params);

export const apiPostCommentDetail = params => service.post('/api/comment/list', params);
export const apiPostCommentPublish = params => service.post('/api/comment/publish', params);

export const apiPeanutAdd = params => service.post('/api/peanut/record', params);
export const apiPeanutRecordList = params => service.post('/api/peanut/list', params);
export const apiPeanutSum = params => service.post('/api/peanut/sum', params);
export const apiPeanutCheck = params => service.post('/api/peanut/check', params);