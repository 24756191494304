import * as React from 'react';
import Box from '@mui/material/Box';
import Appbar from '../components/Appbar';
import Peanut from '../components/Peanut';
import Navigation from '../components/Navigation';

export default function PeanutPage(){

    return (
    	<Box>
    		<Appbar />
    		<Peanut />
    		<Navigation />
    	</Box>
    );
}