import * as React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import { NavLink} from "react-router-dom";

import {apiBannerList} from '../common/Api.js';

export default function Example(props){

	const [items,setItems] = React.useState([]);


	React.useEffect(()=>{
		apiBannerList({}).then(res=>{
			if (res.code == 200) {
                if (res.data != null){
                    for (var i = 0; i < res.data.length; i++) {
                        res.data[i].url = "banner/"+res.data[i].uid;
                    };
                    setItems(res.data);
                }
			}
		})
	},[])


    return (
        <Carousel indicators={false}>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
    )
}

function Item(props)
{
    return (
    	<NavLink to={props.item.url}>
			<Card sx={{ maxWidth: '100%',borderRadius:0}}>
		      <CardMedia
		        component="img"
		        image={props.item.image}
		        alt="Paella dish"
		      />
		    </Card>
	    </NavLink>
    )
}
