import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Box, Button, CardActionArea, CardActions, Container,Grid, Icon } from '@mui/material';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { padding } from '@mui/system';

function ArtistInfo(props){
  const textinfo = "草帽海贼团悬赏公布，索隆等三人达到10亿贝利之后是草帽海贼团这边，他们的悬赏令也公布了，其中作为草帽海贼团现在三大战力，也就是相当于“三灾”的索隆、山治和甚平三人的悬赏都超过了10亿，这也让草帽海贼团，真正拥有了自称四皇的底气。  三人之中，索隆的悬赏最高，为11亿1100贝利，其次是甚平，因为是前七武海的原因，所以甚平的悬赏也不低，直接悬赏11亿贝利。最后是山治，他的悬赏为10亿3200万贝利，相较于索隆要少一些，估计山治在看到自己和索隆的差距的时候，会很不高兴吧！      之后是草帽海贼团之中，最受五老星重视的考古学家妮可·罗宾，被悬赏9亿3000万贝利，以罗宾的实力，是不可能有这么高的悬赏的，对此只能说罗宾因为身份的原因，悬赏被大大的提高了。剩下的乌索普悬赏5亿贝利，不愧是大神之名，和之国没怎么战胜对手，悬赏却没少增加。接下来弗兰奇3亿9400贝利，布鲁克3亿8300万贝利，娜美3亿66万贝利，这几位的悬赏，几乎都是直接增加了3亿。     最后则是可怜的乔巴了，这次的乔巴可以说是几人之中提升最多的，毕竟乔巴的悬赏直接一下子提升了10倍，从原来的100贝利，提升到现在的1000贝利，不过小爱推测，乔巴在看到悬赏令的时候是哭着看完的吧！";
  const [readMore,setReadMore] = React.useState(false);
  const itemData = [
    {
      img: 'fff (1).png',
      title: 'Breakfast',
    },
    {
      img: 'fff (2).png',
      title: 'Burger',
    },
    {
      img: 'fff (3).png',
      title: 'Camera',
    },
    
  ];
    return (
        <Box >
            {/* <Avatar src="../avatar.jpeg" variant="rounded" sx={{width:'38px',height:'38px'}}>
            </Avatar>
            <Box  className='artistInfoContent' sx={{}}>
                <Typography  gutterBottom>
                    Zed
                </Typography>
                <Typography  gutterBottom>
                当艺术赋予科技灵感
                </Typography>
            </Box> */}

            <List sx={{ width: '90%',  bgcolor: 'background.paper' ,margin:'0 auto', }}>

            <ListItem sx={{ display: 'block', }}>
              <ListItemAvatar sx={{display:'inline-block',}}>
                <Avatar alt="zed" src="avatar.jpeg" variant="rounded" sx={{width:'38px',height:'38px'}}/>
              </ListItemAvatar>
              <ListItemText
                sx={{display:'inline-block',}}
                // primary="zed" 
                primary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'block',fontWeight:600,fontSize:'15px', }}
                      component="p"
                      variant="body2"
                    >
                      zed
                    </Typography>
                    <Typography
                      sx={{ display: 'block' }}
                      component="p"
                      variant="body2"
                      color="#646464"
                    >
                      当艺术赋予科技灵感
                    </Typography>
                  </React.Fragment>
                }
              />
              <ImageList sx={{ width: '90%',padding:'20px 20px'}} cols={3} >
                {itemData.map((item) => (
                  <ImageListItem key={item.img}>
                    <img
                      src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              <ListItemText>
                    <Typography
                      sx={{ display: 'block' }}
                      component="p"
                      variant="body1"
                      color="text.primary"
                    >
                        {       
                          readMore ? textinfo :textinfo.substring(0,150)
                        }
                          <Typography 
                              variant="button" display="inline-block" gutterBottom sx={{color:'#175199',fontSize:'1rem'}}
                              onClick={() => setReadMore(!readMore)}>
                              { readMore ? '收起' : '...查看全文' }               
                              { readMore ? <ExpandLessIcon sx={{verticalAlign:'middle'}}></ExpandLessIcon> : <ExpandMoreIcon sx={{verticalAlign:'middle'}}></ExpandMoreIcon>}               
                          </Typography>
                    </Typography>
                    <Typography
                      sx={{ display: 'block' }}
                      component="span"
                      variant="body2"
                      fontSize='14px'
                      color='#646464'
                      paddingTop='5px'
                    >
                      发布于 2022-07-02 09:42
                    </Typography>
              </ListItemText>
              
              <Button  startIcon={<FavoriteBorderIcon />} color="error" sx={{}}>
                93,875
              </Button>
              <Button  startIcon={<TextsmsOutlinedIcon />}  sx={{color:"#8590a6",marginLeft:'24px',}}>
                34条评论
              </Button>
            </ListItem>
            <Divider variant="middle" />


            <ListItem alignItems="flex-start"sx={{textAlign:'center',display:'block'}}>
              <Button variant="text">查看更多</Button>
            </ListItem>
            

        </List>
        </Box>    
    )
}
function PostView(props){
    return (
        <Grid container spacing={2}>
            <ArtistInfo />
        </Grid>
    );
}
export default function MyPost() {

    return (
        <Box sx={{padding:0,width:'70%',}}>
            <Paper elevation={3} />

            <Grid container spacing={2}>
            <Grid item  md={12}>
                <PostView />
            </Grid>
            {/* <Grid item  md={4}>
                <RightSidebar />
            </Grid> */}
            </Grid>
        </Box>
    );
}