import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

import PostListCommon from './postListCommon';
import ArtCircleNew from '../components/ArtCircleNew';
//import ArtCircleOrg from '../components/ArtCircleOrg';
import AppBar from '@mui/material/AppBar';

import AppbarMenu from './AppbarMenu';

const TabPanelItem = styled(TabPanel)(() => ({
    padding:0,
}));

const TabItem = styled(Tab)(() => ({
    minWidth:0,
    minHeight:0,
    margin:'0px 20px',
    padding:0,
    fontSize:'18px'
}));

export default function LabTabs() {
    const [value, setValue] = React.useState('2');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value} sx={{height:'28px',minHeight:'28px'}}>
            <AppBar position="sticky" color="background" sx={{height:'50px',pt:1.5}}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" centered sx={{padding:0,minHeight:'28px'}} >
                    <TabItem label="关注" value="1" />
                    <TabItem label="发现" value="2" />
                    <TabItem label="机构" value="3" />
                </TabList>
                <Box sx={{position:'absolute',top:'8px',right:'15px'}}>
                    <AppbarMenu/>
                </Box>
            </AppBar>
            
            <TabPanelItem value="1"><Container fixed><PostListCommon/></Container></TabPanelItem>
            <TabPanelItem value="2"><ArtCircleNew classify="2"/></TabPanelItem>
            <TabPanelItem value="3"><ArtCircleNew classify="3"/></TabPanelItem>
        </TabContext>
    </Box>
  );
}
