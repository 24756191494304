import * as React from 'react';
import Box from '@mui/material/Box';
import AppbarCrumbs from '../components/AppbarCrumbs';
import EditAvatar from '../components/EditAvatar';
import Navigation from '../components/Navigation';

export default function EditAvatarPage(){

    return (
    	<Box>
    		<AppbarCrumbs/ >
    		<EditAvatar/ >
    		<Navigation value="mine"/>
    	</Box>
    );
}