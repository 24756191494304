import * as React from 'react';
import Box from '@mui/material/Box';
import Appbar from '../components/Appbar';
import Title from '../components/Title';
import Message from '../components/Message';
import Navigation from '../components/Navigation';

export default function MessagePage(){

    return (
    	<Box>
    		<Appbar/ >
            <Title value="信箱" />
            <Message />
            <Navigation />
    	</Box>
    );
}