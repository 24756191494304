import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';
import {apiUserSendCode,apiUserSignup,apiUserGetAvatarList,apiUserSignupStep2} from '../common/Api';
import { useHistory} from "react-router-dom";

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{my:5}}>
            Copyright © MadMeta.Art .
        </Typography>
    );
}

const modalStyle = {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const steps = ['注册', '设置昵称头像', '实名认证'];

//Step0 注册
function Step0(props) {

    let [phone,setPhone]=React.useState("");
    let [code,setCode]=React.useState("");
    let [sendCodeText,setSendCodeText]=React.useState("发送验证码");
    
    let [password,setPassword]=React.useState("");
    let [inviteCode,setInviteCode]=React.useState("");
    let [sendStatus,setSendStatus]=React.useState(false);
    let [agreeStatus,setAgreeStatus]=React.useState(false);
    const [num,setNum] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const handleHref = (url) => {
        history.push(url);
    };

    const handleSubmit = () => {
        var phoneReg = /^[1]([3-9])[0-9]{9}$/
        var passwordReg = /^[a-zA-Z0-9_-]{8,16}$/
        if (phoneReg.test(phone) != true){
            enqueueSnackbar("请填写正确的国内手机号",{variant:"warning"});
        } else if (passwordReg.test(password)!=true){
            enqueueSnackbar("密码格式不正确",{variant:"warning"});
        } else {
            let params = {phone:phone,code:code,password:password,invite_code:inviteCode}
            apiUserSignup(params).then(res=>{
                if (res.code == 200){
                    window.localStorage.setItem('token',res.data.token);
                    props.setUserInfo(res.data.userinfo);
                    enqueueSnackbar("注册成功",{variant:"success"});
                    props.handleNext();
                } else {
                    enqueueSnackbar(res.msg,{variant:"error"});
                }
            });
        }
        
    };

    const sendCode = () => {
        var phoneReg = /^[1]([3-9])[0-9]{9}$/
        if (phoneReg.test(phone)){
            apiUserSendCode({phone:phone}).then(res=>{
                handleSendButton();
            });

        } else {
            enqueueSnackbar("请填写正确的国内手机号",{variant:"warning"});
        }
        
    }

    const handleSendButton = () => {
        let t = 60
        setSendStatus(true);
        setNum(t)
        const t1 = setInterval(()=>{
        t = t - 1;
        setNum(t)
        if (t == 0){
            setSendStatus(false)
            setSendCodeText("发送验证码")
            clearInterval(t1)
        }
        },1000);
    }

    React.useEffect(()=>{
        if (num > 0) {
            setSendCodeText(num)
        }
    })

    const handleAgree = (event) => {
        setAgreeStatus(event.target.checked);
    }

    const codeChange = (e) =>{
        setCode(e.target.value)
    }

    const phoneChange = (e) =>{
        setPhone(e.target.value)
    }

    const passwordChange = (e) =>{
        setPassword(e.target.value)
    }

    const inviteCodeChange = (e) =>{
        setInviteCode(e.target.value)
    }


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openSec, setOpenSec] = React.useState(false);
    const handleOpenSec = () => setOpenSec(true);
    const handleCloseSec = () => setOpenSec(false);

    return (
    	<Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="手机号"
                    name="phone"
                    autoComplete="phone"
                    value={phone}
                    onChange={phoneChange}
                    autoFocus
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextField
                    margin="normal"
                    required
                    name="code"
                    label="验证码"
                    id="code"
                    value={code}
                    onChange={codeChange}
                    sx={{width:'60%'}}
                    />
                    
                    <Button size="large" variant={sendStatus?'disabled':'text'} id="sendCode" onClick={sendCode} sx={{
                    mt:3,
                    ml:2
                    }}>
                    {sendCodeText}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    name="password"
                    label="密码"
                    type="password"
                    id="password"
                    onChange={passwordChange}
                    autoComplete="password"
                    helperText="8-16位由大小写字母及数字组成"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    fullWidth
                    name="inviteCode"
                    label="邀请码"
                    id="inviteCode"
                    onChange={inviteCodeChange}
                    autoComplete="inviteCode"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{fontSize:'12px'}}>
                    <Checkbox value="allowExtraEmails" onChange={handleAgree} color="primary" />
                    我已阅读并同意<Typography variant='body2' onClick={handleOpen}  sx={{display:'inline'}}>《用户协议》</Typography>
                    和
                    <Typography variant='body2' onClick={handleOpenSec} sx={{display:'inline'}}>《隐私协议》</Typography>
                    </Box>
                </Grid>
            
            </Grid>
            <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                disabled={agreeStatus?false:true}
                sx={{ mt: 1, mb: 2 }}
            >
                立即注册
            </Button>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Typography variant="body2" onClick={(e)=>handleHref("/signin")}>
                        已注册账号？登录
                    </Typography>
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h4">
                    用户协议
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    ...
                </Typography>
                </Box>
            </Modal>

            <Modal
                open={openSec}
                onClose={handleCloseSec}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h4">
                    隐私协议
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    ...
                </Typography>
                </Box>
            </Modal>

        </Box>
    );
}

//Step1 设置昵称头像
function Step1(props) {

    const [nickname,setNickname]=React.useState("");
    const [ImgData,setImgData] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [changeStatus,setChangeStatus] = React.useState(false);
    const [DefaultList,setDefaultList] = React.useState([]);
    const [SelectedId,setSelectedId] = React.useState(0);

    const handleSubmit = (event) => {
        var nicknameReg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,14}$/
        if (nicknameReg.test(nickname)!=true){
            enqueueSnackbar("昵称格式不正确",{variant:"warning"});
        } else {
            apiUserSignupStep2({id:SelectedId,nickname:nickname}).then(res=>{
                if (res.code == 200){
                    enqueueSnackbar("设置成功",{variant:"success"});
                    props.handleNext();
                } else {
                    enqueueSnackbar(res.msg,{variant:"error"});
                }
            });
        }
    }

    React.useEffect(()=>{
        if (props.userInfo.nickname != "" && props.userInfo.nickname != undefined){
            setNickname(props.userInfo.nickname);
        }
        if (props.userInfo.avatar != "" && props.userInfo.avatar != undefined){
            setImgData(props.userInfo.avatar);
        }
        apiUserGetAvatarList({}).then(res=>{
			if (res.code == 200){
				setDefaultList(res.data.defaultList);
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
	},[]);


    const nicknameChange = (e) =>{
        setNickname(e.target.value);
        setChangeStatus(true);
    }

    const defaultClick = (id,avatar) => {
		setChangeStatus(true);
		setImgData(avatar);
		setSelectedId(id);
	}

    

    return (
    	<Box sx={{mt:3}}>
            <Box sx={{textAlign:'center',pb:3}}>
                <Avatar src={ImgData} sx={{margin:'0 auto',width:100,height:100}}></Avatar>
            </Box>
            <Typography variant="subtitle2">从默认头像中选择</Typography>
            <Grid container spacing={2} sx={{p:2,mb:2,textAlign:'center'}}>
                { DefaultList.map( (defaultItem, i) => 
                <Grid key={i} item xs={3}>
                    <Avatar onClick={(e)=>defaultClick(defaultItem.id,defaultItem.avatar)} src={defaultItem.avatar} sx={{margin:'0 auto',height:50,width:50}}></Avatar>
                </Grid>
                )}
            </Grid>
            <TextField
            required
            fullWidth
            id="nickname"
            label="昵称"
            name="nickname"
            onChange={nicknameChange}
            autoComplete="nickname"
            value={nickname}
            helperText="长度小于10位且无特殊字符"
            />
            
            <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={changeStatus?false:true}
                sx={{ mt: 2 }}
                onClick={handleSubmit}
            >
                确认修改
            </Button>
            <Button
                fullWidth
                variant="text"
                onClick={props.handleSkip}
            >
                以后
            </Button>
    	</Box>
    );
}

//Step2 实名认证
function Step2(props) {

    const [phone,setPhone] = React.useState("");
    const [realname,setRealname] = React.useState("");
    const [idcard,setIdcard] = React.useState("");
    const history = useHistory();

    React.useEffect(()=>{
        if (props.userInfo.phone != "" && props.userInfo.phone != undefined){
            setPhone(props.userInfo.phone);
        }
	},[]);

    const handleSubmit = (event) => {
        console.log(phone);
    }

    const realnameChange = (e) =>{
        setRealname(e.target.value)
    }

    const idcardChange = (e) =>{
        setIdcard(e.target.value)
    }

    const finishSignup = () => {
        history.push("/personal");
    }
    
    
    return (
    	<Box sx={{mt:3}}>

            <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="手机号"
                name="phone"
                autoComplete="phone"
                value={phone}
                disabled={true}
            />

            <TextField
                margin="normal"
                required
                fullWidth
                id="realname"
                label="真实姓名"
                name="realname"
                autoComplete="realname"
                onChange={realnameChange}
                value={realname}
                autoFocus
            />

            <TextField
                margin="normal"
                required
                fullWidth
                id="idcard"
                label="身份证号码"
                name="idcard"
                autoComplete="idcard"
                onChange={idcardChange}
                value={idcard}
            />  
            
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2 }}
                onClick={finishSignup}
            >
                提交认证
            </Button>
            <Button
                fullWidth
                variant="text"
                onClick={finishSignup}
            >
                以后
            </Button>
    	</Box>
    );

}

export default function SignUp(props) {

    
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [userInfo,setUserInfo] = React.useState({});

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    return (
        <Box>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <Box sx={{ width: '100%',pt:2 }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                <Typography variant="caption"></Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                            })}
                        </Stepper>
                        {activeStep == 0 ? (
                            <React.Fragment>
                                <Step0 handleNext={handleNext} setUserInfo={setUserInfo}  />
                            </React.Fragment>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                        {activeStep == 1 ? (
                            <React.Fragment>
                                <Step1 handleNext={handleNext} handleSkip={handleSkip} userInfo={userInfo} />
                            </React.Fragment>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                        {activeStep == 2 ? (
                            <React.Fragment>
                                <Step2 userInfo={userInfo} />
                            </React.Fragment>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                        
                        
                    </Box>
                    
                </Box>
                
            </Container>
        </Box>
    );
}
