import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';

import { useHistory } from "react-router-dom";

import { apiArtistList,apiArtistFollow } from '../common/Api.js';
import { useSnackbar } from 'notistack';

//import ArtistsbarImage from './ArtistsbarImage.js';


const OrgText = styled(Box)(() => ({
  display:'inline',
  textDecoration:'underline',
  fontSize:'14px',
}));

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
	},
	gridList: {
		flexWrap: 'nowrap'
	},
	itemImg:{
		borderRadius:'20px'
	}
}));

export default function Artistlist(props){

	const [items,setItems] = React.useState([]);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	React.useEffect(()=>{
		apiArtistList({pageSize:props.pageSize}).then(res=>{
			if (res.code == 200) {
				for (var i = 0; i < res.data.length; i++) {
					res.data[i].url = "/space/"+res.data[i].uid;
					res.data[i].organ.url = "/spaceOrg/"+res.data[i].organ.uid;
				};
				
				setItems(res.data);
			}
		})
	},[]);

	const handleHref = (url) => {
		history.push(url);
	};

	const toggleFollow = (item,i) => e => {
		apiArtistFollow({uid:item.uid}).then(res=>{
			if (res.code == 200) {
				let followerCountUpdate = item.followerCount;
				if (res.data == 1){
					followerCountUpdate++;
				} else {
					followerCountUpdate--;
				}
				setItems(
					items.map((itemc,ic)=>{
						if (ic == i) {
							return { ...itemc, isFollowed:res.data, followerCount:followerCountUpdate};
						} else {
							return itemc;
						}
					})
				);
			} else {
				if (res.code == 401){
                    enqueueSnackbar("请先登录",{variant:"warning"});
                } else {
                    enqueueSnackbar(res.msg,{variant:"error"});
                }
			}
		});
	}

    return (
    	<Box>
    		<Container fixed>
    			<Box sx={{pt:2,pb:1}}>
					<Typography onClick={(e)=>handleHref('/artCircle')} variant="h6" sx={{fontWeight:'bold'}}>
						艺术圈<ArrowForwardIosIcon sx={{pt:1}}/>
					</Typography>
				</Box>
		    	<Box className={classes.root}>
		    		<ImageList className={classes.gridList} cols={10}>
    				{ items.map( (item, i) => 
    					<Box key={i} sx={{minWidth: '150px',minHeight:'200px',mr:1}}>
							<Avatar onClick={(e)=>handleHref(item.url)} src={item.avatar} sx={{
								margin:'auto',
								width:100,
								height:100,
							}}/ >
								<Typography variant="body2" component="div" sx={{my:0.5,fontWeight:'bold',textAlign:'center'}}>
									{item.nickname}<OrgText onClick={(e)=>handleHref(item.organ.url)}>@{item.organ.nickname}</OrgText>
								</Typography>
								<Typography variant="body2" component="div" sx={{my:0.5,fontWeight:'bold',textAlign:'center'}}>
									{item.followerCount}粉丝
									<Button onClick={toggleFollow(item,i)} variant={item.isFollowed==1?"outlined":"contained"} size="small" sx={{ml:2}}>{item.isFollowed==1?"已关注":"+关注"}</Button>
								</Typography>
							
						</Box>
    				)}
    				</ImageList>
    			</Box>
    		</Container>
    	</Box>
    );
}