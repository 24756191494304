import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import BadgeIcon from '@mui/icons-material/Badge';
import ChatIcon from '@mui/icons-material/Chat';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";

export default function Edit(){

	const [User,setUser] = React.useState({});
	const history = useHistory();

	React.useEffect(()=>{
		let userInfoStr = window.localStorage.getItem("userInfo");
		let userInfo = JSON.parse(userInfoStr);
		userInfo.simpleIntro = userInfo.introduction.substr(0,18)+'...';
		setUser(userInfo);
	},[]);

	const handleHref = (url) => {
		history.push(url);
	};

    return (
    	<Box>
    		<Container fixed>
    			<List
					sx={{pt:2}}
					subheader={<ListSubheader>编辑个人档案</ListSubheader>}
			    >
					<ListItem sx={{textAlign:'center'}}>
						<Box sx={{width:'100%',textAlign:'center',pb:3}}>
							<Avatar 
								onClick={(e)=>handleHref("/editAvatar")}
								src={User.avatar} 
								sx={{
									margin:'0 auto',
									width:'100px',
									height:'100px'
								}}>
							</Avatar>
						</Box>
						
					</ListItem>
					<Divider/>
					<ListItem onClick={(e)=>handleHref("/editNickname")}>
						<ListItemText id="switch-list-label-mode" primary="昵称" />
						<Typography variant="body1">
							{User.nickname}
						</Typography>
						<ArrowForwardIosIcon sx={{ml:0.5,mr:-1,fontSize:16,opacity:0.8}} />
					</ListItem>
					<Divider/>
					<ListItem onClick={(e)=>handleHref("/editIntroduction")}>
						<ListItemText id="switch-list-label-mode" primary="简介" />
						<Typography variant="body1">
							{User.simpleIntro}
						</Typography>
						<ArrowForwardIosIcon sx={{ml:0.5,mr:-1,fontSize:16,opacity:0.8}} />
					</ListItem>
					<Divider/>
					<ListItem>
						<ListItemText id="switch-list-label-mode" primary="手机号" />
						<Typography variant="body1">
							{User.phone}
						</Typography>
						<ArrowForwardIosIcon sx={{ml:0.5,mr:-1,fontSize:16,opacity:0.8}} />
					</ListItem>
					<Divider/>
					<ListItem>
						<ListItemText id="switch-list-label-mode" primary="实名认证" />
						<Typography variant="body1">
							未认证
						</Typography>
						<ArrowForwardIosIcon sx={{ml:0.5,mr:-1,fontSize:16,opacity:0.8}} />
					</ListItem>
                    <Divider/>
					<ListItem>
						<ListItemText id="switch-list-label-mode" primary="邀请码" />
						<Typography variant="body1">
							{User.inviteCode}
						</Typography>
						<ArrowForwardIosIcon sx={{ml:0.5,mr:-1,fontSize:16,opacity:0.8}} />
					</ListItem>
                    <Divider/>
					<ListItem>
						<ListItemText id="switch-list-label-mode" primary="我的地址" />
						<Typography variant="body1">
							待上链...
						</Typography>
						<ArrowForwardIosIcon sx={{ml:0.5,mr:-1,fontSize:16,opacity:0.8}} />
					</ListItem>
					{ User.role == 2?
					<Box>
						<Divider/>
						<ListItem onClick={(e)=>handleHref("/personalCareer")}>
							<ListItemText id="switch-list-label-mode" primary="艺术履历" />
							<Typography variant="body1">
								查看
							</Typography>
							<ArrowForwardIosIcon sx={{ml:0.5,mr:-1,fontSize:16,opacity:0.8}} />
						</ListItem>
					</Box>
					:<Box></Box>}
			    </List>
    		</Container>
    	</Box>
    );
}