import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { styled } from '@mui/material/styles';

import { useTheme } from '@mui/styles';





export default function Whatismadmeta(){

    const themee = useTheme();

    const Box1 = styled(Box)(({ theme }) => ({
        background:'linear-gradient(135deg, '+theme.palette.error.light+','+theme.palette.background.paper+' 65% )',
        padding:'24px',
        color:'text.main',
        textAlign:'left',
        borderRadius:'10px',
    }));

    const Box2 = styled(Box)(({ theme }) => ({
        background:'linear-gradient(225deg, '+theme.palette.warning.light+','+theme.palette.background.paper+' 65% )',
        padding:'24px',
        color:'text.main',
        textAlign:'left',
        borderRadius:'10px',
    }));

    const Box3 = styled(Box)(({ theme }) => ({
        background:'linear-gradient(225deg, '+theme.palette.background.paper+' 35%,'+theme.palette.success.light+' )',
        padding:'24px',
        color:'text.main',
        textAlign:'left',
        borderRadius:'10px',
    }));

    const Box4 = styled(Box)(({ theme }) => ({
        background:'linear-gradient(135deg, '+theme.palette.background.paper+' 35%,'+theme.palette.info.light+' )',
        padding:'24px',
        color:'text.main',
        textAlign:'left',
        borderRadius:'10px',
    }));

    return (
    	<Box sx={{
    		color:'text.main',
    		textAlign:'center',
    		pt:10,
    		pb:10,
    	}}>	
    		<Container>
	    		<Typography variant="h5" gutterBottom sx={{pb:2}}>
					MadMeta.art
				</Typography>
				<Typography variant="h4" gutterBottom>
                    头部数藏艺术社区
				</Typography>
				<Typography variant="subtitle1" gutterBottom sx={{maxWidth:'700px',margin:'0 auto'}}>
                    聚焦艺术教育产业，教育为起点，数藏为载体，扶持年轻艺术家，培养数字艺术领域的KOL。通过专属的认证机制管理艺术资产，助力艺术家的事业成长之路。
				</Typography>
				<Grid container spacing={3} sx={{pt:10}}>
	    			<Grid item xs={12} md={6}>
	    				<Box1>
	    					<Typography variant="h6" gutterBottom >
								认证品牌+创作者自营品牌
							</Typography>
							<Typography variant="body1" gutterBottom>
								MadMeta对于认证过的创作者给予认证标识，确保作品真实性和质量，后续逐步将认证的权利让渡给社区。
							</Typography>
                        </Box1>
	    			</Grid>
	    			<Grid item xs={12} md={6}>
	    				<Box2>
	    					<Typography variant="h6" gutterBottom >
								多种发行方式
							</Typography>
							<Typography variant="body1" gutterBottom>
								采用定价发行、盲盒发行、多形式拍卖等机制来确保不同范式的数字藏品都能够有合理的价格发现。
							</Typography>
                        </Box2>
	    			</Grid>
	    			<Grid item xs={12} md={6}>
	    				<Box3>
	    					<Typography variant="h6" gutterBottom >
								完整生态场景
							</Typography>
							<Typography variant="body1" gutterBottom>
								MadMeta将与优质IP合作，保证作品的高质量，同时为收藏爱好者提供交换渠道，最终构建创作者、消费者、经纪人三位一体的完整生态。
							</Typography>
	    				</Box3>
	    			</Grid>
	    			<Grid item xs={12} md={6}>
	    				<Box4>
	    					<Typography variant="h6" gutterBottom >
								生态赋能优势
							</Typography>
							<Typography variant="body1" gutterBottom>
								扩大数字藏品应用场景，为数字藏品产品持续赋能，最终形成音乐、体育、动漫、艺术品、虚拟土地、域名等多领域协同发展生态
							</Typography>
	    				</Box4>
	    			</Grid>
	    		</Grid>
	    	</Container>
    	</Box>
    );
}