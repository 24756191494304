import React, { useContext } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import SmileySans from './fonts/SmileySans-Oblique.otf.woff2';
import Box from '@mui/material/Box';
import { ossUrl } from "./common/RequestUrl";

const CustomThemeContext = React.createContext();



export function CustomThemeProvider({ children }) {
	const themeModelocal = window.localStorage.getItem('theme_mode')?window.localStorage.getItem('theme_mode'):'dark';
	const themePrimarylocal = window.localStorage.getItem('theme_primary')?window.localStorage.getItem('theme_primary'):'#f8d90f';
	const themelogo = themeModelocal == 'dark'?ossUrl+'/public/logodark.png':ossUrl+'/public/logo.png';
	const [themeMode,setThemeMode] = React.useState(themeModelocal);
	const [themePrimary,setThemePrimary] = React.useState(themePrimarylocal);
	const [logoAddress,setLogoAddress] = React.useState(themelogo);

	function toggleMode(mode) {
		setThemeMode(mode);
		if (mode == 'dark'){
			setLogoAddress(ossUrl+'/public/logodark.png');
		} else {
			setLogoAddress(ossUrl+'/public/logo.png');
		}
	}

	function togglePrimary(primary) {
		setThemePrimary(primary);
	}

	const theme = React.useMemo(
		() => {
			return createTheme(
				{
					palette: {
						mode:themeMode,
						primary: {
							main: themePrimary,
						},
						secondary: {
							main: themeMode=='light'?'#000':'#FFF',
						},
						divider: themeMode=='light'?'rgba(0,0,0,0.12)':'rgba(255,255,255,0.12)',
						text: {
							main: themeMode=='light'?'rgba(0,0,0,1)':'rgba(255,255,255,1)',
							secondary: themeMode=='light'?'rgba(0,0,0,0.88)':'rgba(255,255,255,0.88)',
							disabled: themeMode=='light'?'rgba(0,0,0,0.55)':'rgba(255,255,255,0.55)',
							hint: themeMode=='light'?'rgba(0,0,0,0.01)':'rgba(255,255,255,0.01)',
						},
					},
					typography: {
						fontFamily: 'SmileySans',
					},
					components: {
						MuiCssBaseline: {
						  styleOverrides: `
							@font-face {
							  font-family: 'SmileySans';
							  src: "url(${SmileySans}) format('woff2')";
							}
						  `,
						},
					}
				}
			);
		},[themeMode,themePrimary],
	);


	return (
		<CustomThemeContext.Provider value={{toggleMode,togglePrimary,themeMode,themePrimary,logoAddress}}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Box sx={{fontFamily: 'SmileySans'}}>
					{children}
				</Box>
			</ThemeProvider>
		</CustomThemeContext.Provider>
	);
}

export function useToggleTheme() {
  const context = useContext(CustomThemeContext);
  if (context === undefined) {
    throw new Error("useCustomThemeContext must be used within an CustomThemeProvider");
  }
  return context;
}