import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Input from '@mui/material/Input';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CssBaseline from '@mui/material/CssBaseline';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import { apiPostPublish ,apiPeanutAdd} from '../common/Api.js';


import AppbarCrumbs from '../components/AppbarCrumbs';

import Zmage from 'react-zmage';

import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
  
	
	imgClass:{
		borderRadius:'10px',
		width:'112px',
		objectFit:'cover',
		marginRight:'10px',
		marginBottom:'10px'
	},
	modalClass:{
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
		background: theme.palette.background.default,
		color:theme.palette.text.main,
		border: '2px solid',
		borderRadius:'10px',
		borderColor:theme.palette.text.main,
		padding:'10px'
	},
	chipClass:{
	    marginRight:'10px',
	    marginBottom:'10px'
	},

}));

let formData = new FormData();
  
export default function Post(){
	const classes = useStyles();
	const [ Title,setTitle ] = React.useState("");
	const [ Content,setContent ] = React.useState("");
	const [ ImgCount,setImgCount ] = React.useState(0);
	const [ ImgList,setImgList ] = React.useState([]);
	const { enqueueSnackbar } = useSnackbar();
	

	//tagmodal
	const [open, setOpen] = React.useState(false);
	const [Tags, setTags] = React.useState([]);
	const [Tag, setTag] = React.useState("");
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	//userInfo
	const [Role,setRole] = React.useState(1);

	React.useEffect(()=>{
		let userInfoStr = window.localStorage.getItem("userInfo");
		let userInfo = JSON.parse(userInfoStr);
		if (userInfo != null || userInfo != undefined) {
			setRole(userInfo.role);
		}
	},[]);

	let upChange = (event) => {
		let upimgs = document.getElementById("upimg");
		if (ImgCount >= 9) {
			enqueueSnackbar('上传图片不可多于9张',{variant:"error"});
			upimgs.value='';
			return false;
		}
        let imgfile = event.currentTarget.files[0];
		formData.append("files",imgfile);
		for (var value of formData.values()) {
			console.log(value);
		}
        if (imgfile.size > 5096000) {
           	enqueueSnackbar('图片必须小于5M',{variant:"error"});
            upimgs.value='';
            return false;
        }
        else {
        	setImgCount(ImgCount+1);
            let reader = new FileReader();
            reader.readAsDataURL(imgfile);

            reader.onload = function (event) {
                let imgs = this.result
                setImgList(current => [...current, imgs]);
            };
        }
    }

    const titleChange = (e) =>{
		setTitle(e.target.value);
	}

	const contentChange = (e) =>{
		setContent(e.target.value);
	}

    const addTag = () => {
    	if (Tags.length >= 10){
    		enqueueSnackbar('请勿添加太多话题',{variant:"warning"});
    		return false;
    	}
    	for (var i = 0; i < Tags.length; i++) {
    		if (Tags[i] == Tag){
    			enqueueSnackbar('请勿添加重复的话题',{variant:"warning"});
    			return false;
    		}
    	};
    	setTags(current => [...current, Tag]);
    	setTag("");
    	setOpen(false);
    }

    const tagChange = (e) =>{
		setTag(e.target.value);
	}

	const changeTag = (v) => {
		setTag(v);
	}

	let handlePost = () => {
		if (Title == "") {
			enqueueSnackbar('请先填写标题～',{variant:"warning"});
    		return false;
		}
		if (Content == "") {
			enqueueSnackbar('请先填写正文内容～',{variant:"warning"});
    		return false;
		}
		formData.append("title",Title);
		formData.append("content",Content);
		formData.append("tags",Tags);
		for (var value of formData.values()) {
			console.log(value);
		}
		apiPostPublish(formData).then(res=>{
			if (res.code == 200) {
				enqueueSnackbar("更新成功",{variant:"success"});
				//每日动态 更新record
				apiPeanutAdd({typeId:3}).then(resdata=>{
					if (resdata.code == 200) {
						//......
					}
				})
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
	}

	return(
		<Box>
			<CssBaseline/>
			<AppbarCrumbs/>
			<Container fixed>
				<Box sx={{mt:2.5}} color="background">
					<Box sx={{display:'flex',justifyContent:'flex-start',mb:1,flexWrap:'wrap',flexDirection:'row'}}>
					{ ImgList.map( (ImgSingle, i) => 
						
						<img key={i} src={ImgSingle} alt="cat" className={classes.imgClass} />
					)}
				
				 		<IconButton
				            aria-label="open drawer"
				            edge="start"
							component="label"
				            sx={{ borderRadius:'10px',border:'1px solid',width:'112px',height:'112px', marginLeft:'0px',padding:'1.6em',marginBottom:'10px',marginRight:'10px',}}
				          >
							<input id="upimg" hidden accept="image/*" multiple type="file" onChange={upChange} />
				            <AddOutlinedIcon/>
				        </IconButton>	
					</Box>	
					<Input placeholder="填写标题会有更多赞哦～"
						value={Title}
						onChange={titleChange}
						sx={{width:'100%',
							fontSize:'20px!important',
							padding:'10px 0',
						}}/>
					<Input placeholder="添加正文"  
						multiline
						rows={6}
						value={Content}
						onChange={contentChange}
						sx={{
							width:'100%',
							fontSize:'16px!important',
							padding:'10px 0',
						}}/>
					<Box sx={{marginTop:'10px',paddingBottom:'16px'}}>
						<Box>
							{ Tags.map( (Tag, i) => 
								<Chip key={i} label={"# "+Tag} className={classes.chipClass}/>
							)}
							
						</Box>
						
						<Button color="text" sx={{marginRight:'10px'}} onClick={handleOpen}># 话题</Button>

					</Box>
						
				</Box>
			</Container>
			<AppBar position="fixed" sx={{ top: 'auto', bottom: 0,height:'5em',justifyContent:'center' }} color="background">
			  	<Toolbar>
					<Button size="large" 
						color="primary"
						variant={Role == 1?"disabled":"contained"}
						sx={{width:'100%',fontSize:'20px'}}
						onClick={handlePost}
					>
						{Role == 1?"成为艺术家才可发布动态":"发布动态"}
					</Button>
			  	</Toolbar>
		  	</AppBar>

		  	<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className={classes.modalClass}>
					<Input 
						placeholder="在此处输入话题"
						endAdornment={
							<CheckIcon onClick={addTag} />
						}
						onChange={tagChange}
						value={Tag}
						sx={{width:'100%',
							fontSize:'20px!important',
							padding:'10px 0',
							mb:1,
						}}/>
					<Typography variant="subtitle" components="div">
						热门话题
					</Typography>
					<List>
						<ListItem>
							<ListItemText primary="波谱艺术" onClick={(e)=>changeTag("波谱艺术")} />
						</ListItem>
						<ListItem>
							<ListItemText primary="艺术源于生活" onClick={(e)=>changeTag("艺术源于生活")} />
						</ListItem>
						<ListItem>
							<ListItemText primary="虚拟偶像" onClick={(e)=>changeTag("虚拟偶像")} />
						</ListItem>
						<ListItem>
							<ListItemText primary="潮玩" onClick={(e)=>changeTag("潮玩")} />
						</ListItem>
					</List>
				</Box>
			</Modal>

		</Box>
		
	);
}
