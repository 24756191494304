import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { Box, Button, CardActionArea, CardActions, Container,Grid } from '@mui/material';

const imgItem = [
{id:1,src:"ape1.png",name:'3D Ape #1190'},
{id:2,src:"ape2.png",name:'3D Ape #2856'},
{id:3,src:"ape3.png",name:'3D Ape #7960'},
{id:4,src:"cat1.png",name:'cool cat'},
{id:5,src:"women.png",name:'doodles #20'},
{id:6,src:"man.png",name:'doodles #99'},
{id:7,src:"fff (1).png",name:'Chimera #977'},
{id:8,src:"fff (2).png",name:'cool cat #1522'},
{id:9,src:"fff (3).png",name:'cool cat #1922'},
{id:10,src:"fff (4).png",name:'Chimera #979'}];

export default function MultiActionAreaCard() {
  return (
      <Box>
          <Grid container spacing={2} >
            {imgItem.map((item, index) => (
                <Grid item xs={12} md={4}  key={index}>
                    <Card sx={{ maxWidth: 350 }}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="200"
                            image={item.src}
                            alt="3D APE"    
                            />
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {item.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                NFT简介 艺术家 等等等
                                
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary">
                            Share
                            </Button>
                        </CardActions>
                </Card>
               </Grid>
            ))}
            </Grid>
    </Box>
  );
}
