import * as React from 'react';
import Box from '@mui/material/Box';

import Appbar from '../components/Appbar';
import ShowEmpty from '../components/ShowEmpty';
import Navigation from '../components/Navigation';

export default function Page404(){

    return (
        <Box>
            <Appbar />
            <ShowEmpty desc="404 Not Found" py="200" />
            <Navigation />
        </Box>
    );
}