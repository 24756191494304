import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';


import { withRouter } from "react-router-dom";

import {apiCollectionList,apiCollectionFollow} from '../common/Api.js';
import { useSnackbar } from 'notistack';

import ShowEmpty from './ShowEmpty.js';

function SpaceSalon(props){

	const [items,setItems] = React.useState([]);
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(()=>{
		apiCollectionList({pageSize:props.pageSize,uid:props.uid}).then(res=>{
			if (res.code == 200) {
                if(res.data != null){
                    for (var i = 0; i < res.data.length; i++) {
                        res.data[i].url = "/collection/"+res.data[i].uid;
                        res.data[i].artist.url = "/space/"+res.data[i].artist.uid;
                    };
                    setItems(res.data);
                }
			}
		});
	},[]);


	const handleHref = (url) => {
        console.log(url);
        props.history.push(url);
	};

    return (
    	<Box sx={{my:1}}>
            {items.length==0?
            <ShowEmpty/>
            :
    		<Box>
	    		<Grid container spacing={3} >
	    			{ items.map((item, i) => (
	    			<Grid item xs={12} md={4} key={i}>
		    				<Card sx={{ maxWidth: '100%',position:'relative' }} >
								<CardMedia
									component="img"
									height="300"
									image={item.cover}
									alt="green iguana"
									onClick={(e)=>handleHref(item.url)}
								/>
								
								<CardContent sx={{pb:0,pt:3}} onClick={(e)=>handleHref(item.url)} >
		                            <Typography gutterBottom variant="h5" component="div"sx={{fontWeight:'bold'}} >
		                                {item.collectionName}
		                            </Typography>
	                            </CardContent>
	                            
						    </Card>
	    			</Grid>
	    			))}
	    		</Grid>
            </Box>
            }
	    </Box>
    );
}

export default withRouter(SpaceSalon);
