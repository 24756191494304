import * as React from 'react';
import Box from '@mui/material/Box';

import Appbar from '../components/Appbar';
import Coldetail from '../components/Coldetail';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';

export default function Collection(){

    return (
    	<Box>
    		<Appbar/ >
    		<Coldetail/ >
    		<Navigation value="collections"/ >
    		<Footer/ >
    	</Box>
    );
}