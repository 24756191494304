import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {  Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import MarketItem from '../components/MarketItem';
import MyPostM from './MyPostM';
import MyPost from './MyPost';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
function RightSidebar(props){
  return (
      <Box sx={{
        padding:'0 12px',
        '& > :not(style)': {
          m: 1,
        },
        display:{xs:'none',md:'block'},
      }}>
        <Paper elevation={3} >
                  
              <Box component="div"  sx={{minWidth: 275,display:'flex',marginBottom:'15px'}}>
                <Box component="a" sx={{padding:'10px 0', flex:'1 1',textAlign:'center'}}>
                  <Box component="span"sx={{ fontSize: 14,color:"#8590a6" }}>
                    关注了 
                  </Box>
                  <Typography component="strong" sx={{fontWeight:600,lineHeight:'1.6', fontSize: 18,color:"#646464" }} >
                    12 
                  </Typography>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem/>

                <Box component="a" sx={{padding:'10px 0', flex:'1 1',textAlign:'center'}}>
                  <Box component="span" sx={{ fontSize: 14,color:"#8590a6" }}>
                    关注者 
                  </Box>
                  <Typography component="strong" sx={{fontWeight:600,lineHeight:'1.6', fontSize: 18,color:"#646464" }}>
                    34,735 
                  </Typography>
                </Box>
              
              </Box>
          </Paper>   

          <Paper elevation={3}>
            <Card sx={{ minWidth: 275 }}>
             
              <Typography sx={{ fontSize: 15 , color:'#646464',fontWeight:'600',padding:'18px',marginBottom:'0px'}} gutterBottom>
              个人成就
                </Typography>
              <Divider sx={{borderColor:'#f6f6f6'}}></Divider>

              <CardContent>
                <Box sx={{p:1}}>
                <FavoriteIcon sx={{verticalAlign:'middle',color:"#646464",marginRight:'8px'}} ></FavoriteIcon>
                <Typography variant="span" sx={{ fontSize: 14,color:"#646464" }} color="text.secondary" gutterBottom>
                  获得 93,875,932 次喜欢
                </Typography>
                </Box>
                
                <Box sx={{p:1}}>
                <StarIcon sx={{verticalAlign:'middle',color:"#646464",marginRight:'8px',}} ></StarIcon>
                <Typography variant="span" sx={{ fontSize: 14,color:"#646464" }} color="text.secondary" gutterBottom>
                  获得 7,229 次收藏
                </Typography>
                </Box>
               
                <Box sx={{p:1}}>
                <CollectionsBookmarkIcon sx={{verticalAlign:'middle',color:"#646464",marginRight:'8px',}} ></CollectionsBookmarkIcon>
                <Typography variant="span" sx={{ fontSize: 14,color:"#646464" }} color="text.secondary" gutterBottom>
                  上架艺术品 9 幅
                </Typography>
                </Box>
              </CardContent>
              <CardActions>
              </CardActions>
            </Card>
          </Paper>        

      </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box sx={{ p: 0 }}>
          <Box sx={{padding:'16px 0'}}>{children}</Box>
        // </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const postTabTheme = createTheme({
  components: {
    MuiTabPanel: {
      styleOverrides: {
        root: {
          // padding:'16px 0',  
          color:'#ffffff',   
          
        },  
        children:{
          styleOverrides:{color:'#ffffff',}
        },
      }
    }
  }
});



export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const postTabThemeClass = postTabTheme();
  return (
    <Box sx={{ width: '100%' ,padding:'0px 5px',marginTop:'0px',}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          <Tab label="艺术履历" {...a11yProps(0)} />
          <Tab label="动态" {...a11yProps(1)} />
          <Tab label="画廊" {...a11yProps(2)} />

        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{display:{xs:'block',md:'flex'}}}>

          <List sx={{ width:{xs:'100%',md:'70%'}, padding:{xs:'0',md:'0 20px'},  bgcolor: 'background.paper' }}>
              <Typography  sx={{ display: 'block',fontWeight:700,fontSize:'18px',color:'#000',paddingTop:'10px',paddingBottom:'10px' }}>
                教育背景
              </Typography>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="xyavatar.jpeg" />
                </ListItemAvatar>
                <ListItemText
                  primary="小燕画院" secondary=""
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        小燕画院-学生
                      </Typography>
                      {" — 作品类型涵盖电影动画、多媒体艺术、装置艺术、视觉艺术、建筑设计、平面设计、工业设计、产品设计等多元化内容。"}
                      <Typography
                        sx={{ display: 'block' }}
                        component="span"
                        variant="body2"
                        fontSize='12px'
                        color='#afa9a9'
                        paddingTop='5px'
                      >
                        Jan 1, 2020 - Jan 1, 2022 · 2年
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" sx={{marginLeft:'70px'}} />
              <Typography  sx={{ display: 'block',fontWeight:700,fontSize:'18px',color:'#000',paddingTop:'15px',paddingBottom:'10px' }}>
                实践经历
              </Typography>
              <ListItem alignItems="flex-start">
                <ListItemAvatar sx={{objectFit:'scale-down'}}>
                  <Avatar alt="Travis Howard" src="logo_cartoon.png" />
                </ListItemAvatar>
                <ListItemText
                  primary="萃 | MADMETA" 
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        签约艺术家
                      </Typography>
                      {" — 以满足、好奇甚至是玩乐的态度表现了光怪陆离的现代商品社会。"}
                      <Typography
                        sx={{ display: 'block' }}
                        component="span"
                        variant="body2"
                        fontSize='12px'
                        color='#afa9a9'
                        paddingTop='5px'
                      >
                        Jun 20, 2022 - 至今 · 一个月
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" sx={{marginLeft:'70px'}}/>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Cindy Baker" src="opensea.svg" />
                </ListItemAvatar>
                <ListItemText
                  primary="OpenSea"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        NFT艺术家
                      </Typography>
                      {' — 当艺术赋予科技灵感：'}
                      <Typography
                        sx={{ display: 'block' }}
                        component="span"
                        variant="body2"
                        fontSize='12px'
                        color='#afa9a9'
                        paddingTop='5px'
                      >
                        Jun 20, 2022 - 至今 · 一个月
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" sx={{marginLeft:'70px'}}/>

              <ListItem alignItems="flex-start"sx={{textAlign:'center',display:'block'}}>
                <Button variant="text">查看更多</Button>
              </ListItem>
              

          </List>

          <RightSidebar></RightSidebar>
        </Box>
      </TabPanel>
      
        <TabPanel value={value} index={1} >
          <Box sx={{display:{xs:'flex',md:'none'}}}>
            <MyPostM/ >
          </Box>

          <Box sx={{display:{xs:'none',md:'flex'}}}>
            <MyPost/ >
            <RightSidebar/ >
          </Box>

        </TabPanel>    
      

        <TabPanel value={value} index={2}>
        
        <Box sx={{textAlign:'center',marginBottom:'30px',padding:'30px 0px 20px 0px'}} >
          <CardMedia
                            component="img"
                            width='42'
                            height="auto"
                            image="fire.png"
                            alt="最热门～"  
                            sx={{borderRadius:'20px',width:'42px',marginRight: 'auto',
                            marginleft: 'auto',display:'inline',marginBottom:'10px',
                            position: 'relative',textAlign:'center',}}  
                            />    
                            <Box sx={{color:'#25484e',marginBottom:'10px',fontSize:'20px',fontWeight:'700',lineHeight:'1.4em',letterSpacing:'1px',}}>热门作品</Box>              
                            <Box sx={{color:'#000000',fontSize:'43px',fontWeight:'600',lineHeight:'1.3em',letterSpacing:'0px'}}>探索更多Paul Rojers精彩的艺术品</Box>              
          </Box>
      <MarketItem/ >
      </TabPanel>
    </Box>
  );
}
