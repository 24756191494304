import * as React from 'react';
import Carousel from 'react-material-ui-carousel'

import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme,styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';

import { Box, Button, CardActionArea, CardActions, Container,Grid, Icon } from '@mui/material';
const postCardTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow:'0px 0px 1px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
          
        },
      }
    }
  }
});
const Mycards = styled(Card)({
  boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
  paddingBottom:'10px'
});

function ImageCarousel(props){

	const items = [
	  {
	    img: 'fff (1).png',
	  },
	  {
	    img: 'fff (2).png',
	  },
	  {
	    img: 'fff (3).png',
	  },
	];


    return (
        <Carousel 
        autoPlay={false} 
        swipe={true} 
        indicators={true} 
        indicatorContainerProps={{
          style: {
            zIndex: 1,
            // marginTop: "-30px",
            position: "relative"
          }
        }}>
            {
                items.map( (item, i) => <ImgItem key={i} item={item} /> )
            }
        </Carousel>
    )
}
function ImgItem(props)
{
    return (

    		<Card sx={{ maxWidth: '100%',borderRadius:0}}>
		      <CardMedia
          sx={{
            maxHeight:"270px"
          }}
		        component="img"
		        image={props.item.img}
		        alt="Paella dish"
		      />
		    </Card>
    )
}

function Media(props) {
    const { loading = false } = props;
    const textinfo = "草帽海贼团悬赏公布，索隆等三人达到10亿贝利之后是草帽海贼团这边，他们的悬赏令也公布了，其中作为草帽海贼团现在三大战力，也就是相当于“三灾”的索隆、山治和甚平三人的悬赏都超过了10亿，这也让草帽海贼团，真正拥有了自称四皇的底气。  三人之中，索隆的悬赏最高，为11亿1100贝利，其次是甚平，因为是前七武海的原因，所以甚平的悬赏也不低，直接悬赏11亿贝利。最后是山治，他的悬赏为10亿3200万贝利，相较于索隆要少一些，估计山治在看到自己和索隆的差距的时候，会很不高兴吧！      之后是草帽海贼团之中，最受五老星重视的考古学家妮可·罗宾，被悬赏9亿3000万贝利，以罗宾的实力，是不可能有这么高的悬赏的，对此只能说罗宾因为身份的原因，悬赏被大大的提高了。剩下的乌索普悬赏5亿贝利，不愧是大神之名，和之国没怎么战胜对手，悬赏却没少增加。接下来弗兰奇3亿9400贝利，布鲁克3亿8300万贝利，娜美3亿66万贝利，这几位的悬赏，几乎都是直接增加了3亿。     最后则是可怜的乔巴了，这次的乔巴可以说是几人之中提升最多的，毕竟乔巴的悬赏直接一下子提升了10倍，从原来的100贝利，提升到现在的1000贝利，不过小爱推测，乔巴在看到悬赏令的时候是哭着看完的吧！";
    const [readMore,setReadMore] = React.useState(false);
    const [like,setLike] = React.useState(false);
   
    return (
      <Mycards sx={{ maxWidth: 365, m: 0,marginBottom:'10px' }}>
        <CardHeader
          sx={{padding:'8px',}}
          avatar={
            loading ? (
              <Skeleton
                animation="wave"
                variant="circular"
                width={42}
                height={42}
              />
            ) : (
              <Avatar
                alt="Ted talk"
                src="../avatar.jpeg"
              />
            )
          }
          action={
            loading ? null : (
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            )
          }
          title={
            loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            ) : (
                <Typography sx={{fontSize:'1.1rem'}}>Ted</Typography>
            )
          }
          subheader={
            loading ? (
              <Skeleton animation="wave" height={10} width="40%" />
            ) : (
              "5 hours ago"
            )
          }
        />
        {loading ? (
          <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
        ) : (
          // <CardMedia 
          <ImageCarousel 
          
            component="img"
            height="auto"
            alt="Nicola Sturgeon on a TED talk stage"
          />

        )}
  
        <CardContent sx={{padding:'8px 6px',top: '-47px',position: 'relative',display: 'inline',zIndex:10,}}>
          {loading ? (
            <React.Fragment>
              <Skeleton
                animation="wave"
                height={10}
                style={{ marginBottom: 6,marginTop:'40px', }}
              />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
          ) : (
              <Box >
                <Box sx={{paddingBottom:'4px',}}>
                  <Box sx={{padding:'0 10px',display:'inline-block',}} onClick={() => setLike(!like)}>
                  {like ? <FavoriteIcon sx={{verticalAlign:'middle'}} color="error" ></FavoriteIcon> :<FavoriteBorderIcon sx={{verticalAlign:'middle'}}  ></FavoriteBorderIcon>}
                  </Box>
                  <Box  onClick={()=>alert('未开放')}
                   sx={{padding:'0 10px',display:'inline-block',verticalAlign:'middle'}}>
                  <TextsmsOutlinedIcon sx={{verticalAlign:'middle'}}></TextsmsOutlinedIcon>
                  </Box>
                </Box>
                <Typography variant="h6" display="block" gutterBottom sx={{fontSize:'1rem',fontWeight:700}}>
                  93,875次赞
                </Typography>
                <Typography variant="body1" color="text.secondary" component="p" sx={{
                // overflow:'hidden',
                // textOverflow:'ellipsis',
                // whiteSpace:'normal',
                // display:'-webkit-box',
                // WebkitLineClamp:3,
                // WebkitBoxOrient:'vertical',
                // fontSize:'0.93rem',
                // lineHeight:'1.43',
                }}>
                {       
                    readMore ? textinfo :textinfo.substring(0,50)
                }
                    <Typography 
                    variant="button" display="inline-block" gutterBottom sx={{color:'#175199',fontSize:'1rem'}}
                    onClick={() => setReadMore(!readMore)}>
                    { readMore ? '收起' : '...查看全文' }               
                    { readMore ? <ExpandLessIcon sx={{verticalAlign:'middle'}}></ExpandLessIcon> : <ExpandMoreIcon sx={{verticalAlign:'middle'}}></ExpandMoreIcon>}               
                    </Typography>
                </Typography>

                <Typography sx={{color:'#969393',fontSize:'0.93rem'}} variant="body2" display="block" gutterBottom>
                  共1,101条评论
                </Typography>
              </Box>
            
          )}
        </CardContent>
        <Divider variant="middle" sx={{marginTop:'-47px'}} />

      </Mycards>

    );
  }
  
export default function MyPostM() {

    return (
        <Box sx={{padding:0}}>
            <Media /> 
            <Media /> 
            <Media /> 
            <Media loading />
            <Divider>到底了</Divider>

        </Box>
    );
}