import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import Appbar from '../components/Appbar';
import { makeStyles } from '@mui/styles';
import { padding } from '@mui/system';

import CountDown from '../components/CountDown';
import Navigation from '../components/Navigation';
import { apiCollectionDrawList,apiCollectionDrawRecord } from '../common/Api';
import { useSnackbar } from 'notistack';
import utils from '../common/utils'
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  
  orangeButton:{
    backgroundColor:'#fa732194!important',
    color:'#fff!important',
    borderRadius:'10px!important',
    width:'40%',
  },
  deepOrangeButton:{
    backgroundColor:'#ef8659bd!important',
    color:'#fff!important',
    borderRadius:'10px!important',
    width:'40%',
  },
  deepRedButton:{
    backgroundColor:'#e9366447!important',
    color:'#fff!important',
    borderRadius:'10px!important',
    width:'40%',
  },
  defaultButton:{
    backgroundColor:'#757575!important',
    color:'#fff!important',
    borderRadius:'10px!important',
    width:'40%',
  },
  grayText:{
    color:'#9e9e9e',
  },
  textOverflowHidden:{
    whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'
  },
  boldText:{
    fontWeight:'bold',
  },
  paddingFiveUpDown:{
    padding:'5px 0',
  },
  marginLeftFivePx:{
    marginLeft:'6px!important'
  },
  backgroudTheme:{
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  //   background:'linear-gradient(to left top,rgb(255,87,88,35%) 0%,rgba(253,121,60,45%) 20%,rgba(236, 232, 122,43%) 40%, rgba(137, 202, 105, 37%) 60% ,rgba(45, 194, 237, 37%) 90%)',
    background: theme.palette.mode === 'dark' ? 'linear-gradient(to right bottom, rgb(145 138 138 / 35%) 0%, rgb(178 173 171 / 45%) 20%, rgb(98 98 92 / 35%) 40%, rgb(77 79 75 / 37%) 60%, rgb(85 88 89 / 37%) 90%)' :'linear-gradient(to right bottom,rgb(255,87,88,35%) 0%,rgba(253,121,60,45%) 20%,rgba(183, 178, 43,35%) 40%, rgba(137, 202, 105, 37%) 60% ,rgba(45, 194, 237, 37%) 90%)',
    transition: 'all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s',
    minHeight:'100%',
  }
}));


const Widget = styled('div')(({ theme }) => ({
  padding: 16,
  borderRadius: 16,
  width: 343,
  maxWidth: '100%',
  margin: 'auto',
  position: 'relative',
  zIndex: 1,
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.4)',
  backdropFilter: 'blur(40px)',
}));

const CoverImage = styled('div')({
  width: 110,
  height: 'auto',
  objectFit: 'cover',
  overflow: 'hidden',
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: 'rgba(0,0,0,0.08)',
  '& > img': {
    width: '100%',
  },
});

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const FlexCenter = styled(Typography)({
  display: 'flex',
  justifyContent:'center',
  alignItems:'center'
});

function MultiCard(props) {
  const theme = useTheme();
  const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';
  const lightIconColor =
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';

  const classes = useStyles();
  const history = useHistory();
  const [drawItem,setDrawItem] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleHref = (url) =>e=> {
    console.log(1);
    history.push(url);
    e.stopPropagation();
  };

  React.useEffect(()=>{
    setDrawItem(props.itemData) 
  },[props.itemData])

  const goDraw=(peanutNum,collectionId)=>{
    apiCollectionDrawRecord({collectionId:collectionId,usePeanutNum:peanutNum,typeId:4}).then(res=>{
        console.log(res)
        if(res.code==200){
          setDrawItem({...drawItem,IsDrawed:1})
          enqueueSnackbar("参与抽签成功",{variant:"success"});
        }
    })
    
  }

  return (
    <Box sx={{ width: '100%', overflow: 'hidden',marginBottom:'30px' }}>
      <Widget>
        <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleHref(props.itemData.Url)}>
          <CoverImage sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              style={{borderRadius: '8px'}}
              alt={props.itemData.CollectionsName}
              src={props.itemData.Cover}
            />
          </CoverImage>
          <Box sx={{  minWidth: 0 ,width:'100%'}}>
            <FlexCenter variant="caption" color="text.secondary" fontWeight={500} sx={{fontSize:'0.855rem'}} onClick={handleHref(props.itemData.ArtistUrl)}>
              艺术家 : 
              <FlexCenter variant="caption" className={classes.marginLeftFivePx}>
                <Avatar src={props.itemData.ArtistAvatar} sx={{width:16,height:16,marginRight:'5px'}} /> 
                <Typography variant="caption" color="text.secondary" fontWeight={500} className={classes.paddingFiveUpDown}>
                  @{props.itemData.ArtistNickname}
                </Typography>
              </FlexCenter>
            </FlexCenter>
            
            <FlexCenter variant="caption">
              <Typography variant="subtitle2" letterSpacing={-0.25}>作品名称 :</Typography> 
              <Typography variant="h6" className={classes.marginLeftFivePx}><b>{props.itemData.CollectionsName}</b></Typography> 
            </FlexCenter>
            <FlexCenter variant="caption">
              ￥
              <Typography letterSpacing={-0.25} component='span' variant="subtitle2" sx={{fontSize:'0.975rem',marginRight:'3px'}}>
              {props.itemData.Price}
              </Typography>
              ,
              <Typography variant="subtitle2" letterSpacing={-0.25} component='span' sx={{marginLeft:'3px'}}>
              {props.itemData.PublishCount} 份
              </Typography>            
            </FlexCenter>
            <FlexCenter sx={{fontSize:'0.835rem',padding:"5px 0"}}>   
              <Typography letterSpacing={-0.25} component='span' variant="subtitle2" >
              消耗花生 :
              </Typography>
              <FlexCenter variant="subtitle2" letterSpacing={-0.25} component='span' className={classes.marginLeftFivePx}>
              {props.itemData.PeanutCost}
                <svg className="icon" aria-hidden="true"  style={{width: '0.9rem',height:'0.9rem',marginLeft:'3px',  color:theme.palette.mode=='dark'?theme.palette.primary.main:"#e67759",}}>
                  <use xlinkHref="#icon-huasheng-hei"></use>
                </svg>              
              </FlexCenter>          
            </FlexCenter>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
          }} 
        >

            <CountDown itemData= {props.itemData.is_start==true?props.itemData.EndTime:props.itemData.StartTime}/>
            {drawItem.is_start==true
            ?             
                // <Button size="large" className={props.itemData.is_end==false?classes.orangeButton:classes.defaultButton}>
                // {props.itemData.is_end==false? props.itemData.IsDrawed==0?"参与抽签":"已参与" :"已结束"}
                // </Button>  
                drawItem.is_end==false?
                drawItem.IsDrawed==0?
                <Button size="large" className={classes.orangeButton} onClick={(e)=>goDraw(props.itemData.PeanutCost,props.itemData.Cuid)}>
                  参与抽签
                </Button>:
                 <Button size="large" className={classes.deepOrangeButton}>
                  已参与
                </Button>
                :
                <Button size="large" className={classes.defaultButton}>
                  已结束
                </Button>
            :
            <Button size="large" className={classes.deepRedButton}>
              即将开始
            </Button>
            }
           
        </Box>
      

      </Widget>
    </Box>
  );
}



const myStyles={
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
  background: 'linear-gradient(to right bottom,rgb(255,87,88,35%) 0%,rgba(253,121,60,45%) 20%,rgba(183, 178, 43,35%) 40%, rgba(137, 202, 105, 37%) 60% ,rgba(45, 194, 237, 37%) 90%)',
  transition: 'all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s',
  minHeight:'100%',
}


export default function Draw(){
    const classes = useStyles();
    const [items,setItems] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(()=>{
      let nowtime=new Date();
      apiCollectionDrawList({}).then(res=>{
        if (res.code == 200) {
          if (res.data){
            let bottomArr=[];
            for (var i = 0; i < res.data.length; i++) {
              res.data[i].ArtistUrl = "/space/"+res.data[i].ArtistUUid;
              res.data[i].Url = "/collection/"+res.data[i].Cuid;
              let start_date = new Date(res.data[i].StartTime)             
              let end_date = new Date(res.data[i].EndTime)
              res.data[i].StartTime = utils.getNowFormatDate(start_date);
              res.data[i].EndTime = utils.getNowFormatDate(end_date);
              if(start_date>=nowtime)
              {
                res.data[i].is_start = false
              }else{
                res.data[i].is_start = true
              }   

              if(end_date>=nowtime)
              {
                res.data[i].is_end = false
              }else{
                res.data[i].is_end = true
                bottomArr.push(res.data[i]);
                res.data.splice(i,1);
                i--;
              }                          
            };
            res.data = res.data.concat(bottomArr);
            setItems(res.data);
          }      
          // toparent(res.data.count);
          // setCommentCount(res.data.count);
        } else {
          enqueueSnackbar(res.msg,{variant:"error"});
        }
      })
      
    },[])



    return(
      // <Box style={myStyles}>
      <Box className={classes.backgroudTheme}>                                                   
            <Appbar />
            <Box sx={{marginBottom:'20px'}}></Box>
            {
                items.map( (item, i) => <MultiCard key={i} itemData={item} /> )
            }
          <Navigation / >
      </Box>
     
    );
}