import * as React from 'react';
import Box from '@mui/material/Box';
import AppbarCrumbs from '../components/AppbarCrumbs';
import EditCareer from '../components/EditCareer';

export default function EditCareerPage(){

    return (
    	<Box>
    		<AppbarCrumbs />
    		<EditCareer />
    	</Box>
    );
}