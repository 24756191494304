import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';

import { red, blueGrey, orange } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { Link, useHistory } from "react-router-dom";

import {apiCollectionList,apiCollectionFollow} from '../common/Api.js';
import { useSnackbar } from 'notistack';

import ShowEmpty from './ShowEmpty.js';

export default function CollectionsList(props){

	const [items,setItems] = React.useState([]);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(()=>{
		apiCollectionList({pageSize:props.pageSize,uid:props.uid}).then(res=>{
			if (res.code == 200) {
				for (var i = 0; i < res.data.length; i++) {
					res.data[i].url = "collection/"+res.data[i].uid;
					res.data[i].artist.url = "space/"+res.data[i].artist.uid;
				};
				setItems(res.data);
			}
		});
	},[]);


	const toggleFollow = (item,i) => e => {
		apiCollectionFollow({uid:item.uid}).then(res=>{
			if (res.code == 200) {
				setItems(
					items.map((itemc,ic)=>{
						if (ic == i) {
							return { ...itemc, isFollowed:res.data};
						} else {
							return itemc;
						}
					})
				);
			} else {
				enqueueSnackbar(res.msg,{variant:"error"});
			}
		});
		e.stopPropagation();
	}

	const handleNth = (e) => {
		e.stopPropagation();
	};

	const handleHref = (url) => {
		history.push(url);
	};

    return (
    	<Box sx={{mb:5}}>
            {items.length==0?
            <ShowEmpty/>
            :
    		<Container fixed>
	    		<Grid container spacing={3} >
	    			{ items.map((item, i) => (
	    			<Grid item xs={12} md={4} key={i}>
		    				<Card sx={{ maxWidth: '100%',position:'relative' }} >
								<CardMedia
									component="img"
									height="300"
									image={item.cover}
									alt="green iguana"
									onClick={(e)=>handleHref(item.url)}
								/>
								<Box
									sx={{
										position:'absolute',
										top:0,
										right:0,
										height:'30px',
										width:'30px',
										lineHeight:'30px',
										pt:'4px',
										mt:0.5,
										mr:0.5,
										textAlign:'center',
										borderRadius:'50%',
										background:'rgba(0,0,0,0.3)'
									}}
									onClick={toggleFollow(item,i)}
								>
									{item.isFollowed == 1?<FavoriteIcon color="error"/>:<FavoriteBorderIcon color="error"/>}
								</Box>
								<Box
									sx={{
										position:'relative',
										width:'100%',
										textAlign:'center',
										display:'none',
									}}
								>	
									<Box sx={{
										position:'absolute',
										left:0,
										right:0,
										top:0,
										bottom:0,
										margin:'auto',
										width:200,
										height:20,
										background:'#fff',
										color:'#000',
										fontSize:'x-small',
										border:'1px solid #a1a1a1',
										borderRadius:5,
									}}> 发售倒计时 09:02:23
									</Box>
								</Box>
								<CardContent sx={{pb:0,pt:3}} onClick={(e)=>handleHref(item.url)}>
		                            <Typography gutterBottom variant="h5" component="div"sx={{fontWeight:'bold'}} >
		                                {item.collectionName}
		                            </Typography>
	                            </CardContent>
	                            <CardHeader onClick={(e)=>handleHref(item.artist.url)} title={item.artist.nickname} sx={{pt:0}}
									avatar={
				    					<Avatar src={item.artist.avatar} aria-label="recipe"/ >
			    					}
			    					action={
			    						<Box color="warning.main" sx={{pt:1.5,pr:1}} onClick={handleNth}>
					    					<Typography variant="h5" component="div"sx={{fontWeight:'bold'}} >
				                                ¥{item.price}
				                            </Typography>
			    						</Box>
			    					}
			    				/ >
						    </Card>
	    			</Grid>
	    			))}
	    		</Grid>
    		</Container>
            }
	    </Box>
    );
}

