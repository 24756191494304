import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

//other
import {apiUserGetInfo} from '../common/Api.js';
import { useHistory,NavLink } from "react-router-dom";

//tab
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import PersonalIndex from './PersonalIndex';
import PostListCommon from './postListCommon';
import PersonalLike from './PersonalLike';

import { styled } from '@mui/material/styles';

const TabPanelItem = styled(TabPanel)(() => ({
	padding:0,
}));

export default function Personal(){

	let [User,setUser] = React.useState({});
	const history = useHistory();
	const [value, setValue] = React.useState('1');

	React.useEffect(()=>{
		apiUserGetInfo({}).then(res=>{
			if (res.code == 200) {
				setUser(res.data);
				let userStr = JSON.stringify(res.data);
				window.localStorage.setItem('userInfo',userStr);
			} else {
				history.push('/signin');
			}
		})
	},[]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box>
			<Container fixed>
				<Box
					sx={{
						width:'100%',
						marginBottom:'4px',
						marginTop:4,
						display:'flex'
					}}
				>	
					<Avatar src={User.avatar} sx={{
						display:'inline-block',
						marginLeft:'20px',
						width:100,
						height:100,
					}}/ >
						<Box sx={{					
								margin:'auto',
								textAlign:'center',
							}}>
							<Box component="span" sx={{ fontSize: 18,fontWeight:600, display:'block' }} color="text.secondary">
								{User.followCount}
							</Box>
							<Box component="span" sx={{ fontSize: 14,fontWeight:400, display:'block' }} color="text.secondary">
								关注
							</Box>
						</Box>	
						<Box sx={{					
								margin:'auto',
								textAlign:'center',
							}}>
							<Box component="span" sx={{ fontSize: 18,fontWeight:600, display:'block' }} color="text.secondary">
								{User.followerCount}
							</Box>
							<Box component="span" sx={{ fontSize: 14,fontWeight:400, display:'block' }} color="text.secondary">
								粉丝
							</Box>
						</Box>	
						<Box sx={{					
								margin:'auto',
								textAlign:'center',
							}}>
							<Box component="span" sx={{ fontSize: 18,fontWeight:600, display:'block' }} color="text.secondary">
								0
							</Box>
							<Box component="span" sx={{ fontSize: 14,fontWeight:400, display:'block' }} color="text.secondary">
								收到的点赞
							</Box>
						</Box>	
				</Box>


				<Typography variant="h5" sx={{mx:1,py:1.5,fontWeight:'bold'}}>
					{User.nickname}
					<Button component={NavLink} to="/edit" variant="contained" size="small" sx={{mx:1}}>编辑个人档案</Button>
				</Typography>
					

				<Typography variant="body2" sx={{mx:1}}>
					{User.introduction}
				</Typography>

				<Box>
					<TabContext value={value}>
						<TabList onChange={handleChange} aria-label="lab API tabs example" centered>
							<Tab label="主页" value="1" />
							<Tab label="动态" value="2" />
							<Tab label="收藏" value="3" />
							
						</TabList>
						<TabPanelItem value="1"><PersonalIndex/></TabPanelItem>
						<TabPanelItem value="2"><PostListCommon method="3" /></TabPanelItem>
						<TabPanelItem value="3"><PersonalLike/></TabPanelItem>
						
						
					</TabContext>
				</Box>

			</Container>


		</Box>
	);
}