import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Paper from '@mui/material/Paper';

import { BrowserRouter as Router, Route, Link} from "react-router-dom";


export default function FixedBottomNavigation(props) {

  
  return (
    <Box sx={{ pb: 7, display:{xs:'flex',md:'none',position:'relative',zIndex:'999'}}} >
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation 
          showLabels
          value={props.value}
          
        >
          <BottomNavigationAction value="home" component={Link} to="/" label="首页" icon={<HomeIcon />} />
          <BottomNavigationAction value="artCircle" component={Link} to="/artCircle" label="艺术圈" icon={<DataUsageIcon />} />
          <BottomNavigationAction value="post" component={Link} to="/post" icon={<AddCircleIcon sx={{fontSize: 40}} color="primary" />} sx={{mt:-1}} />
          <BottomNavigationAction value="collections" component={Link} to="/collections" label="藏品" icon={<CatchingPokemonIcon />} />
          <BottomNavigationAction value="mine" component={Link} to="/personal" label="我的" icon={<PersonIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}

