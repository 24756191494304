import * as React from 'react';
import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import {Link} from "react-router-dom";
import {useHistory} from 'react-router-dom'

import ShowEmpty from './ShowEmpty';

import { apiArtistPostList,apiUserPostList,apiPostList,apiPostLike } from '../common/Api';

const Mycards = styled(Card)(({ theme }) => ({
    boxShadow:'none',
    paddingBottom:'10px',
    backgroundColor:theme.palette.text.hint,
}));

function ImageCarousel(props){
    const imageArr = JSON.parse(props.imagesItem);
    const showIndicators = imageArr.length == 1?false:true;
    
    return (
        <Carousel 
        autoPlay={false} 
        swipe={true} 
        indicators={showIndicators}
        

        indicatorIconButtonProps={{
            style: {
                padding: 0,    // 1
                fontSize: '10px',       // 3
            }
        }}
        indicatorContainerProps={{
            style: {
                height:'20px',
                marginTop:'0px',
            }
        }}
        
        >
        
            {
                imageArr.map( (item, i) => <ImgItem url={props.urlData} key={i} item={item} /> )
            }
        </Carousel>
    )
}
function ImgItem(props)
{
    const history = useHistory();
    const handleHref = (url) => {
      history.push(url);
    };
    return (

    		<Card sx={{ maxWidth: '100%',borderRadius:0}}>
		      <CardMedia
          sx={{
            maxHeight:"270px"
          }}
		        component="img"
		        image={props.item}
		        alt="Paella dish"
            onClick={(e)=>handleHref(props.url)}
		      />
		    </Card>
    )
}

function Media(props) {
    const loading = false;
    const item = props.item;
    const index = props.index;
    console.log(props)
    const textinfo = item.content;
    const [readMore,setReadMore] = React.useState(false);
    const [like,setLike] = React.useState(false);

    let likepost = (postId,index)=>{
      apiPostLike({postId:postId}).then(res=>{
          if (res.code == 200) {
            likeChildToParent(postId,index,res.data)
            // setItems(
            //   items.map((itemc,ic)=>{
            //    if (ic == index) {
            //       let temp = itemc.followerCount
            //       if(res.data=="like"){
            //         temp +=1;
            //         return { ...itemc, isFollowed:1,followerCount:temp};
            //       }else if(res.data=="unlike"){
            //         temp -=1;
            //         return { ...itemc, isFollowed:0,followerCount:temp};
            //       }   
            //    } else {
            //     return itemc;
            //    }
            //   })
            //  );           
          }
      });
    };
    const likeChildToParent = (postId,index,likeStr) => {
      // 向父组件传递新增的评论
        props.ChildToParent(postId,index,likeStr)
    }
    return (
      <Mycards sx={{ maxWidth: '100%', m: 0,my:1}}>
        <CardHeader
          sx={{padding:'8px',}}
          avatar={
            loading ? (
              <Skeleton
                animation="wave"
                variant="circular"
                width={42}
                height={42}
              />
            ) : (
              <Avatar
                alt="Ted talk"
                src={item.author.avatar}
                component={Link} 
                to={{
                        pathname:item.author.url,            
                        state: {  // 页面跳转要传递的数据，如下          
                              data1: {},
                              data2: []           
                        },
                
                }}
              />
            )
          }
          action={
            loading ? null : (
              <IconButton aria-label="settings" onClick={(e)=>likepost(item.uid,index)}>
                <Box sx={{padding:'0 10px',display:'inline-block',}} >
                {item.isFollowed==1 ? <FavoriteIcon sx={{color:'#c43131fc',verticalAlign:'middle',mr:0.5}}/>: <FavoriteBorderIcon sx={{verticalAlign:'middle',mr:0.5}} />}                  
                  <Typography variant="body1" sx={{display:'inline',height:1.5,lineHeight:1.5}}>{item.followerCount}</Typography>
                </Box>
              </IconButton>
            )
          }
          title={
            loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            ) : (
                <Typography sx={{fontSize:'1.1rem'}}>{item.author.nickname}</Typography>
            )
          }
          subheader=
            {item.showTime}
          
        />
        {loading ? (
          <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
        ) : (
          // <CardMedia 
          <ImageCarousel 
          
            // component="img"
            height="auto"
            alt="Nicola Sturgeon on a TED talk stage"
            imagesItem={item.images}
            urlData={item.url}
          />

        )}
  
        
              <Box >
               
                <Box className='jumpToDetail'  component={Link} 
                  to={{
                    pathname:item.url,            
                    state: {  // 页面跳转要传递的数据，如下          
                          data1: {},
                          data2: []           
                    },
            
                }}
                label="post详情页">
                  <Box sx={{px:1,paddingTop:"10px"}}>
                    <Typography variant="body1" color="text.secondary" component="p" sx={{fontSize:"1.2rem"
                    }}>
                    {item.title}
                    </Typography>


                    <Typography sx={{color:'#969393',fontSize:'0.93rem'}} variant="body2" display="block" >
                      共{item.commentCount ? item.commentCount : 0}条评论
                    </Typography>
                  </Box>
                </Box>
              </Box>

      </Mycards>

    );
  }
  
export default function PostListCommon(props) {

    const [items,setItems] = React.useState([]);
    const method = props.method == null?1:props.method;

    React.useEffect(()=>{

        if (method == 1){
            apiPostList({classify:1}).then(res=>{
                if (res.code == 200) {
                    if (res.data != null) {
                        for (var i = 0; i < res.data.length; i++) {
                            res.data[i].author.url = "/space/"+res.data[i].author.uid;
                            res.data[i].url = "/postDetail/"+res.data[i].uid;
                            res.data[i].showTime = handleDifferTime(res.data[i].createTime);
                        };
                        setItems(res.data);
                    }
                }
            });
        } else if (method == 2){
            apiArtistPostList({uid:props.uid}).then(res=>{
                if (res.code == 200) {
                    if (res.data != null) {
                        for (var i = 0; i < res.data.length; i++) {
                            res.data[i].author.url = "/space/"+res.data[i].author.uid;
                            res.data[i].url = "/postDetail/"+res.data[i].uid;
                            res.data[i].showTime = handleDifferTime(res.data[i].createTime);
                        };
                        setItems(res.data);
                    }
                }
            });
        } else if (method == 3){
            apiUserPostList({}).then(res=>{
                if (res.code == 200) {
                    if (res.data != null) {
                        for (var i = 0; i < res.data.length; i++) {
                            res.data[i].author.url = "/space/"+res.data[i].author.uid;
                            res.data[i].url = "/postDetail/"+res.data[i].uid;
                            res.data[i].showTime = handleDifferTime(res.data[i].createTime);
                        };
                        setItems(res.data);
                    }
                }
            });
        }

    },[]);

    const handleDifferTime = (createTime) => {
        const date = new Date(createTime);
        const timestamp = date.getTime();
        const dateNow = new Date();
        const timestampNow = dateNow.getTime();
        const timestampDiffer = timestampNow - timestamp; 
        if (timestampDiffer < 3600) {
            return date.getSeconds()+"分钟前";
        } else if (timestampDiffer >= 3600 && timestampDiffer < 43200) {
            return  date.getHours()+"小时前";
        } else {
            return (date.getMonth()+1)+"-"+date.getDate();
        }
    }
    const getNewChildLike = (postId,index,likeStr) => {
      setItems(
        items.map((itemc,ic)=>{
         if (ic == index) {
            let temp = itemc.followerCount
            if(likeStr=="like"){
              temp +=1;
              return { ...itemc, isFollowed:1,followerCount:temp};
            }else if(likeStr=="unlike"){
              temp -=1;
              return { ...itemc, isFollowed:0,followerCount:temp};
            }   
         } else {
          return itemc;
         }
        })
       );     
    }
    return (
        <Box>
            {items.length == 0?
            <ShowEmpty/>
            :
            <Box sx={{padding:0}}>
                {items.map((item,i) => (
                <Media key={item.uid} item={item} index={i} ChildToParent={getNewChildLike}/>
                ))}
            </Box>
            }
        </Box>
    );
}