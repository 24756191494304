import * as React from 'react';
import Box from '@mui/material/Box';

import BannerDetail from '../components/BannerDetail';

export default function BannerPage(){

    return (
    	<Box>
    		<BannerDetail/ >
    	</Box>
    );
}